import { CalculationTable } from "Business/calculation/calculationTable";
import { HttpClient } from "component/httpClient/httpClient";
import { Locale } from "component/localeManager/localeManager";
import { Datepicker } from "controls/datepicker";
import { ControlData } from "controls/util/controlData";
import { CompFeeUsage, Modules, PayTypeUsed, Region } from "enum/allEnums";
import { DetailForm } from "forms/detailForm";
import { Util } from "helper/util";
import { ControlHelper } from "../../uiFramework/helper/controlHelper";
import { HttpClientError } from "../../uiFramework/helper/httpClientError";
import { getBasePayFactor, getBasePayFactorControl, getCalculationForm, getEndDate, getEqualPayFactor, getExtraPay1, getExtraPay1Control, getExtraPay2, getExtraPay2Control, getExtraPayFactor, getExtraPayFactorControl, getHasChargedRates, getHasChargedRatesControl, getIncreaseFactor, getIncreaseFactorControl, getObjectId, getPayGrade, getPayGradeRate, getPayTypeUsed, getStartDate, getPaymentGroupRuleId } from "./calculationUtil";
import { CalculateObject } from "./interface/calculateObject";
import { StoredRateTable } from "./interface/storedRateTable";
import { SurchargeInputData } from './interface/surchargeInputData';
import { Modal } from '../../uiFramework/core/modals/modal';
import { DialogType } from '../../uiFramework/core/modals/enum/dialogType';
import { ModalType } from '../../uiFramework/enum/allEnums';

export class Calculation {

    private readonly module: Modules;
    private _previousEmploymentError = false;
    private _validationError = false;

    constructor(module: Modules) {
        this.module = module;
    }

    public getRatecard(): Promise<Ratecard> {

        const form = getCalculationForm();
        const projectControl = form.getControl("project_id");
        const supplierUnitControl = form.getControl("supplier_unit_id]");

        if (projectControl == null
            || supplierUnitControl == null
            || !projectControl.hasValue
            || !supplierUnitControl.hasValue) { return null; }

        const supplierUnitId = supplierUnitControl.getValues()[0].value;
        const projectId = projectControl.getValues()[0].value;

        const url = "PaymentCalculation/GetRatecardData";
        const params = new URLSearchParams();
        params.append("projectId", projectId);
        params.append("supplierUnitId", supplierUnitId);

        return HttpClient
            .httpGet<Ratecard>(url, params);
    }

    public calculateWorkUnits(form: DetailForm): void {

        let url;
        let params;

        const errorReasons: string[] = [];

        const { workUnitType, shiftId } = this.getWorkUnitTypeShiftId(form, errorReasons);

        if (errorReasons.length === 0 && this.module === Modules.ChangeRequest) {

            const awardCrId = this.getAwardCrId(form, errorReasons);

            if (errorReasons.length === 0) {
                ({ url, params } = this.GetWorkUnitsCrUrl(awardCrId, workUnitType, shiftId));
            }

        } else if (errorReasons.length === 0) {
            const { startDate, endDate } = this.getStartEndDate(form, errorReasons);

            // if date is invalid toISOString would throw errors
            if (errorReasons.length === 0) {
                ({ url, params } = this.getWorkUnitsUrl(startDate, endDate, workUnitType, shiftId));
            }
        }

        if (errorReasons.length > 0) {
            form.showInlineMessage(`${errorReasons.join(", ")} ${Locale.getTranslation("missing")}`, "error");
        } else {
            HttpClient
                .httpGet<string>(url, params)
                .then((workUnits) => {
                    if (workUnits != null) {
                        form.getControl("work_units").setValues(new ControlData(Locale.formatNumber(workUnits)));
                    }
                });
        }
    }

    public async openWorkUnitCalculateModal(form: DetailForm) {

        const workUnitControl = form.getControl("work_units");
        const workUnits = workUnitControl.getValues()[0].value;

        if (workUnitControl.hasValue && workUnits !== "0") {

            const modal = new Modal(ModalType.Single, "selectionModal");
            const headerText = Locale.getTranslation("are you sure");
            const bodyText = Locale.getTranslation("workunit modal data");

            modal.openModalDialog(headerText, bodyText, DialogType.YesNo, {
                overrideCloseCallback: () => {

                },
                overrideSuccessCallback: () => {
                    this.calculateWorkUnits(form);
                    modal.closeModal();
                }
            });
        }
       else {
            this.calculateWorkUnits(form);
        }
    }

    public getAwardRateTable(): Promise<void> {

        const form = getCalculationForm();
        const { url, params } = this.getCalculationUrl(form);

        return HttpClient
            .httpGet<RatesTable>(url, params)
            .then((rateTable) => {
                CalculationTable.getInstance().drawTable(this.module, rateTable);
            });
    }

    public async getCalculationTable(): Promise<void> {

        // Prepare the Calculation Object to work with
        let calculateObject: CalculateObject;
        if (this.module !== Modules.ChangeRequest) {
            calculateObject = this.createCalculateObject();
        }

        // need to add the div to dom for calc table at first call (e.g. when error occurs we need that div too)
        CalculationTable.getInstance().getCalculationTableDiv();

        // hide table when no pay grade selected
        if ($.inArray(calculateObject.payTypeUsed, [PayTypeUsed.PayGroup, PayTypeUsed.ComparativeEarning]) >= 0
            && (Util.stringIsNullOrEmpty(calculateObject.payGrade))) {
            CalculationTable.getInstance().showCalculationTable(false);
            return Promise.resolve();
        }

        const form = getCalculationForm();
        if (this.module === Modules.Bid) {
            const bidId = getObjectId(this.module, form);
            if (!await this.validatePreviousEmployment(calculateObject, bidId)) {
                return Promise.resolve();
            }
        }

        const { url, params } = this.getCalculationUrl(form);
        if (url === "") {
            return Promise.resolve();
        }

        this.getRateTable(url, params, calculateObject)
            .then((rateTable) => {
                CalculationTable.getInstance().drawTable(this.module, rateTable);
            }).catch((error: HttpClientError) => {
                this.handleValidationError(error.responseData.ExceptionMessage);
            });

    }

    public calculateComperativeEarning() {

        const form = getCalculationForm();
        const payType = getPayTypeUsed(this.module, form);

        if (payType !== PayTypeUsed.ComparativeEarning) { return; }

        const comparativeFee = ControlHelper.getSingleNumberOrDefault(form, "comp_fee", null);
        const percentage = ControlHelper.getSingleNumberOrDefault(form, "comp_fee_percentage", null);
        const compFeePayRateControl = form.getControl("comp_fee_payrate");

        if (!comparativeFee || !percentage || !compFeePayRateControl) { return; }

        let calculatedComparativeFee = 0;
        if (isFinite(comparativeFee * (percentage / 100)) && (comparativeFee) * (percentage / 100) !== 0) {
            calculatedComparativeFee = (comparativeFee * (percentage / 100));
        }

        compFeePayRateControl.setValues(new ControlData(Locale.formatCurrency(calculatedComparativeFee)));

    }

    public getMilestonesData(startDate: Date): Promise<MilestoneRates> {

        if (startDate === null || isNaN(startDate.getTime())) {
            return Promise.resolve(null);
        }

        const form = getCalculationForm();
        const awardId = getObjectId(Modules.Award, form);
        if (!awardId) {
            return Promise.resolve(null);
        }

        const url = "PaymentCalculation/GetMilestoneData";
        const params = new URLSearchParams();
        params.append("awardId", awardId);
        params.append("year", startDate.getFullYear().toString());
        params.append("month", (startDate.getMonth() + 1).toString());
        params.append("day", startDate.getDate().toString());

        return HttpClient.httpGet<MilestoneRates>(url, params);

    }

    public showSupplierData(showGeneralData?: boolean, showTVBZFactor?: boolean) {

        const form = getCalculationForm();

        const extraPay1Control = getExtraPay1Control(this.module, form);
        const extraPay2Control = getExtraPay2Control(this.module, form);
        const basePayFactorControl = getBasePayFactorControl(this.module, form);
        const extraPayFactorControl = getExtraPayFactorControl(this.module, form);
        const increaseFactorControl = getIncreaseFactorControl(this.module, form);
        const hasChargedRatesControl = getHasChargedRatesControl(this.module, form);

        if (showTVBZFactor) {
            Util.safeEnableControls(increaseFactorControl);
        } else {
            Util.safeDisableControls(increaseFactorControl);
        }

        const factorsEditable = form.getControl("factors_editable");
        if (showGeneralData) {
            Util.safeEnableControls(extraPay1Control, extraPay2Control, basePayFactorControl, extraPayFactorControl, hasChargedRatesControl, factorsEditable);
        } else {
            Util.safeDisableControls(extraPay1Control, extraPay2Control, basePayFactorControl, extraPayFactorControl, hasChargedRatesControl, factorsEditable);
        }
    }

    private getWorkUnitTypeShiftId(form: DetailForm, errorReasons: string[]) {

        const workunitTypeControl = form.getControl("work_unit_type_id");
        const workUnitType = workunitTypeControl.getValues()[0].value;
        if (!workUnitType) {
            errorReasons.push(Locale.getTranslation("work unit type"));
        }

        const shiftIdControl = form.getControl("shift_id");
        const shiftId = shiftIdControl.getValues()[0].value;
        if (!shiftId) {
            errorReasons.push(Locale.getTranslation("shift"));
        }

        return { workUnitType, shiftId };

    }

    private getWorkUnitsUrl(startDate: Date, endDate: Date, workUnitType: string, shiftId: string): { url: string, params: URLSearchParams } {

        const url = "WorkUnits/CalculateWorkUnitsByDate";
        const params = new URLSearchParams();
        params.append("startDate", startDate.toISOString());
        params.append("endDate", endDate.toISOString());
        params.append("workunitType", workUnitType);
        params.append("shiftId", shiftId);

        return { url, params };

    }

    private GetWorkUnitsCrUrl(awardCrId: string, workUnitType: string, shiftId: string): { url: string, params: URLSearchParams } {

        const params = new URLSearchParams();
        const url = "WorkUnits/CalculateWorkUnitsByAwardCr";
        params.append("awardCrId", awardCrId);
        params.append("workunitType", workUnitType);
        params.append("shiftId", shiftId);

        return { url, params };

    }

    private getAwardCrId(form: DetailForm, errorReasons: string[]) {

        const awardCrIdControl = form.getControl("award_cr_id");
        const awardCrId = awardCrIdControl.getValues()[0].value;
        if (!awardCrId) {
            errorReasons.push(Locale.getTranslation("award cr"));
        }
        return awardCrId;

    }

    private getStartEndDate(form: DetailForm, errorReason: string[]) {

        const startDateControl = form.getControl("start_date");
        let startDate: Date;

        if (startDateControl instanceof Datepicker) {
            startDate = startDateControl.getDate();
        }

        if (!startDate || !Util.isValidDate(startDate)) {
            errorReason.push(Locale.getTranslation("start date"));
        }

        const endDateControl = form.getControl("end_date");
        let endDate: Date;
        if (endDateControl instanceof Datepicker) {
            endDate = endDateControl.getDate();
        }

        if (endDate == null || !Util.isValidDate(startDate)) {
            errorReason.push(Locale.getTranslation("end date"));
        }

        return { startDate, endDate };

    }

    private createCalculateObject(): CalculateObject {

        const form = getCalculationForm();

        return {
            payTypeUsed: getPayTypeUsed(this.module, form),
            payGrade: getPayGrade(this.module, form),
            payGradeRate: getPayGradeRate(this.module, form),
            percentage: ControlHelper.getSingleNumberOrDefault(form, "comp_fee_percentage", 0),
            comperativeFeePayRate: ControlHelper.getSingleNumberOrDefault(form, "comp_fee_payrate", 0),
            comperativeFeeUsage: ControlHelper.getSingleNumberOrDefault(form, "comp_fee_usage", CompFeeUsage.FromProjectStart),
            startDate: getStartDate(this.module, form),
            endDate: getEndDate(this.module, form),
            ratecardMinimum: ControlHelper.getSingleNumberOrDefault(form, "ratecard_min", 0),
            ratecardMaximum: ControlHelper.getSingleNumberOrDefault(form, "ratecard_max", 0),
            rateUsage: ControlHelper.getSingleNumberOrDefault(form, "vk_percent_usage", 100),
            extraPay1: getExtraPay1(this.module, form),
            extraPay2: getExtraPay2(this.module, form),
            basePayFactor: getBasePayFactor(this.module, form),
            extraPayFactor: getExtraPayFactor(this.module, form),
            increaseFactor: getIncreaseFactor(this.module, form),
            nextSurchargeStartDate: ControlHelper.getSingleDateOrDefault(form, "industry_surcharge_pos_start_date", null),
            nextSurchargeLevel: ControlHelper.getSingleNumberOrDefault(form, "industry_surcharge_level", null),
            hasChargedRates: getHasChargedRates(this.module, form),
            industryId: ControlHelper.getSingleNumberOrDefault(form, "bid_surcharge_id", 0),
            regionId: ControlHelper.getSingleNumberOrDefault(form, "bid_region_id", Region.West),
            trainingPhaseDuration: ControlHelper.getSingleNumberOrDefault(form, "training_phase_duration", null),
            trainingPhaseFactor: ControlHelper.getSingleNumberOrDefault(form, "training_phase_factor", null),
            equalPayRate: ControlHelper.getSingleNumberOrDefault(form, "equal_pay_rate", null),
            equalPayPeriod: ControlHelper.getSingleNumberOrDefault(form, "equal_pay_period", null),
            equalPayFactor: getEqualPayFactor(this.module, form),
            dayBeforeEqualPay: ControlHelper.getSingleDateOrDefault(form, "day_before_equal_pay", null),
            dayBeforeMaxiumOperationalPeriod: ControlHelper.getSingleDateOrDefault(form, "day_before_maximum_operational_period", null),
            maximumOperationalPeriod: ControlHelper.getSingleNumberOrDefault(form, "maximum_operational_period", null),
            module: this.module,
        };

    }

    private getCalculationUrl(form: DetailForm): {url: string, params: URLSearchParams} {

        let url = "";
        const params = new URLSearchParams();
        let awardId;

        switch (this.module) {
            case Modules.Project:

                const projectId = getObjectId(this.module, form);
                const paymentGroupControl = form.getControl("payment_group_rule_id");

                if (paymentGroupControl) {

                    const paymentGroupRuleId = paymentGroupControl.getValues()[0].value;
                    params.append("paymentGroupRuleId", paymentGroupRuleId);
                }

                params.append("projectId", projectId);
                url = "PaymentCalculation/CalculateProjectRateTable";
                
                return { url, params };

            case Modules.Bid:

                const bidId = getObjectId(this.module, form);
                params.append("bidId", bidId);

                url = "PaymentCalculation/CalculateBidRateTable";
                return { url, params };

            case Modules.Award:

                awardId = getObjectId(this.module, form);
                params.append("awardId", awardId);

                url = "PaymentCalculation/GetAwardRateTable";
                return {url, params};

            case Modules.ChangeRequest:

                const crId = getObjectId(this.module, form);
                awardId = getObjectId(Modules.Award, form);
                params.append("awardCrId", crId);
                params.append("awardId", awardId);

                url = "PaymentCalculation/CalculateChangeRequestRateTable";
                return { url, params };

            default:
                return {url, params};

        }
    }

    private getRateTable(url: string, params: URLSearchParams, calculateObject: CalculateObject): Promise<RatesTable> {

        // check to load table from session storage otherwise get it by web service
        const storedRateTable = this.getRateTableFromSessionStorage(url, params, calculateObject);
        if (storedRateTable) {
            this.resetValidationError();
            return Promise.resolve(storedRateTable);
        } else {
            return HttpClient
                .httpPost<RatesTable>(url, calculateObject, params)
                .then((rateTable) => {
                    this.resetValidationError();
                    this.storeTable(url, params, rateTable, calculateObject);
                    return rateTable;
                });
        }
    }

    private getRateTableFromSessionStorage(url: string, params: URLSearchParams, calculateObject: CalculateObject): RatesTable {

        if (this.module === Modules.ChangeRequest) { return null; } // needs to be calculated on server every time, because no calculate object is needed

        const storedDataRaw = sessionStorage.getItem("RateTable");

        if (storedDataRaw !== null) {

            const storedData: StoredRateTable = JSON.parse(storedDataRaw);
            if (storedData.url === url && storedData.params === params.toString() && JSON.stringify(storedData.calculateObject) === JSON.stringify(calculateObject)) {
                return storedData.rateTable;
            } else {
                // delete existing table from storage, because it is old
                sessionStorage.removeItem("RateTable");
            }
        }
        return null;
    }

    private storeTable(url: string, params: URLSearchParams, rateTable: RatesTable, calculateObject: CalculateObject): void {

        if (calculateObject != null) {
            sessionStorage.setItem("RateTable", JSON.stringify({ url: url, params: params.toString(), rateTable: rateTable, calculateObject: calculateObject }));
        }
    }

    private handleValidationError(message: string): void {
        this._validationError = true;

        if (!message)
            message = Locale.getTranslation("unknown error");

        CalculationTable.getInstance().replaceCalculationTableWithMessage(message);
    }

    private validatePreviousEmployment(calculateObject: CalculateObject, bidId: string): Promise<boolean> {

        if (this.module !== Modules.Bid
            || !calculateObject.nextSurchargeLevel
            || !calculateObject.nextSurchargeStartDate
            || !Util.isNumeric(calculateObject.nextSurchargeStartDate.getTime())) {

            this.resetPreviousEmploymentError();
            return Promise.resolve(true);
        }

        const url = "PaymentCalculation/ValidatePreviousEmployment";
        const params = this.getPreviousEmploymentParams(calculateObject);
        const surchargeInputData = this.getSurchargeInputData(bidId, calculateObject);

        return HttpClient
            .httpPost<boolean>(url, surchargeInputData, params)
            .then(() => {
                this.resetPreviousEmploymentError();
                return true;
            })
            .catch((error: HttpClientError) => {
                this.handlePreviousEmploymentError(error.responseData.ErrorKey);
                return false;
            });
    }

    private getPreviousEmploymentParams(calculateObject: CalculateObject): URLSearchParams {

        const params = new URLSearchParams();
        params.append("nextTvbzStepDate", calculateObject.nextSurchargeStartDate.toISOString());
        params.append("nextTvbzLevel", calculateObject.nextSurchargeLevel.toString());

        return params;
    }

    private getSurchargeInputData(bidId: string, calculateObject: CalculateObject) {

        let data: SurchargeInputData = {
            IndustryId : calculateObject.industryId,
            Module : calculateObject.module,
            ObjectId : bidId,
            PayGrade : calculateObject.payGrade,
            PayTypeUsed : calculateObject.payTypeUsed,
            Region : calculateObject.regionId,
            StartDate : calculateObject.startDate,
            PaymentGroupRuleId: getPaymentGroupRuleId(calculateObject.module, getCalculationForm()) 
        };

        return data;
    }



    private handlePreviousEmploymentError(errorKey: string) {
        switch (errorKey) {
            case "PREVIOUS_EMPLOYMENT_UNKNOWN_ERROR":
                break;
            case "PREVIOUS_EMPLOYMENT_HIGHEST_LEVEL":
                this.throwPreviousEmploymentError(Locale.getTranslation("previous employment highest level"));
                break;
            case "PREVIOUS_EMPLOYMENT_NO_SURCHARGES":
                this.throwPreviousEmploymentError(Locale.getTranslation("previous employment no surcharges"));
                break;
            case "PREVIOUS_EMPLOYMENT_LEVEL_1":
                this.throwPreviousEmploymentError(Locale.getTranslation("previous employment level 1"));
                break;
            case "PREVIOUS_EMPLOYMENT_WRONG_LEVEL_OR_DATE":
                this.throwPreviousEmploymentError(Locale.getTranslation("previous employment wrong level or date"));
                break;
        }
    }

    private throwPreviousEmploymentError(message: string) {
        this._previousEmploymentError = true;
        CalculationTable.getInstance().previousEmploymentError = true;
        CalculationTable.getInstance().replaceCalculationTableWithMessage(message);
    }

    private resetPreviousEmploymentError() {
        if (this._previousEmploymentError) {
            this._previousEmploymentError = false;
            CalculationTable.getInstance().previousEmploymentError = false;
            CalculationTable.getInstance().showCalculationTable(true);
        }
    }

    private resetValidationError() {
        if (this._validationError) {
            this._validationError = false;
            CalculationTable.getInstance().showCalculationTable(true);
        }
    }

}


import { Button } from "buttons/button";
import { BackgroundSubmit } from "component/backgroundSubmit/backgroundSubmit";
import { FormValidation } from "component/formValidation/formValidation";
import { GridActionButton } from "component/gridActionButton/gridActionButton";
import { Locale } from "component/localeManager/localeManager";
import { LookupMultiple } from "controls/allControls";
import { ControlData } from "controls/util/controlData";
import { ActionTypes, ControlTypes, FormAction, FormStyles, ModalType } from "enum/allEnums";
import { FormCallbackType } from "forms/enum/formCallbackType";
import { Form } from "forms/form";
import { Util } from "helper/util";
import { DialogType } from "modals/enum/dialogType";
import { Modal } from "modals/modal";
import { DataTableWrapper } from "uiFramework/component/dataTableWrapper/dataTableWrapper";

interface RowDataInterface {
    [key: string]: {display?: string, value?: string[] };
}

type Direction = "current" | "previous" | "next";

export class GridForm extends Form {

    public get formStyle(): FormStyles {
        return FormStyles.Grid;
    }

    /// Begin Getter
    public get rowCount(): number {
        if (!this.hasGridWithRecords()) { return 0; }

        return this.dataTableWrapper.rowCount;
    }

    public get columnCount(): number {
        if (!this.hasGridWithRecords()) { return 0; }

        return this.dataTableWrapper.columnCount;
    }

    public get tableNode(): JQuery<HTMLTableElement> {
        if (!this.hasGridWithRecords()) { return null; }

        return this.dataTableWrapper.getTableNode();
    }

    /// Grid Form Callbacks

    /**
     * Get Callback Function for Grids in a Modal.
     * Returns value to control after row clicked
     * Preselect values in grid after modal opens
     * @param values
     * @param control
     * @param modal
     */
    public static initGridModalCallback(values: ControlData[], control: ControlInterface, modal: ModalInterface): FormCallback {

        return function(callbackType: FormCallbackType, data?: any): void {

            if (this instanceof GridForm) { // check at runtime if this is a GridForm

                switch (callbackType) {

                    case FormCallbackType.FormContentClicked:

                        GridForm.getValueFromModalToControl(data, control, modal);
                        break;

                    case FormCallbackType.FormLoaded:

                        GridForm.selectGridValues(data, values);
                        break;
                }
            }

        };
    }

    private static getValueFromModalToControl(clickedElement: JQuery, destinationControl: ControlInterface, modal: ModalInterface): void {

        const selectedGuidValue = clickedElement.attr("data-return-id");
        const selectedTextValue = clickedElement.text();
        const controlData = new ControlData(selectedGuidValue, selectedTextValue);

        if (destinationControl.type === ControlTypes.Lookup) {

            destinationControl.setValues(controlData);
            destinationControl.validate();

            modal.closeModal();
        } else if (destinationControl instanceof LookupMultiple) {

            if (destinationControl.containsValue(controlData)) {
                let values = destinationControl.getValues();
                values = values.filter((x) => x.value !== controlData.value);
                destinationControl.setValues(values);
            } else {
                destinationControl.addValue(controlData);
            }
            destinationControl.validate();
        }

    }

    private static selectGridValues(gridForm: GridForm, valuesToSelect: ControlDataInterface[]): void {

        if (!gridForm || !valuesToSelect || valuesToSelect.length === 0) { return; }

        for (const rowObject of Array.from(gridForm.getRows())) {

            const anchor = rowObject.node.find("[data-return-id]");

            // Preselect Rows in Grid
            const rowValue = anchor.attr("data-return-id");
            for (const item of valuesToSelect) {
                if (rowValue === item.value) {
                    gridForm.selectRow(rowObject.index);
                }
            }

        }
    }

    private dataTableWrapper: DataTableWrapper;
    private activeRowIndex: number;
    private readonly modalFormCallbacks: FormCallback[]; // callback which is passed to the modal form for add/edit Button in Grid
    private readonly backgroundSubmit: BackgroundSubmit;
    private readonly gridActionButton: GridActionButton;
    private validationInstance: FormValidation;

    constructor(dispatcher: DispatcherInterface, portletBody: JQuery, formCallbacks?: FormCallback | FormCallback[]) {

        super(dispatcher, portletBody, formCallbacks);

        this.modalFormCallbacks = new Array<FormCallback>();
        this.backgroundSubmit = new BackgroundSubmit(this);
        this.gridActionButton = new GridActionButton(this);
    }

    public async initializeForm(): Promise<void> {

        this.instantiateGrid();
        this.validationInstance = new FormValidation(this);

        super.initializeButtons(); // Init Button outside Grid Table - e.g. Add Button

        if (this.hasGridWithRecords()) {
            await this.dataTableWrapper.initializeGrid();
            this.initializeGridForm();
        }

        super.setFormInitialized(this);
    }

    public isValid(): boolean {
        return this.validationInstance.validate();
    }

    public async reloadForm(formConfiguration?: FormConfiguration): Promise<void> {

        let currentFilter;
        if (this.hasGridWithRecords()) { currentFilter = this.dataTableWrapper.getSearchValue(); }

        await super.reloadForm(formConfiguration);

        if (this.hasGridWithRecords()) { this.dataTableWrapper.setSearchValue(currentFilter); }

    }

    public getTransferData(): FormTransferInterface {

        const transfer = super.getTransferData();

        if (this.hasGridWithRecords()) {

            const rows = this.getRows();
            for (const rowObject of Array.from(rows)) {
                const rowData: RowDataInterface = rowObject.data as RowDataInterface;

                for (const fieldName in rowData) {

                    if (rowData[fieldName].value) {
                        transfer.addField(rowObject.index, fieldName, rowData[fieldName].value);
                    }
                }

                // add hidden Fields
                const hiddenFields = this.getHiddenFields(rowObject.index);
                for (const key in hiddenFields) {

                    const value = hiddenFields[key];

                    transfer.addField(rowObject.index, key, value);
                }
            }
        }

        return transfer;
    }

    /**
     * Set Display Value of a Visible Field - use {{display}} to insert old display value
     * @param rowNumber
     * @param fieldName
     * @param newValue
     */
    public setFieldDisplayValue(rowNumber: number | "current", fieldName: string, newValue: string): void {

        if (!this.hasGridWithRecords()) { return; }

        const field = this.getField(rowNumber, fieldName);
        if (!field) { return; }

        const oldValue = field.display;
        newValue = this.replacePlaceholder(newValue, oldValue);

        const rowIndex = this.getGridIndex(rowNumber);

        return this.dataTableWrapper.setCellDisplayValue(rowIndex, fieldName, newValue);
    }

    /**
     * Get a Row Iterator
     * @param applySearchFilters - filter rows by current user search
     */
    public * getRows(applySearchFilters = false): IterableIterator<GridRow> {

        if (!this.hasGridWithRecords()) { return; }

        yield * this.dataTableWrapper.getRows(applySearchFilters);
    }

    /**
     * Get any field - could be visible or hidden
     * @param rowNumber
     * @param fieldName
     */
    public getField(rowNumber: number | "current", fieldName: string): GridContentData {

        if (!this.hasGridWithRecords()) { return null; }

        const visibleFields = this.getVisibleFields(rowNumber);
        const visiblefield = visibleFields[fieldName];
        if (visiblefield != null) {
            return visiblefield;
        } else {
            const hiddenFields = this.getHiddenFields(rowNumber);
            const hiddenField = hiddenFields[fieldName];
            if (!hiddenField) { return null; }
            return { display: "", value: hiddenField, isReadonly: true }; // hidden Field has no Display Value
        }
    }

    /**
     * Get Visible Fields
     * @param rowNumber
     */
    public getVisibleFields(rowNumber: number | "current"): Dictionary<GridContentData> {

        if (!this.hasGridWithRecords()) { return {}; }

        const rowData = this.getRowData(rowNumber);

        const visibleFields: Dictionary<GridContentData> = {};
        for (const key in rowData) {
            const value = rowData[key];

            if (this.isContentData(value)) {
                visibleFields[key] = value;
            }
        }

        return visibleFields;
    }

    /**
     * Get Hidden Fields - this includes primary key fields
     * @param rowNumber
     */
    public getHiddenFields(rowNumber: number | "current"): Dictionary<string[]> {

        return this.getSpecialFields("hiddenFields", rowNumber);

    }

    /**
     * Get Primary Key Fields
     * @param rowNumber
     */
    public getPrimaryKeyFields(rowNumber: number | "current"): Dictionary<string[]> {

        return this.getSpecialFields("pkFields", rowNumber);
    }

    public getCurrencyId(): number {

        if (!this.hasGridWithRecords()) { return null; }

        const currencyId = this.tableNode.attr("data-currency");

        if (Util.isNumeric(Number(currencyId))) {
            return parseInt(currencyId);
        } else {
            return null;
        }
    }

    public getActiveRowIndex(): number {
        return this.activeRowIndex;
    }

    public selectRow(rowNumber: number | "current"): void {

        if (!this.hasGridWithRecords) { return; }

        const rowIndex = this.getGridIndex(rowNumber);

        this.dataTableWrapper.selectRow(rowIndex);
    }

    /// End Getter

    /// Begin Setter

    /**
     * Set the "active" row for selection purpose
     * @param rowNumber
     */
    public setActiveRow(rowNumber: number | Direction) {
        if (rowNumber == null) {
            this.activeRowIndex = null;
        } else if (typeof rowNumber === "number") {
            if (rowNumber > this.dataTableWrapper.rowCount) {
                throw Error("Grid.setActiveRow(): Index out of Range");
            }
            this.activeRowIndex = rowNumber;
        } else {
            if (rowNumber === "next" && this.activeRowIndex + 1 < this.dataTableWrapper.rowCount) {
                this.activeRowIndex++;
            } else if (rowNumber === "previous" && this.activeRowIndex - 1 >= 0) {
                this.activeRowIndex--;
 }
        }
    }

    /// End Setter

    public appendModalCallback(callback: FormCallback): void {
        this.modalFormCallbacks.push(callback);
    }

    public dispatch(button: ButtonInterface): Promise<void> {

        const actionType = button.formAction;
        switch (actionType) {
            case FormAction.Delete:

                this.triggerGridDeleteButton(button);
                return Promise.resolve();

            case FormAction.Add:

                this.triggerGridAddButton(button);
                return Promise.resolve();

            case FormAction.Gridedit:

                this.triggerGridEditButton(button);
                return Promise.resolve();

            default:

                return super.dispatch(button);                
        }
    }

    private instantiateGrid() {
        const tableNode = ((this.formNode as JQuery).find("table.dataTable")) as JQuery<HTMLTableElement>;
        if (tableNode.length === 1) {  // check there is no <table> because no records in mapper
            this.dataTableWrapper = new DataTableWrapper(tableNode);
        } else {
            this.dataTableWrapper = undefined;
        }
    }

    private hasGridWithRecords(): boolean {
        return this.dataTableWrapper != null; // check there is no datatable because no records in mapper
    }

    private initializeGridForm() {

        if (this.rowCount === 0) { return; }

        this.initFormContentClickCallbacks();
        this.initializeAllGridButtons();
        this.initializeResponsiveGridButtons();

    }

    private getSpecialFields(name: "hiddenFields" | "pkFields", rowNumber: number | "current"): Dictionary<string[]> {

        if (!this.hasGridWithRecords()) { return {}; }

        const rowIndex = this.getGridIndex(rowNumber);

        return this.dataTableWrapper.getCellData(rowIndex, name) as Dictionary<string[]>;

    }

    private getRowData(rowNumber: number | "current"): Dictionary<GridContentData | Object> {

        if (typeof rowNumber === "number") {
            return this.dataTableWrapper.getRowData(rowNumber);
        }

        if (this.activeRowIndex == null) {
            throw new Error("No Active Grid Row set");
        }

        return this.dataTableWrapper.getRowData(this.activeRowIndex);

    }

    private initializeResponsiveGridButtons() {

        // in case grid action buttons are not visible because of responsive table, we need to rewire the button events when buttons get visible
        // no multiple events, because tr.child gets deleted after closing or resizing
        this.dataTableWrapper.addResponsiveCallback((index: number, childRowNode: JQuery, childRowIsVisible: boolean) => {

            if (childRowIsVisible && childRowNode.length) {

                this.initializeRowGridButtons(index, childRowNode);
                childRowNode.find("input.group-checkable").parent().remove();

            }

        });

    }

    private initializeAllGridButtons(): void {

        for (const row of Array.from(this.getRows())) {

            this.initializeRowGridButtons(row.index, row.node);
        }

    }

    private initializeRowGridButtons(index: number, rowNode: JQuery): void {

        this.initializeBackgroundSubmitButtons(rowNode);
        this.initializeGridActionButtons(rowNode);
        this.createGridButtons(index, rowNode);

    }

    private initFormContentClickCallbacks(): void {

        for (const rowObject of Array.from(this.getRows())) {

            const rowNode = rowObject.node;

            // Set event when a row is clicked
            rowNode.click((event: JQuery.ClickEvent) => {
                const anchor = rowNode.find("[data-return-id]");
                // skip event propagation when grid responsive symbol was clicked
                if (!$(event.target).hasClass("control")) {
                    this.runCallbacks(FormCallbackType.FormContentClicked, anchor);
                }
            });

        }

    }

    private initializeGridActionButtons(rowNode: JQuery): void {
        const gridActionButtons = rowNode.get(0).querySelectorAll("a.btn[data-toggle='dropdown']");
        this.gridActionButton.wireGridActionButtons(gridActionButtons);
    }

    private initializeBackgroundSubmitButtons(rowNode: JQuery): void {
        const backgroundSubmitButtons = rowNode.get(0).querySelectorAll("a.btn[data-backgroundsubmit-form-name]");
        this.backgroundSubmit.wireBackgroundSubmit(backgroundSubmitButtons);
    }

    // Init Buttons inside the Grid Table - e.g. Edit Button
    private createGridButtons(rowIndex: number, rowNode: JQuery): void {

        // Init Buttons inside the Grid Table - e.g. Edit Button
        $("a.text-button:not([data-backgroundsubmit-form-name]), a.icon-button", rowNode).each((i: number, element: HTMLElement) => {

            const buttonId = $(element).attr("data-button-id");
            const id = `${buttonId}-${rowIndex}`;
            const button = new Button(id, this, $(element) as JQuery<HTMLAnchorElement>);
            button.setPreDispatchCallback(() => {

                this.setActiveRow(rowIndex); // set the row of the button to active when button clicked

            });

            this.formButtons.push(button);

        });
    }

    private triggerGridAddButton(button: ButtonInterface) {

        const modalId = `add-${this.formId}`;
        const modal = new Modal(ModalType.Single, modalId, this);

        const formConfig: FormConfiguration = {
            formId: this.formId,
            pageName: this.pageName,
            overrideFormStyle: FormStyles.Detail,
            renderAsEmptyForm: true,
            hideCaptionButtons: true,
            formSearchable: false,
        };
        const modalConfig: ModalConfig = {
            dialogType: DialogType.SaveCancel,
            headerText: Locale.getTranslation("add entry"),
        };

        this.handleMultipleDetailForm(button, formConfig, modalConfig);

        modal.openModalByForm(formConfig, this.modalFormCallbacks, modalConfig);
    }

    private triggerGridEditButton(button: ButtonInterface) {

        const modalId = `edit-${this.formId}`;
        const modal = new Modal(ModalType.Single, modalId, this);

        const formConfig: FormConfiguration = {
            formId: this.formId,
            pageName: this.pageName,
            overrideFormStyle: FormStyles.Detail,
            primaryKeys: button.getAttribute("data-id"),
            hideCaptionButtons: true,
            formSearchable: false,
        };
        const modalConfig: ModalConfig = {
            dialogType: DialogType.SaveCancel,
            headerText: Locale.getTranslation("edit entry"),
            action: ActionTypes.Edit
        };

        this.handleMultipleDetailForm(button, formConfig, modalConfig);

        modal.openModalByForm(formConfig, this.modalFormCallbacks, modalConfig);
    }

    private triggerGridDeleteButton(button: ButtonInterface) {

        const modalId = `delete-${this.formId}`;
        const modal = new Modal(ModalType.Single, modalId, this);

        const headerText = Locale.getTranslation("are you sure");
        const bodyText = Locale.getTranslation("delete entry");
        modal.openModalDialog(headerText, bodyText, DialogType.YesNo,
            {
                action: ActionTypes.Delete,
                overrideSuccessCallback: () => {

                    // Delete Entry on Server and refresh grid
                    this.dispatcher.dispatch(this, button, button.formAction);
                }
            });
    }

    private handleMultipleDetailForm(button: ButtonInterface, formConfig: FormConfiguration, modalConfig: ModalConfig) {

        if (this.getDataAttribute("data-modal-button-navigation") != null) {

            // important, otherwise we could get a modified version of the form by extensions. But we use the form for every row.
            formConfig.renderAsEmptyForm = true;
            formConfig.overrideFormStyle = FormStyles.DetailFormWithDataGrid;

            if (button.formAction === FormAction.Add) {
                modalConfig.dialogType = DialogType.AddSaveCancelPrevNext;
                button.form.setDataAttribute("mode", "add");
            } else {
                modalConfig.dialogType = (button.getAttribute("data-modal-button-add") != null) ? DialogType.AddSaveCancelPrevNext : DialogType.SaveCancelPrevNext;
                button.form.setDataAttribute("mode", "edit");
            }
        }

    }

    private isContentData(object: any): object is GridContentData {

        const u: GridContentData = object;

        return u.display != null && u.value != null;

    }

    private replacePlaceholder(newValue: string, oldValue: string) {
        newValue = newValue.replace("{{display}}", oldValue);
        return newValue;
    }

    private getGridIndex(rowNumber: "current" | number): number {
        return (rowNumber === "current") ? this.activeRowIndex : rowNumber;
    }

    /// End Grid Callbacks

}

import { HttpClient } from "component/httpClient/httpClient";
import { Locale } from "component/localeManager/localeManager";
import { ToastrWrapper } from "component/toastrWrapper/toastrWrapper";
import { PageTransfer } from "transfer/pageTransfer";
import { ClientOperationalException } from "../helper/ClientOperationalException";
import { HttpClientError } from "../helper/httpClientError";

export class SubmitHelper {

    /**
     * Submit Data to WebController
     * @param submitOptions
     */
    public static submitData(submitOptions: SubmitOptions): Promise<ResponseMessage> {

        return SubmitHelper.submit<ResponseMessage>(submitOptions);
    }

    /**
     * Get Search Result Page from WebController
     * @param submitOptions
     */
    public static getResultSearchPage(submitOptions: SubmitOptions): Promise<string> {

        return SubmitHelper.submit<string>(submitOptions, true);
    }

    /**
     * Do File Upload in WebController
     * @param pageTransfer
     * @param form
     */
    public static doFileUpoad(pageTransfer: PageTransfer, form: FormInterface): Promise<void> {

        const formData = new FormData();

        // Add all Files from File Inputs to FormData Object
        let fileCount = 0;
        let fileSizeMax = 0;
        const fileInputs = $("input[type=file]", form.getFormContainer());
        const fileExtAllowed = [
            "bmp", "png", "jpg", "jpeg", "gif",
            "txt", "csv",
            "pdf",
            "docx", "doc",
            "pptx", /*"ppt",*/
            "xlsx", "xls",
        ];
        const collectionFileExtFoundIsAllowed: boolean[] = [];

        $(fileInputs).each((i: number, elem: HTMLInputElement) => {

            if (elem.files.length > 0) {

                fileSizeMax = (elem.files[0].size > fileSizeMax) ? elem.files[0].size : fileSizeMax;
                formData.append(`file${i}`, elem.files[0], elem.files[0].name);
                fileCount++;

                let validator = true;

                if (elem.files[0].name.length < 4) {
                    validator = false;
                }

                if (elem.files[0].name.split(".").length <= 1) {
                    validator = false;
                }

                if (elem.files[0].name.split(".").pop() === "") {
                    validator = false;
                }

                if ((elem.files[0].name.split(".").pop().length < 3 ) || (elem.files[0].name.split(".").pop().length > 4 )) {
                    validator = false;
                }

                if (validator) {
                    const fileExt = elem.files[0].name.split(".").pop();
                    const fileExtFoundIsAllowed = fileExtAllowed.some((x) => x === fileExt.toLocaleLowerCase());
                    if (fileExtFoundIsAllowed) {
                        collectionFileExtFoundIsAllowed.push(true);
                    } else {
                        collectionFileExtFoundIsAllowed.push(false);
                    }
                } else {
                    collectionFileExtFoundIsAllowed.push(false);
                }

            }

        });

        if (!collectionFileExtFoundIsAllowed.every((x) => x)) {
            throw new ClientOperationalException (Locale.getTranslation("forbidden file extension"), "File extension forbidden");
        }

        // Client side filesize validation
        const fileSizeMaxAllowed = 12 * 1024 * 1024;
        if (fileSizeMax > fileSizeMaxAllowed) {
            throw new ClientOperationalException (Locale.getTranslation("the file you selected is too big."), "File validation error");
        }

        if (fileCount === 0) { return Promise.resolve(); }

        formData.append("PageTransfer", JSON.stringify(pageTransfer));

        return HttpClient.httpPost<FileUploadResponseObject[]>("document/upload", formData)
        .then((data) => {
            SubmitHelper.updateFileNames(data, pageTransfer);
        })
        .catch((error: HttpClientError) => {
            // Server response with serialized JSON object indicates that this is an error from 3SS => expect statusCode:500.
            if (error.responseData && error.responseData instanceof Array) {
                throw error;
            }

            if (error.statusCode && error.statusCode == 401)
                error.message = Locale.getTranslation("not authenticated");
            else {
                // Possible server error message because file-size exceeded server upload limit configuration.
                // Nevertheless doing a client-side-validate for max filesize above in addition to avoid unnecessary server stress.
                // Hitting this offset is therefore kind of unexpected error + catch-all => expect statusCode:404.
                error.message = Locale.getTranslation("the file you selected is too big.");
            }
            throw error;
        });
    }

    /**
     * Main Function to Submit Data to WebController
     * @param submitOptions
     * @param dataTypeHtml
     */
    private static submit<T>(submitOptions: SubmitOptions, dataTypeHtml = false): Promise<T> {

        const options: HttpClientOptions = {};

        // Required Headers for every Request directly made to WebController
        options.headers = {
            "x-current-page": submitOptions.currentPage, // save the current page for webcontroller
            "x-request-type": "ajax", // header for web controller to differentiate between api call and "normal" request (error handling)
        };

        // require a document from Web Controller
        if (dataTypeHtml) {
            options.prependWebApiPrefix = false;
            //options.responseType = "document";
        }

        return HttpClient
            .httpPost<T>(submitOptions.submitUrl, submitOptions.formData, null, options);
    }

    /**
     * Updates File Names coming from Server after FileUpload to PageTransfer Object
     * @param uploadReturnData
     * @param pageTransfer
     */
    private static updateFileNames(uploadReturnData: FileUploadResponseObject[], pageTransfer: PageTransfer) {

        for (const data of uploadReturnData.filter((x) => x.reason === "newFileNames")) {
            const fileNamesObj: FileNameTransferInterface[] = JSON.parse(data.name);

            for (const fileName of fileNamesObj) {

                const form = pageTransfer.forms.filter((f) => f.id === fileName.FormID)[0];
                if (form != null) {
                    form.updateFieldValue(fileName.FieldName, [fileName.NewFileName]);
                }

            }
        }
    }

}

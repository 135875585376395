import { HttpClient } from "component/httpClient/httpClient";
import { ModalType } from "enum/allEnums";
import { PageTransfer } from "transfer/pageTransfer";
import { TssApp } from "tssApp";

export class PageHelper {

    public static getPage(): PageInterface {
        return TssApp.getInstance().getPage();
    }

    public static readonly sessionExpiredPage = "index?m=SESSION_EXPIRED_OR_MISSING";

    // Gets the content of the page from the server
    public static loadPageDOMFromServer(pageName: string, modalType: ModalType): Promise<JQuery> {

        const pageTransfer = new PageTransfer();
        pageTransfer.modalType = modalType;

        const formData = new FormData();
        formData.append("PageTransfer", JSON.stringify(pageTransfer));

        return HttpClient
            .httpPost<string>(pageName, formData, null, { prependWebApiPrefix: false })
            .then((data) => {
                return $(data);
            });
    }

    public static getButtonsByDataAttribute(attributeName: string): ButtonInterface[] {
        const resultButtons: ButtonInterface[] = [];

        PageHelper
            .getPage()
            .getForms()
            .forEach((f) => f
                .getButtonsByDataAttribute(attributeName)
                .forEach((b) => resultButtons.push(b)));

        return resultButtons;
    }

}

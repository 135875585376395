import { LogLevels } from "component/logging/logLevels";
import { Util } from "helper/util";
import { JL } from "jsnlog";
import { ClientOperationalException } from "../../helper/ClientOperationalException";
import { HttpClientError } from "../../helper/httpClientError";

// For public usage
export class Log {

    public static getLogLevel(): LogLevels {
        return Logging.getInstance().getLogLevel();
    }

    public static logFatal(message: string) {
        Logging.getInstance().logFatal(message);
    }

    public static logError(error: string | Error) {
        Logging.getInstance().logError(error);
    }

    public static logWarn(message: string) {
        Logging.getInstance().logWarn(message);
    }

    public static logInfo(message: string) {
        Logging.getInstance().logInfo(message);
    }

    public static logDebug(message: string) {
        Logging.getInstance().logDebug(message);
    }

    public static logTrace(message: string) {
        Logging.getInstance().logTrace(message);
    }

}

// internal only
class Logging {

    public static getInstance(): Logging {

        if (!Logging._instance) {

            Logging._instance = new Logging();

            Logging._instance.initLogging();
        }

        return Logging._instance;

    }

    private static _instance: Logging;
    private _tssLogger: JL.JSNLogLogger;

    constructor() {
        if (Logging._instance) {
            throw new Error("Error: Instantiation failed: Use Logging.getInstance() instead of new.");
        }
        Logging._instance = this;

    }

    public logFatal(message: string): void {

        const logObject: LogObject = {
            message,
            stack: Error().stack
        };
        this._tssLogger.fatal(logObject);
    }

    public logError(error: string | Error): void {

        let logObject: LogObject;
        if (error instanceof HttpClientError) {
            logObject = {
                message: error.message,
                stack: Error().stack,
                errorType: "Exception"
            };
            this._tssLogger.error(logObject);
        }
        else if (error instanceof ClientOperationalException) {
            logObject = {
                message: error.message,
                stack: Error().stack,
                errorType: "OperationalException"
            };
            this._tssLogger.error(logObject);
        }
        else if (typeof error === "string") {
            logObject = {
                message: error,
                stack: Error().stack
            }

            this._tssLogger.error(logObject);
        }
        else {
            logObject = {
                message: error.message,
                stack: error.stack
            }
            this._tssLogger.error(logObject);
        }
    }

    public logWarn(message: string): void {
        const logObject: LogObject = {
            message,
            stack: Error().stack
        };
        this._tssLogger.warn(logObject);
    }

    public logInfo(message: string): void {
        const logObject: LogObject = {
            message,
            stack: Error().stack
        };
        this._tssLogger.info(logObject);
    }

    public logDebug(message: string): void {
        const logObject: LogObject = {
            message,
            stack: Error().stack
        };
        this._tssLogger.debug(logObject);
    }

    public logTrace(message: string): void {
        const logObject: LogObject = {
            message,
            stack: Error().stack
        };
        this._tssLogger.trace(logObject);
    }

    public getLogLevel(): LogLevels {
        const attrLevel = Util.toTitleCase($("body").attr("data-log-level").toLowerCase());
        return (LogLevels as any)[attrLevel];
    }

    private initLogging() {
        // Default Logger for uncaught exceptions
        JL.setOptions({
            enabled: true,
            defaultAjaxUrl: "api/Logging/Log",
        });

        // Set Log Level
        let level: number;

        let levelAttribute: LogLevels = LogLevels.Warn;
        if ($("body").attr("data-log-level")) {
            const attrLevel = Util.toTitleCase($("body").attr("data-log-level").toLowerCase());
            levelAttribute = (LogLevels as any)[attrLevel];
        }

        switch (levelAttribute) {

            case LogLevels.Trace:
                level = JL.getTraceLevel();
                break;

            case LogLevels.Debug:
                level = JL.getDebugLevel();
                break;

            case LogLevels.Info:
                level = JL.getInfoLevel();
                break;

            case LogLevels.Warn:
                level = JL.getWarnLevel();
                break;

            case LogLevels.Error:
                level = JL.getErrorLevel();
                break;

            case LogLevels.Fatal:
                level = JL.getFatalLevel();
                break;

            default:
                level = JL.getAllLevel();
                break;
        }

        // Ajax and Console Appender for code triggered logs
        const ajaxAppender = JL.createAjaxAppender("ajaxAppender");
        ajaxAppender.setOptions({
            url: "api/Logging/Log",
        });

        const consoleAppender = JL.createConsoleAppender("consoleAppender");

        this._tssLogger = JL("TSS.Logger");
        this._tssLogger.setOptions({
            level,
            appenders: [ajaxAppender, consoleAppender],
        });
    }

}

interface LogObject {
    message: string;
    stack: string;
    errorType?: ErrorType;
}

type ErrorType = "Exception" | "OperationalException"

Logging.getInstance();

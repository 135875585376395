import { Form } from "forms/form";
import { FormStyles } from "uiFramework/enum/allEnums";

export class TreeviewForm extends Form {

    constructor(dispatcher: DispatcherInterface, portletBody: JQuery, formCallbacks: FormCallback | FormCallback[]) {

        super(dispatcher, portletBody, formCallbacks);
    }

    public get formStyle(): FormStyles {
        return FormStyles.Treeview;
    }

    public async initializeForm(): Promise<void> {

        super.initializeButtons();

        super.setFormInitialized();

    }

    public isValid(): boolean {
        return true;
    }

    // public static CreateRoot(): void {

    //    var treeviewControl = $(".treeview");

    //    var jsonData = treeviewControl.attr("data");

    //    var treeData = JSON.parse("[]");

    //    if (jsonData)
    //        treeData = JSON.parse(jsonData);

    //    treeData.push({ "text": Locale.getTranslation("new folder"), "icon": "glyphicon glyphicon-folder-close" });

    //    treeviewControl.attr("data", JSON.stringify(treeData));

    //    //treeviewControl.treeview(
    //    //    {
    //    //        data: treeData,
    //    //        enableLinks: true,
    //    //        expandIcon: "",
    //    //        collapseIcon: ""
    //    //    });
    // }

    // public static RenameNode(): void {

    // }

}

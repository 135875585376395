import { ModalType } from "enum/allEnums";
import { DialogType } from "modals/enum/dialogType";
import { Modal } from "modals/modal";

export class DebugBox {

    public initialize() {

        $("#DebugIcon").on("click", (() => {
            const modal = new Modal(ModalType.Single, "DebugBoxContainer");
            const modalConfig: ModalConfig = {
                dialogType: DialogType.CloseOnly,
                headerText: "DebugBox",
                copyDomToBody: true,
                largeModal: true,
            };
            modal.openModalByDOMData($("#debugBox"), function() { }, modalConfig);
        }));
        if ($("#DebugIcon").length !== 0) {
            $(".debug-tab-btn").on("click", (function(event) {
                const activeBtn = $("li.active > .debug-tab-btn");
                const activeTab = $("#DebugTabsContainer > div:not(.hidden)");
                const targetTab = $("#" + event.target.dataset.targetTab);

                // refresh which button is active
                if (activeBtn.length > 0) {
                    activeBtn.parent().removeClass("active");
                }
                $(event.target).parent().addClass("active");

                // refresh which tab is shown
                if (activeTab.length > 0) {
                    activeTab.addClass("hidden");
                }
                if (targetTab.length > 0) {
                    targetTab.removeClass("hidden");
                }
            }));
        }
    }
}

import { Locale } from "component/localeManager/localeManager";

import { utc } from "moment";
import { HtmlHelper } from "../../helper/htmlHelper";

export class PivotHelper {

    public set localeId(value: string) {
        this._localeId = value;
    }

    public set data(value: any[]) {
        this._data = value;
    }

    public set container(value: JQuery) {
        this._pivotContainer = value;
    }

    public get config(): PivotUiOptions {
        return this._config;
    }

    public set configUpload(value: JQuery) {

        if (value != null) {

            value.on("change", () => {
                const file = (value[0] as HTMLInputElement).files[0]; // FileList object
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.execute(JSON.parse((e.target as FileReader).result.toString()), true);
                };
                reader.readAsText(file);

            });
        }
    }

    public static mergePivots(startIndex: number, endIndex: number, formId: string, isMultiRow?: boolean) {

        let removeMultiRow = true;
        if (isMultiRow != null && isMultiRow) {
            removeMultiRow = false;
        }

        const form = $("form[data-form-id='" + formId + "']");

        if (!form || form.length === 0) {
            return;
        }

        const firstOutput = "#output" + (startIndex);
        const pvtTable = form.find(firstOutput + " table.pvtTable");
        const rowspan = parseInt(pvtTable.attr("data-numcols"));

        for (let i = startIndex; i <= endIndex; i++) {

            const output = "#output" + i;
            if (i > startIndex) { // skip first table
                // removing
                form.find(output + " .pvtRowLabel, " + output + " .pvtAxisLabel, " + output + " .pvtTotalLabel:last").remove();
                form.find(output + " th:empty").remove();
                if (removeMultiRow) {
                    form.find(output + " tr:empty").remove();
                    form.find(output + " .pvtColLabel").each((ix, item) => {
                        $(item)
                            .attr("colspan", "1")
                            .attr("rowspan", "1")
                            .attr("class", "pvtTotalLabel");
                    });
                }

                // merging
                let elementIndex = 0;
                pvtTable.find("tr").each((i, item) => {

                    // Elements to append on main table
                    const elements = form.find(output + " table.pvtTable").find("tr").eq(elementIndex).find("td,th");
                    $(item).append(elements);

                    // if we have mixed tables (with and without cols property in pivot config), we need to adjust the rowspan for the header cell
                    if (rowspan > 1 && elements.is("th.pvtTotalLabel")) {
                        elements.attr("rowspan", rowspan);
                    } else {
                        elementIndex++;
                    }

                });

            }
        }
    }

    public static addCurrencySymbol(Index: number, formId: string, symbol: string) {
        const output = "#output" + Index;
        const form = $("form[data-form-id='" + formId + "']");
        if (!form || form.length === 0) {
            return;
        }
        form.find(output + " .pvtVal, " + output + " .pvtTotal, " + output + " .pvtGrandTotal").each((i, item) => {
            const valText = $(item).text();
            if (valText.length > 0) {
                $(item).text(valText + " " + symbol);
            }
        });

    }

    public static replaceExcelExport(form: JQuery) {

        // replace functionality of excel symbol of 3SS
        const selectorString = "a i.fa.fa-download";

        if (form) {
            this.replaceDownloadLink(form.find(selectorString));
        } else {
            $(selectorString).each((index, elem) => {
                this.replaceDownloadLink($(elem));
            });
        }
    }

    public static async SetupAggregatorAndFilter(pivotConfig: PivotUiOptions, isEditMode: boolean): Promise<void> {

        return import(/* webpackChunkName: "pivot" */"./pivotImporter").then((e) => {

            if (!pivotConfig.aggregatorName) {
                throw new Error("No AggregatorName in PivotConfig!");
            }

            const aggregator = $.pivotUtilities.aggregators[pivotConfig.aggregatorName];
            pivotConfig.aggregator = aggregator(pivotConfig.vals);

            if (!isEditMode) {
                pivotConfig.filter = function(record: Array<KeyValuePair<string, string>>) {

                    for (const prop in record) {

                        // skip loop if the property is from prototype
                        if (!record.hasOwnProperty(prop)) {
                            continue;
                        }

                        for (const pivotProp in pivotConfig.exclusions) {
                            if (prop === pivotProp) {
                                // exclude records which were deselected in edit mode
                                return $.inArray(record[prop], pivotConfig.exclusions[pivotProp]) === -1;
                            }
                        }
                    }
                    return true;
                };
            }
        });
    }

    public static addOutputDivs(formNode: JQuery, count: number): void {

        const topButtonNode = formNode.find(".form-actions.top");

        let destinationElement;
        if (topButtonNode.length > 0) {
            destinationElement = topButtonNode;
        } else {
            destinationElement = formNode.find(".message");
        }

        for (let i = 1; i <= count; i++) {
            const element = $(`<div style="overflow-x: auto; overflow-y:hidden;" id="output${i}">`);

            destinationElement.after(element);
            destinationElement = element;
        }
    }

    public static addFileInput(formNode: JQuery): void {

        const element = $(`<input type="file" id="files" name="files[]" style="display:none;" />`);
        formNode.append(element);
    }

    public static getDisplayValue(data: string[][], fieldName: string): string {

        if (!data || data.length < 2) {
            return "";
        }

        const index = data[0].indexOf(fieldName);
        if (index >= 0) {
            return data[1][index];
        }

        return "";

    }

    private static exportAsExcel(excelImageElement: JQuery): Promise<void> {

        let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
        tab_text = tab_text + "<head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>";
        tab_text = tab_text + '<meta http-equiv="content-type" content="application/vnd.ms-excel;charset=UTF-8"/>';
        tab_text = tab_text + "<x:Name>{worksheet}</x:Name>";
        tab_text = tab_text + "<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>";
        tab_text = tab_text + "</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>";
        tab_text = tab_text + "<table>";
        tab_text = tab_text + "{table}";
        const exportTable = excelImageElement.closest(".section-form").find(".pvtTable:first").clone();
        exportTable.find("input").each(function(index, elem) { $(elem).remove(); });

        const pivotHtmlTable = exportTable.html();

        const currendDateTime = utc().format("YYYY_MM_DD_HH_mm_ss");

        const worksheet = "Pivot_" + currendDateTime;

        const templateFields: TemplateFields = {
            "{worksheet}": worksheet,
            "{table}": pivotHtmlTable,
        };

        tab_text = tab_text + "</table></body></html>";

        let fileName = excelImageElement.closest(".section-form").find(".caption").find("span").html().replace(/ /g, "_");

        fileName += "_" + currendDateTime + ".xls";

        tab_text = tab_text.replace(/{\w+}/g, function(all: string) {
            return templateFields[all] || all;
        });

        // Save the file
        const blob = new Blob([tab_text], { type: "application/vnd.ms-excel;charset=utf-8;base64" });

        return import(/* webpackChunkName: "pivot" */"./pivotImporter").then((e) => {

            if (pivotHtmlTable !== undefined) {
                e.saveAs(blob, fileName);
            }
        });
    }

    private static removeRowTotal(pivotConfig: PivotOptions | PivotUiOptions, output: JQuery) {

        if (pivotConfig.removeRowTotal) {
            output.find(".rowTotal").remove();
            output.find(".pvtGrandTotal").remove();
            output.find(".pvtTotalLabel").first().remove();
        }
    }

    private static replaceDownloadLink(selector: any) {
        const excelDownloadLink = selector.parent();
        excelDownloadLink.unbind("click");
        excelDownloadLink.click(() => { this.exportAsExcel(selector); });
        excelDownloadLink.removeAttr("href");
        excelDownloadLink.css("cursor", "pointer");
    }
    private _pivotContainer: JQuery;
    private _data: any[];
    private _config: PivotUiOptions;
    private _localeId: string = "en";

    public execute(newConfig: PivotOptions | PivotUiOptions, editMode: boolean, refreshFunction?: Function): Promise<void> {

        if (this._data == null) {
            throw new Error("Error: Pivotdata is not defined!");
        }
        if (this._pivotContainer == null) {
            throw new Error("Error: No PivotContainer defined!");
        }

        return import(/* webpackChunkName: "pivot" */"./pivotImporter").then((e) => {

            const sortersFunction = this.getSortersFunction();

            const renderers = $.extend($.pivotUtilities.renderers, $.pivotUtilities.c3_renderers);

            if (newConfig != null && editMode) {

                const titleText = newConfig.rendererOptions != null ? newConfig.rendererOptions.titleText : "";

                const uiConfig = newConfig as PivotUiOptions;

                this._pivotContainer.pivotUI(this._data, {
                    renderers,
                    onRefresh: this.uiRefreshFunction(refreshFunction),
                    sorters: sortersFunction,
                    rows: uiConfig.rows,
                    cols: uiConfig.cols,
                    filter: uiConfig.filter,
                    exclusions: uiConfig.exclusions,
                    autoSortUnusedAttrs: uiConfig.autoSortUnusedAttrs,
                    derivedAttributes: uiConfig.derivedAttributes,
                    hiddenAttributes: uiConfig.hiddenAttributes,
                    unusedAttrsVertical: uiConfig.unusedAttrsVertical,
                    vals: uiConfig.vals,
                    aggregatorName: uiConfig.aggregatorName,
                    menuLimit: uiConfig.menuLimit,
                    rendererName: uiConfig.rendererName,
                    rendererOptions: {
                        c3: {
                            size: { width: uiConfig.width, height: uiConfig.height },
                        },
                        titleText,
                    },
                    width: uiConfig.width,
                    height: uiConfig.height,
                    isNeutral: true,
                }, true, this._localeId);
            } else if (newConfig != null) {

                const aggregator = $.pivotUtilities.aggregators[newConfig.aggregatorName];
                const titleText = newConfig.rendererOptions != null ? newConfig.rendererOptions.titleText : "";

                const nonUiConfig = newConfig as PivotOptions;
                this._pivotContainer.pivot(this._data, {
                    renderer: renderers[nonUiConfig.rendererName],
                    sorters: sortersFunction,
                    rows: nonUiConfig.rows,
                    cols: nonUiConfig.cols,
                    filter: nonUiConfig.filter,
                    derivedAttributes: nonUiConfig.derivedAttributes,
                    aggregator: aggregator(nonUiConfig.vals),
                    vals: nonUiConfig.vals,
                    aggregatorName: nonUiConfig.aggregatorName,
                    rendererOptions: {
                        c3: {
                            size: { width: nonUiConfig.width, height: nonUiConfig.height },
                        },
                        titleText,
                    },
                    isNeutral: true,
                }, this._localeId);
            } else {
                this._pivotContainer.pivotUI(this._data, {
                    renderers,
                    sorters: sortersFunction,
                    onRefresh: this.uiRefreshFunction(refreshFunction),
                    isNeutral: true,
                }, true, this._localeId);
            }
        });
    }

    public downloadConfigFile(): void {
        HtmlHelper.downloadFile(this.downloadConfigLink(), "reporting.txt");
    }

    public async drawPivot(data: string[][], pivotConfig: PivotOptions | PivotUiOptions, index: number, formId: string): Promise<void> {

        const output = $("form[data-form-id='" + formId + "']").find("#output" + index);
        if (!output || output.length === 0) {
            return;
        }

        this.data = data;
        this.container = output;
        this.configUpload = $("#files");
        this.localeId = Locale.getCurrentLanguage().split("-")[0].toLowerCase();

        const pivotConfigCopy = pivotConfig;

        if (pivotConfig.showUi) {

            await this.execute(pivotConfigCopy, true, () => PivotHelper.removeRowTotal(pivotConfigCopy, $(output)));

            if (pivotConfig.hideAxisContainer) {
                $(output).find(".pvtAxisContainer.pvtUnused li").remove();

                // this is a bit tricky. We can not simply remove pvtUnused, because the Filter Box will not work.
                // for this reason we move the horizontal list to the axis container and everything is fine ;)
                const horizontalBox = $(output).find(".pvtAxisContainer.pvtHorizList");
                const unusedBox = $(output).find(".pvtAxisContainer.pvtUnused");
                unusedBox.parent().append(horizontalBox);
                unusedBox.removeClass("pvtAxisContainer");
            }
        } else {
            await this.execute(pivotConfigCopy, false);
        }

        if (pivotConfig.overwriteRowTotalLabel) {
            $(output).find("tr").last().find(".pvtTotalLabel").text(pivotConfig.overwriteRowTotalLabel);
        }

        if (pivotConfig.overwriteColumnTotalLabel) {
            $(output).find("tr").first().find(".pvtTotalLabel").text(pivotConfig.overwriteColumnTotalLabel);
        }

        if (pivotConfig.overwriteColumnLabel) {
            $(output).find("tr").first().find(".pvtColLabel").each((index, item) => {
                $(item).text(pivotConfig.overwriteColumnLabel[index]);
            });
        }

        if (!pivotConfig.showUi) {
            PivotHelper.removeRowTotal(pivotConfig, $(output));
        }
    }

    private uiRefreshFunction(refreshFunction?: Function): (options: PivotUiOptions) => void {

        // updates local config after every change in UI
        return (config: PivotUiOptions) => {
            const config_copy = JSON.parse(JSON.stringify(config)); // deep clone - easy way...
            // delete some values which are functions
            delete config_copy.aggregators;
            delete config_copy.renderers;
            // delete some bulky default values
            delete config_copy.rendererOptions;
            delete config_copy.localeStrings;

            this._config = config_copy;
            // call manual refreshFunction
            if (refreshFunction != null) {
                refreshFunction();
            }
        };
    }

    private getSortersFunction(): (attr: string) => void {
        if (Locale.getCurrentLanguage().split("-")[0].toLowerCase() === "de") {
            return function(attr: string) {
                const suffix = " Monat";
                const sortAs = $.pivotUtilities.sortAs;
                if (attr.indexOf(suffix, attr.length - suffix.length) !== -1) {
                    return sortAs(["Jan", "Feb", "Mrz", "Apr", "Mai",
                        "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]);
                }
            };
        } else {
            return function(attr: string) {
                const suffix = " Month";
                const sortAs = $.pivotUtilities.sortAs;
                if (attr.indexOf(suffix, attr.length - suffix.length) !== -1) {
                    return sortAs(["Jan", "Feb", "Mar", "Apr", "May",
                        "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
                }
            };
        }
    }

    private downloadConfigLink(): string {
        const configData = JSON.stringify(this._config);
        return "data:application/json;charset=utf-8," + encodeURIComponent(configData);
    }

}

import { FormAction, ActionTypes } from "enum/allEnums";
import { Util } from "helper/util";

export class Button implements ButtonInterface {

    public readonly id: string;
    public readonly form: FormInterface;
    public readonly formAction: FormAction;
    public readonly actionType: ActionTypes;

    protected buttonAttributes: NamedNodeMap;
    protected anchorTags: Array<JQuery<HTMLAnchorElement>>;

    private beforeDispatchCallback: () => void;

    constructor(id: string, form: FormInterface, ...anchorTags: Array<JQuery<HTMLAnchorElement>> ) {

        this.id = id;
        this.form = form;
        this.buttonAttributes = anchorTags[0].get(0).attributes;
        this.anchorTags = anchorTags;

        const action = parseInt(anchorTags[0].attr("data-form-action")) as FormAction;

        // when there is no form action, we cant bind any event
        if (!action) {
            return;
        }

        this.formAction = action;

        const rawActionType = anchorTags[0].attr("data-action-type");
        if (rawActionType) {
            this.actionType = parseInt(rawActionType) as ActionTypes;

        }

        this.setClickEventHandler();
    }

    public getAttribute(name: string): string {

        let attr: Attr = this.buttonAttributes.getNamedItem(name);
        if (attr != null) {
            return attr.value;
        }

        // fallback add data- to attribute name, if someone missed it
        name = Util.formatDataAttribute(name);
        attr = this.buttonAttributes.getNamedItem(name);
        if (attr != null) {
            return attr.value;
        }

        return undefined;
    }

    public setPreDispatchCallback(callback: () => void): void {
        this.beforeDispatchCallback = callback;
    }

    public overrideClickEvent(callback: (e: JQuery.Event) => void): void {

        this.anchorTags.forEach((a) => a.off("click")
                                    .click((event: JQuery.Event) => {

                                        event.preventDefault();

                                        if (this.beforeDispatchCallback != null) {
                                            this.beforeDispatchCallback();
                                        }

                                        callback(event);
                                    }));

    }

    public click(): void {
        // call click event only on one anchor tag
        if (this.anchorTags.length > 0) {
            this.anchorTags[0].click();
        }
    }

    /**
     * Do not misuse this; Sometimes it is better to implement a new function to the classS
     */
    public getAnchorElements(): Array<JQuery<HTMLAnchorElement>> {
        return this.anchorTags;
    }

    public disable(): void {
        // remove tabindex, to skip disabled buttons by tab key
        this.anchorTags.forEach((b) => b.addClass("disabled").removeAttr("tabindex"));
    }

    public enable(): void {
        // add tabindex, to include buttons by tab key
        this.anchorTags.forEach((b) => b.removeClass("disabled").attr("tabindex", "0"));
    }

    protected setClickEventHandler(): void {

        this.anchorTags.forEach((a) => a.click((event: JQuery.Event) => {

            event.preventDefault();

            if (this.beforeDispatchCallback != null) {
                this.beforeDispatchCallback();
            }

            this.form.dispatch(this);
        }));

    }
}

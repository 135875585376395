import { Framework } from "framework";
import { isEqual, sortBy } from "lodash";
import { ControlData } from "uiFramework/core/controls/util/controlData";

export type TagElement = "input" | "select" | "textarea";
export type ControlCallbackFunction = () => void;

export abstract class Control implements ControlInterface {

    get isEnabled(): boolean {
        return !this.fieldContainer.find(this.baseTag).prop("disabled");
    }

    get isRequired(): boolean {
        return this.fieldContainer.find(this.baseTag).prop("required");
    }

    get hasValue(): boolean {

        if (this.getValues().length === 0) {
            return false;
        }

        const firstValue = this.getValues()[0];
        if (firstValue.value && firstValue.value !== "") {
            return true;
        }

        if (firstValue.displayValue && firstValue.displayValue !== "") {
            return true;
        }

        return false;
    }

    public readonly fieldName: string;
    public readonly displayName: string;
    public readonly isStaticControl: boolean;
    public readonly form: FormInterface;
    public readonly type: ControlTypes;
    protected readonly fieldContainer: JQuery;
    protected readonly baseTag: TagElement = "input"; // default base tag is <input>

    protected valueChangedCallbacks: ControlCallbackFunction[];

    private readonly selectorStaticValue = "input:hidden, .generic-container";
    private readonly spanSelectorStaticDisplayValue = "span";
    private readonly divSelectorStaticDisplayValue = "div";

    constructor(fieldContainer: JQuery, form?: FormInterface, baseTag?: TagElement) {

        Framework.verifyFieldcontainer(fieldContainer);

        this.fieldContainer = fieldContainer;
        this.fieldName = fieldContainer.attr("data-field-name");
        this.displayName = fieldContainer.attr("data-display-name");
        this.isStaticControl = fieldContainer.attr("data-static") === "true";
        this.form = form;
        this.type = parseInt(fieldContainer.attr("data-control-type")) as ControlTypes;
        this.valueChangedCallbacks = new Array<ControlCallbackFunction>();

        if (baseTag) {
            this.baseTag = baseTag;
        }

    }

    public enable(): void {

        this.enableElement(this.baseTag);

    }

    public disable(): void {

        this.disableElement(this.baseTag);

    }

    public validate(): void {

        this.validateElement(this.baseTag);

    }

    public focus(): void {

        this.focusElement(this.baseTag);

    }

    public addLabelTooltip(text: string): void {
        // check if there is already a tooltip, then replace text or create a new tooltip
        const label = this.fieldContainer.closest("div.form-group").find("label");
        let iTag = label.find("i");

        if (iTag.length === 0) {
            label.text(label.text() + " "); // add whitspace to label

            iTag = $("<i>");
            iTag.addClass("fa fa-question-circle force-tooltip help-cursor");
            iTag.attr("title", text);

            label.append(iTag);

        } else {
            iTag.attr("title", text);
            iTag.attr("data-original-title", text);
        }
    }

    public addBottomTextToControl(text: string): void {
        let infoTextSpan = this.fieldContainer.find("span.help-block");

        if (infoTextSpan.length === 0) {

            infoTextSpan = $("<span />").addClass("help-block");
            infoTextSpan.text(text);

            this.fieldContainer.append(infoTextSpan);
        } else {
            infoTextSpan.text(text);
        }
    }

    public getDataAttribute(name: string): string {

        if (this.isStaticControl) {
            return this.fieldContainer.find(this.selectorStaticValue).attr(name);
        } else {
            const baseElement = this.fieldContainer.find(this.baseTag);
            return baseElement.attr(name);
        }

    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        if (this.isStaticControl) { return; }

        this.valueChangedCallbacks.push(callback);
    }

    public addValue(controlData: ControlDataInterface): void {
        // valid for all controls which have only one value
        this.setValues(controlData);
    }

    public equals(controlData: ControlDataInterface | ControlDataInterface[], ignoreOrder = false): boolean {

        const compareData = this.makeArray(controlData);

        if (!ignoreOrder) {
            // deep equal = check value and displayValue
            return isEqual(this.getValues(), compareData);
        } else {
            // sort array first to have the same order which can be then compared
            const a = sortBy(this.getValues(), [ (o) => o.value]);
            const b = sortBy(compareData, [(o) => o.value]);
            return isEqual(a, b);
        }

    }

    public containsValue(controlData: ControlDataInterface): boolean {
        return this.getValues().map((v) => v.value).indexOf(controlData.value) !== -1;
    }

    public abstract clear(): void;

    public abstract initialize(): void;

    public abstract setValues(controlData: ControlDataInterface | ControlDataInterface[]): void;
    public abstract getValues(): ControlDataInterface[];

    protected makeArray(controlData: ControlDataInterface | ControlDataInterface[]): ControlDataInterface[] {
        if (!Array.isArray(controlData)) {
            return [controlData];
        }

        return controlData;
    }

    protected setStaticField(value: string, displayValue?: string, containsHtml = false): void {

        const { input, span } = this.getValueAndDisplayValueElement();

        input.val(value);

        const theDisplayValue = (displayValue == null) ? value : displayValue;
        if (containsHtml && displayValue != null) {
            span.html(theDisplayValue);
        } else {
            span.text(theDisplayValue);
        }

    }

    protected getStaticSingleField(): ControlDataInterface[] {

        const { input, span } = this.getValueAndDisplayValueElement();

        let value = input.val().toString();
        if (input[0].dataset.isoDate) { // for datatype date we return iso date
            value = input[0].dataset.isoDate;
        }

        const displayValue = span.text();

        return [new ControlData(value, displayValue)];

    }

    protected getStaticMultipleField(): ControlDataInterface[] {

        const selectControlStaticInput = this.fieldContainer.find(this.selectorStaticValue);
        const selectControlStaticSpan = this.fieldContainer.find(".form-control-static");
        const values: ControlDataInterface[] = [];
        // both controls contain must have equal count of token
        // input [hidden]  = ids
        // span [visiable] = labels
        if ((selectControlStaticInput.length > 0) &&
            (selectControlStaticSpan.length > 0)) {

            const inputArray = selectControlStaticInput.val().toString().trim().split(";");
            const spanArray = selectControlStaticSpan.text().trim().split(",");

            // unexpected invalid state
            if (inputArray.length !== spanArray.length) {
                return values;
            }

            // empty strings caused by split()
            if ((inputArray[0].trim() === "") && (spanArray[0].trim() === "")) {
                return values;
            }

            for (let i = 0; i < inputArray.length; i++) {
                values.push(new ControlData(inputArray[i].trim(), spanArray[i].trim()));
            }

        }

        return values;

    }

    protected getValuesFromSortableSelect(): ControlDataInterface[] {

        // the order is important here
        // the values in the select control are not ordered
        // therefore we need the order of the li elements
        // and map the text of li-Element to the id's of select-options to get the right values in the right order
        const values: ControlDataInterface[] = [];
        const kvOptionsSelected: Array<KeyValuePair<string, string>> = new Array<KeyValuePair<string, string>>();

        // search all options which have been "selected" and add them to an array
        const optionsSelected: JQuery = this.fieldContainer.find("option:selected");
        optionsSelected.each((i: number, element: HTMLElement) => {
            const title = $(element).text();
            const value = $(element).val() as string;
            kvOptionsSelected.push({ key: title, value });
        });

        // obtain handle to SELECT2 node
        const lis: JQuery = this.fieldContainer.find("li.select2-selection__choice");

        // iterate all custom-ordered SELECT2 list item elements
        lis.each((i: number, element: HTMLElement) => {

            // read SELECT2 list item title => the only available key, which can be not unique — but shouldn't!
            const title: string = $(element).attr("title");

            // filter the current iteration-cycle-array-key in our "original-select" selected-options-array
            // more than one result is possible due to equal defined SELECT2 list-item-elements (TSS-2118) => Firma/Firma
            // "more than one" in case same TITLE/LABEL has been used for different field
            const optionsFiltered: Array<KeyValuePair<string, string>> = kvOptionsSelected.filter((v: KeyValuePair<string, string>, i: number, array: Array<KeyValuePair<string, string>>) => {
                return v.key === title;
            });

            if ((optionsFiltered != null) && (optionsFiltered.length > 0)) {

                // if iterate && process directly all-found instead only one => sort gets lost for items between these equal-labels
                // ensure we only pick the first-found-single item => so we use static zero index "0" instead "options_filtered.length" loop

                // push found values in result array
                values.push(new ControlData(optionsFiltered[0].value, optionsFiltered[0].key));

                // iterate selected-options-array and drop added values
                for (let i = 0; i < kvOptionsSelected.length; i++) {
                    if (kvOptionsSelected[i].key === optionsFiltered[0].key) {
                        kvOptionsSelected.splice(i, 1);
                        break;
                    }
                }

            }

        });
        return values;
    }

    protected addStaticValueMultipleField(controlData: ControlDataInterface): void {

        const selectControlStaticInput = this.fieldContainer.find(this.selectorStaticValue);
        const selectControlStaticSpan = this.fieldContainer.find("span");

        if ((selectControlStaticInput != null) && (selectControlStaticSpan != null)) {

            const selectControlStaticInputValue = selectControlStaticInput.val().toString().trim();
            if (selectControlStaticInputValue.length > 0) {
                selectControlStaticInput.val(`${selectControlStaticInputValue};${controlData.value}`);
            } else {
                selectControlStaticInput.val(controlData.value);
            }

            if (selectControlStaticSpan.text().trim().length > 0) {
                selectControlStaticSpan.append(",<br>");
            }
            selectControlStaticSpan.append(controlData.displayValue);

        }

    }

    protected nullToEmptyString(value: string): string {
        return value || "";
    }

    protected clearStaticInputBasedElement(): void {
        this.fieldContainer.find(this.selectorStaticValue).val("");
        this.fieldContainer.find(this.spanSelectorStaticDisplayValue).text("");
    }

    protected enableElement(type: TagElement): void {

        if (this.isStaticControl) { return; }

        this.fieldContainer.find(type).prop("disabled", false);

    }

    protected disableElement(type: TagElement): void {

        if (this.isStaticControl) { return; }

        this.fieldContainer.find(type).prop("disabled", true);

    }

    protected validateElement(type: TagElement): void {

        const element = this.fieldContainer.find(type);
        element.valid();

    }

    protected focusElement(type: TagElement): void {

        if (this.isStaticControl) { return; }

        if (type === "select") {
            this.fieldContainer.find("select").select2('open').select2('close');
        } else {
            this.fieldContainer.find(type).trigger("focus");
        }

    }

    protected clearDatePickerBasedControl(): void {

        if (!this.isStaticControl) {

            this.fieldContainer
                .data("DateTimePicker")
                .clear();
        } else {
            const hiddenInput = this.fieldContainer.find(this.selectorStaticValue);
            hiddenInput.val("");
            hiddenInput[0].dataset.isoDate = "";

            this.fieldContainer.find(this.spanSelectorStaticDisplayValue).text("");
        }

    }

    protected runValueChangedCallbacks(): void {

        if (!this.isStaticControl && this.valueChangedCallbacks && this.valueChangedCallbacks.length > 0) {

            for (const callback of this.valueChangedCallbacks) {
                callback();
            }

        }

    }

    protected addEmptyControlDataWhenEmpty(values: ControlDataInterface[]): ControlDataInterface[] {

        if (values.length === 0) {
            values.push(new ControlData("", ""));
        }

        return values;
    }

    private getValueAndDisplayValueElement() {

        const input = this.fieldContainer.find(this.selectorStaticValue);
        let span = this.fieldContainer.find(this.spanSelectorStaticDisplayValue);

        if (input.length === 0) {
            throw new Error(`${this.fieldName}: Input Element not found`);
        }

        if (span.length === 0) { // when form label style is NONE, there is no span element. instead we need to find a div element
            span = this.fieldContainer.find(this.divSelectorStaticDisplayValue);

            if (span.length === 0) {
                throw new Error(`${this.fieldName}: Span Element not found`);
            }

        }

        return { input, span };
    }

}

export enum ProjectType {

    RateBased = 16,
    TaskBased = 32,
    RecurringServices = 64,
    FixedPrice = 128

} 
export enum ActionTypes {
    View = 1,
    Add = 2,
    Edit = 3,
    Delete = 4,
    Admin = 5,
    Gridedit = 6
}
 
 

export enum CalculationRule  {
    
	Hours = 1,
	Days = 2,
	Manual = 3,
	Award = 4,
	CostItem = 5
}

export enum CompFeeUsage  {
    
	FromProjectStart = 0,
	IfCheaper = 1
}

export enum ControlTypes  {
    
	Hypertext = 1,
	HypertextWithoutLabel = 2,
	FreeText = 3,
	Bitmask = 4,
	Checkbox = 5,
	Datepicker = 6,
	HiddenField = 7,
	Hourpicker = 8,
	Lookup = 9,
	Password = 10,
	FreetextWithoutEval = 11,
	Radio = 12,
	OnlyLabel = 14,
	Invisible = 15,
	Select = 16,
	LookupMultiple = 17,
	ReadOnly = 18,
	Textarea = 19,
	Image = 20,
	SelectSort = 21,
	SavableReadOnly = 22,
	RangePicker = 30,
	RangePickerMonth = 31,
	SelectMultiple = 32,
	PeriodOfCurrentAndLastYear = 33,
	Fileupload = 50,
	MultipleCheckbox = 34,
	Durationpicker = 51,
    Container = 40,
    TOTP = 52,
}

export enum CostCodeTypes  {
    
	CostCode = 1,
	InternalOrderNumber = 4,
	PO = 0,
	WBS = 2,
	WBSTaskCode = 3
}

export enum CrType  {
    
	Initial = 0,
	ChangeDatesExtension = 1,
	ChangeRates = 2,
	ChangePO = 3,
	ChangeExpenses = 4,
	ChangePlacementFee = 5,
	ChangeMilestones = 6,
	ChangeOTStrategy = 7,
	ChangeCodes = 8,
	ChangeMultiple = 9,
	SummarizedExtension = 10,
	ChangeWorkUnits = 11,
	ChangeSurcharges = 12,
	ChangeOtherFields = 13
}

export enum CrTypeMulti  {
    
	ChangePO = 4,
	ChangeFieldData = 1,
	ChangeFinancialInformation = 2,
	ChangeWorkUnits = 64,
	ChangeCodes = 32,
	ChangeOTStrategy = 16,
	ChangeExpenses = 8,
	ChangeSurcharges = 128,
	ChangeOrderUdf = 256
}

export enum DataTablesColumnType  {
    
	Text = 1,
	Button = 2,
	Checkbox = 3,
	Link = 4,
	Date = 5,
	Image = 6
}

export enum FormAction  {
    
	Search = 1,
	Saveform = 2,
	Resetform = 3,
	Delete = 4,
	Edit = 5,
	Add = 6,
	Href = 7,
	Gridedit = 8,
	Adhocupdate = 9,
	Adhocsavesearch = 10,
	Prevrow = 11,
	Nextrow = 12,
	Addrow = 13,
	Success = 14,
	Close = 15,
	BackgroundSubmit = 16
}

export enum FormStyles  {
    
	Grid = 1,
	Detail = 2,
	Graphical = 5,
	Calendar = 6,
	Treeview = 7,
	Links = 8,
	Razor = 9,
	RazorGrid = 10,
	RazorDetail = 11,
	DetailFormWithDataGrid = 12,
	Container = 13
}

export enum IncreaseType  {
    
	None = 2,
	Percentage = 0,
	Monetary = 1
}

export enum JobType  {
    
	Contracting = 1,
	DirectHire = 2,
	SOW = 3,
	Temp = 5,
	Unused = 4,
	OpenRequest = 6
}

export enum ModalType  {
    
	None = 0,
	Single = 1,
	Multi = 2
}

export enum Modules  {
    
	AcctOfficeCashFlow = 53,
	AcctOfficeFunding = 41,
	AcctOfficeJournalEntry = 44,
	AcctOffice = 63,
	Admin = 42,
	AdminWorkgroupArchiving = 89,
	AgencyWorkersRegulations = 81,
	Applicant = 33,
	AvailabilityRequest = 94,
	Award = 5,
	AwardConversion = 84,
	AwardCostSchedule = 78,
	AwardRateChange = 83,
	Bid = 2,
	BidMilestone = 76,
	Blog = 26,
	Calendar = 60,
	CalendarAttendee = 70,
	CalendarEvent = 30,
	CalendarTask = 31,
	Candidate = 4,
	CandidateSearch = 37,
	Case = 8,
	CaseTech = 68,
	ChangeRequest = 6,
	Checklist = 27,
	ClientsBillable = 79,
	Contract = 32,
	CostItem = 7,
	DashboardUserCustomer = 86,
	Dashboards = 102,
	DirectHireOrder = 67,
	Document = 20,
	Email = 34,
	Evaluation = 18,
	ExpenseStatement = 10,
	FileManagement = 49,
	FileManagementDocCart = 72,
	Folder = 50,
	InboxAlerts = 59,
	Interface = 28,
	Interview = 36,
	Invoice = 13,
	InvoiceDetail = 77,
	LocationContract = 39,
	Metrics = 99,
	Milestone = 14,
	MyClients = 85,
	NA = 0,
	OnSet = 62,
	OnSetCallsheet = 51,
	OnSetProductionReport = 52,
	OpenRequest = 96,
	Opportunity = 22,
	Order = 3,
	OrderCatalog = 98,
	Org = 15,
	OrgAdmin = 16,
	OrgJobTypes = 93,
	OrgSupplier = 35,
	PaymentGroup = 101,
	PlacementFee = 12,
	PO = 19,
	Preinvoice = 100,
	ProdOfficeCast = 56,
	ProdOfficeCrew = 55,
	ProdOfficeStudio = 54,
	ProdOfficeSupplier = 57,
	ProdOffice = 64,
	Project = 1,
	ProjectMilestone = 75,
	ProjectSupplierMetric = 66,
	Reports = 65,
	ServiceReceipt = 11,
	ShortTerm = 97,
	SiteAdmin = 58,
	Sourcing = 61,
	SourcingAssetBook = 45,
	SourcingCompetitiveBids = 73,
	SourcingElementTracking = 46,
	SourcingElementTrackingPost = 71,
	SOW = 24,
	SummarizedExtension = 95,
	Supplier = 17,
	SupplierAdmin = 48,
	TAExecutive = 87,
	TAMarketing = 88,
	TAProduction = 38,
	TATemplateExecutive = 91,
	TATemplateMarketing = 92,
	TATemplateProduction = 90,
	Tenure = 69,
	Timesheet = 9,
	TimesheetBonuses = 82,
	User = 21,
	UserAdmin = 47,
	UserEqualOps = 80,
	VacationRequest = 29,
	Wiki = 25,
	WorkflowActivity = 23,
	WorkgroupAdmin = 43
}

export enum PageSelectPosition  {
    
	Both = 0,
	Top = 1,
	Bottom = 2
}

export enum PayTypeUsed  {
    
	None = 0,
	Ratecard = 1,
	PayGroup = 2,
	ComparativeEarning = 3,
	IndividualRegulation = 4,
	IndividualRegulationNoIncreases = 5
}

export enum Region  {
    
	East = 1,
	West = 2
}

export enum Status  {
    
	Error = 0,
	Success = 1
}



import { blockUI, ShowAlert } from "frontpage/frontpageHelper";
import { HttpClient } from "../component/httpClient/httpClient";
import Cookies from 'js-cookie';
import { Util } from '../helper/util';
import { KeyCodes } from '../enum/keyCodes';

export class Login {

    public static init() {

        this.rememberMe();

        $("#submitButton").on("click", (event) => {
            this.checkLoginForSso(true);
        });

        $("#nextButton").on("click", (event) => {
            this.checkLoginForSso(true);
        });

        $(".login-form").validate({
            errorElement: "span", // default input error message container
            errorClass: "help-block", // default input error message class
            focusInvalid: false, // do not focus the last invalid input
            rules: {
                username: {
                    required: true,
                },
                password: {
                    required: true,
                },
                remember: {
                    required: false,
                },
            },

            messages: {
                username: {
                    required: $("input[name=username]").data("validationmessage"),
                },
                password: {
                    required: $("input[name=password]").data("validationmessage"),
                },
            },

            invalidHandler(event, validator) { // display error alert on form submit
                ShowAlert(validator);
            },

            highlight(element) { // hightlight error inputs
                $(element)
                    .closest(".form-group").addClass("has-error"); // set error class to the control group
            },

            success(label) {

                label.closest(".form-group").removeClass("has-error");
                label.remove();
            },

            errorPlacement(error, element) {
                error.insertAfter(element.closest(".input-icon"));
            },

            submitHandler(form) {

                blockUI();

                // we need a little timeout for awesome IE. Otherwise it will not render image and animation
                // form validation success, call ajax form submit
                setTimeout(() => form.submit(), 50);
            },
        });

        $(".login-form input").keypress(function (e) {
            if (Util.getKeyCode(e) === KeyCodes.Enter) {

                Login.checkLoginForSso(true);

                return false;
            }
            return true;
        });
    }

    private static checkLoginForSso(submit: boolean) {
        const userName = $("#username").val();

        if (Login.isNonEmptyString(userName)) {
            blockUI();

            Login.getUserSSOConfig(userName as string)
                .then((result) => {
                    if (result !== "sso") {
                        $("#username").prop("disabled", true);
                        $("#password").removeClass("hidden");
                        $("#password").prop("disabled", false);
                        $("#nextButton").addClass("hidden");
                        $("#submitButton").removeClass("hidden");
                        $("#password").trigger("focus");
                        $("#remembermeContainer").addClass("hidden");
                        $("#otherUsername").removeClass("hidden");

                        const tfaEnabled = result === "tfa";

                        if (tfaEnabled) {
                            $("#tfaPin").removeClass("hidden");
                            $("#tfaPin").prop("disabled", false);
                            $("#tfaPinSupport").removeClass("hidden");
                        }

                        const password = $("#password").val();
                        const tfaPin = $("#tfaPin").val();

                        if (Login.isNonEmptyString(password) && submit && (!tfaEnabled || Login.isNonEmptyString(tfaPin))) {
                            $("#username").prop("disabled", false);
                            $("#submitButton").prop("disabled", true);
                            document.querySelector<HTMLFormElement>(".login-form").submit();
                        } else {
                            $.unblockUI();
                        }
                    } else {
                        $("#submitButton").trigger("focus");
                        if (submit) {
                            $("#username").prop("disabled", false);
                            $("#submitButton").prop("disabled", true);
                            document.querySelector<HTMLFormElement>(".login-form").submit();
                        } else {
                            $.unblockUI();
                        }
                    }
                })
                .catch(() => {
                    $.unblockUI();
                });
        }
    }
    private static rememberMe() {
        // check if rememberme was checked and fill in username
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("otherUserName")) {
            Cookies.remove("Login");
            return;
        }

        var cookie = Cookies.get("Login");
        if (cookie?.includes("?rememberme=1"))
        {
            $("#rememberme").attr("checked", "checked");
            $("#username").val(cookie.substring(23));
            $("#password").trigger("focus");
            this.checkLoginForSso(false);                
        }
    }

    private static isNonEmptyString(val: any): boolean {
        const result = (typeof val === "string" && !Login.isStringNullOrWhiteSpace(val));

        return result;
    }

    private static getUserSSOConfig(userName: string): Promise<string> {

        const url = `user/getUserSsoConfiguration`;
        const params = new URLSearchParams();
        params.append("userName", userName);

        return HttpClient.httpGet<string>(url, params);
    }

    private static getUserTfaConfig(userName: string): Promise<string> {
        const url = `user/getUserTfaConfiguration`;
        const params = new URLSearchParams();
        params.append("userName", userName);

        return HttpClient.httpGet<string>(url, params);
    }

    private static isStringNullOrWhiteSpace(str: string): boolean {
        return str === undefined || str === null
            || typeof str !== "string"
            || str.match(/^ *$/) !== null;
}

}

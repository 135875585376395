import { ChartColor, ChartConfiguration, ChartDataSets, LinearTickOptions } from "chart.js";
import { Locale } from "component/localeManager/localeManager";
import { Util } from "helper/util";

// Public Locale Class
export class ChartjsHelper {

    public static collectValuesAndLabelsAndGetTotalValue(json: string[][], chartData: string[][], labels: string[], values: number[][], chartType: string, chartLabels: string[]): number[] {

        if (!json[0]) {
            return [0];
        }

        for (let i = 1; i < json.length; i++) {
            chartData.push(json[i]);
        }

        const totalValues: number[] = [0];

        // if we have a single data row than all labels are in the first json "datarow" and all values are in the second "datarow"...
        if (chartType === "pie" && chartData.length === 1) {

            for (const item of json[0]) {
                labels.push(item);
            }

            values[0] = [];

            for (let i = 0; i < labels.length; i++) {

                let currentValue = 0;
                if (chartData[0][i] && Util.isNumeric(Number(chartData[0][i]))) {
                    currentValue = Number(chartData[0][i]);
                }

                values[0].push(currentValue);
                labels[i] = labels[i] + ": " + currentValue;
                totalValues[0] += currentValue;
            }
        } else {
            let labelIndex: number;

            // go through all "rows" of json data
            for (let row = 0; row < chartData.length; row++) {

                let valueIndex = 0;

                labelIndex = chartData[row].length - 1;

                // go through all "columns" of json data
                for (let column = 0; (chartData[row]) && column < chartData[row].length; column++) {

                    if (!(chartData[row][column]) || !Util.isNumeric(Number(chartData[row][column]))) {
                        labelIndex = column;
                    }

                    if (column === labelIndex) {
                        labels[row] = chartData[row][column];
                    } else {

                        if (!values[valueIndex]) {
                            values[valueIndex] = [];
                        }

                        values[valueIndex].push(Number(chartData[row][column]));

                        totalValues[valueIndex] += Number(chartData[row][column]);
                        valueIndex++;
                    }
                }

            }

            for (let i = 0; (json[0][i]) && i < json[0].length; i++) {
                if (i !== labelIndex) {
                    chartLabels.push(json[0][i]);
                }
            }

            if (chartType === "pie") {
                for (let i = 0; i < labels.length; i++) {
                    if (values[0][i]) {
                        labels[i] += ": " + values[0][i].toLocaleString(Locale.getCurrentLanguage());
                    }
                }
            }
        }

        for (let i = 0; i < totalValues.length; i++) {
            if (!Util.isNumeric(totalValues[i])) {
                totalValues[i] = 0;
            }
        }

        return totalValues;
    }

    public static getTitleText(titleAttributeValue: string, totalValues: number[], chartType: string): string {

        let titleText = "";

        if (titleAttributeValue) {
            titleText = titleAttributeValue;
        } else if (chartType === "line") {
            return titleText;
 } else {
            titleText = Locale.getTranslation("total");
 }

        if (!(totalValues.length > 1)) {
            titleText += ": " + totalValues[0].toLocaleString(Locale.getCurrentLanguage());
        }

        return titleText;
    }

    public static getChartConfiguration(chartType: string, labels: string[], chartlabels: string[], totalValues: number[], titleText: string, values: number[][], showGridLines: boolean): ChartConfiguration {

        const defaultBackgroundColor: ChartColor[] = ["#002776", "#0090DA", "#404040"];

        const initialColorCounter = defaultBackgroundColor.length;

        const shadeFactor = 0.4;

        // if our color palette is not sufficient add further colors
        if (values[0] && values[0].length > defaultBackgroundColor.length) {

            for (let i = defaultBackgroundColor.length; i < values[0].length; i++) {
                defaultBackgroundColor.push(ChartjsHelper.shadeColor(defaultBackgroundColor[defaultBackgroundColor.length - initialColorCounter + 1].toString(), shadeFactor));
            }
        }

        // var hoverColor: ChartColor[] = [];

        // doesn't work properly -> the tooltip legend color is always black if enabled and not depending on the hovered arc
        // for (var i = 0; i < backgroundColor.length; i++) {
        //    hoverColor.push("Orange");
        // }

        const dataSets: ChartDataSets[] = [];

        for (let i = 0; i < values.length; i++) {

            if (values[0].length > 100 && ChartjsHelper.allValuesAreZero(values[i])) {

                chartlabels.splice(i, 1);
                continue;
            }

            let chartBackGroundColor: ChartColor[] = [];

            if (chartType !== "pie") {
                // we want all bars of the same dataset look the same
                for (const value in values[i]) {
                    if (defaultBackgroundColor[i]) {
                        chartBackGroundColor.push(defaultBackgroundColor[i]);
                    } else {
                        chartBackGroundColor.push(defaultBackgroundColor.pop());
                    }
                }
            } else {
                chartBackGroundColor = defaultBackgroundColor;
            }

            const dataSet: ChartDataSets = {};

            if (chartlabels[i]) {
                dataSet.label = chartlabels[i];
            }

            dataSet.data = values[i];
            dataSet.backgroundColor = chartBackGroundColor;

            if (chartType === "line") {
                if (chartBackGroundColor[i]) {
                    dataSet.borderColor = chartBackGroundColor[i];
                } else
                    if (chartBackGroundColor) {
                        dataSet.borderColor = chartBackGroundColor[chartBackGroundColor.length - 1];
                    }

                if (values.length > 20) {
                    dataSet.borderWidth = 0.9;
                }
            }

            if ((chartType === "bar" || chartType === "bar stacked" || chartType === "line") && values[0] && values[0].length < 4) {
                dataSet.barPercentage = 0.5;
            }

            

            if (chartType === "pie" || dataSet.label) {
                dataSets.push(dataSet);
            }
        }

        const chartConfiguration: ChartConfiguration = {};

        if (chartType === "bar stacked") {
            chartConfiguration.type = "bar";
        } else {
            chartConfiguration.type = chartType;
        }

        chartConfiguration.data = {
            datasets: dataSets,
            labels,
        };

        chartConfiguration.options = {
            responsive: true,
            maintainAspectRatio: true,
            tooltips: {
                callbacks: {
                    label(tooltipItem, data) {
                        let currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        if (!Util.isNumeric(currentValue)) {
                            currentValue = 0;
                        } else {
                            currentValue = Number(currentValue);
                        }

                        let label = "";
                        if (chartType === "pie") {
                            label += data.labels[tooltipItem.index] + " (" + Math.round((currentValue / (totalValues[tooltipItem.datasetIndex] == 0 ? 1 : totalValues[tooltipItem.datasetIndex])) * 100) + " %)";
                        } else {
                            label += currentValue.toLocaleString(Locale.getCurrentLanguage());
                        }

                        return label;
                    },
                },
            },
            title: {
                display: true,
                text: titleText,
                fontFamily: "'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            },
            legend: {
                onHover(e: MouseEvent) {
                    $(e.target).css("cursor", "pointer");
                },
                labels: {
                    fontFamily: "'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                },
            },
        };

        if (chartType === "pie") {
            chartConfiguration.options.maintainAspectRatio = false;
        }

        if (chartType === "bar" || chartType === "bar stacked" || chartType === "line") {
            chartConfiguration.options.scales = {};
            chartConfiguration.options.scales.yAxes = [];
            chartConfiguration.options.scales.yAxes[0] = {};
            chartConfiguration.options.scales.yAxes[0].ticks = {};
            (chartConfiguration.options.scales.yAxes[0].ticks as LinearTickOptions).beginAtZero = true;

            chartConfiguration.options.scales.yAxes[0].ticks.callback = function(value, index, values) {

                if (Number(value)) {
                    return value.toLocaleString(Locale.getCurrentLanguage());
                }
                return value;
            };

            chartConfiguration.options.scales.xAxes = [];
            chartConfiguration.options.scales.xAxes[0] = {};

            chartConfiguration.options.scales.yAxes[0].gridLines = {};
            chartConfiguration.options.scales.xAxes[0].gridLines = {};

            if (chartType === "line" && values[0].length < 2) {
                chartConfiguration.options.scales.xAxes[0].gridLines.offsetGridLines = true;
            }

            if (!showGridLines) {

                chartConfiguration.options.scales.yAxes[0].gridLines.display = false;
                chartConfiguration.options.scales.xAxes[0].gridLines.display = false;
            }

            let longestLabel = "";

            if (labels.length > 1) {
                longestLabel = labels.reduce(function(a, b) { return a.length > b.length ? a : b; });
            }

            if ((longestLabel.length > 20 || (values[0] && values[0].length > 12)) && (values[0] && values[0].length < 100)) {

                chartConfiguration.options.scales.xAxes[0].ticks = {};
                chartConfiguration.options.scales.xAxes[0].ticks.autoSkip = false;
                chartConfiguration.options.scales.xAxes[0].ticks.minRotation = 90;
                chartConfiguration.options.scales.xAxes[0].ticks.maxRotation = 90;
            }

            if (chartType === "bar stacked") {

                chartConfiguration.options.scales.yAxes[0].stacked = true;
                chartConfiguration.options.scales.xAxes[0].stacked = true;
            }
        }

        return chartConfiguration;
    }

    private static shadeColor(color: string, percent: number) {
        const f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = f >> 8 & 0x00FF, B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    }

    private static allValuesAreZero(values: number[]) {

        for (const value of values) {
            if (value !== 0) {
                return false;
            }
        }

        return true;
    }
}

import { ControlData } from "controls/util/controlData";
import { isEqual } from "lodash";
import { Control } from "./control";

export class RangePicker extends Control {

    private readonly lowControl: JQuery<HTMLInputElement>;
    private readonly highControl: JQuery<HTMLInputElement>;

    constructor(fieldContainer: JQuery, form?: FormInterface) {

        super(fieldContainer, form);
        this.lowControl = this.fieldContainer.find("[name*=_low] input") as JQuery<HTMLInputElement>;
        this.highControl = this.fieldContainer.find("[name*=_high] input") as JQuery<HTMLInputElement>;

    }

    public initialize(): void {}

    public clear(triggerCallback = true): void {

        if (!this.hasValue) { return; }

        if (this.isRangeDatePicker()) {
            this.lowControl.parent().data("DateTimePicker").clear();
            this.highControl.parent().data("DateTimePicker").clear();
        } else {
            this.lowControl.val("");
            this.highControl.val("");
        }

        if (triggerCallback) {
            this.runValueChangedCallbacks();
        }
    }

    public disable(): void {
        super.disable();

        // also disable spin-buttons
        if (!this.isRangeDatePicker()) {
            this.fieldContainer.find("button").prop("disabled", true);
        }
    }

    public enable(): void {
        super.enable();

        // also enable spin-buttons
        if (!this.isRangeDatePicker()) {
            this.fieldContainer.find("button").prop("disabled", false);
        }

    }

    public focus(): void {

        this.lowControl.trigger("focus");

    }

    public equals(controlData: ControlDataInterface | ControlDataInterface[]): boolean {

        let compareData = []; // need to transform the given value to array with 2 control data, because this is the standard format of rangepicker
        if (!Array.isArray(controlData)) {
            compareData.push(controlData);
        } else {
            compareData = controlData.slice();
        }

        if (compareData.length === 1) {
            compareData.push(new ControlData("", ""));
        }

        return super.equals(compareData);

    }

    public addValue(controlData: ControlDataInterface): void {

        if (!controlData
            || this.equals(controlData)
            || isEqual(this.getValues()[0], controlData)) { return; }

        this.setValues(controlData); // also triggers value changed callback

    }

    // returns values from range picker or date range picker
    public getValues(): ControlData[] {

        // We need to differentiate between input range and input range datepicker
        if (this.isRangeDatePicker()) {

            const lowISODate = this.lowControl[0].dataset.isoDate;
            const lowDate = new Date(lowISODate);

            const highISODate = this.highControl[0].dataset.isoDate;
            const highDate = new Date(highISODate);

            const lowValue = lowDate ? lowISODate : ""; // check if date is valid. when not use empty string
            const highValue = highDate ? highISODate : "";

            return [new ControlData(lowValue, this.lowControl.val().toString()), new ControlData(highValue, this.highControl.val().toString())];
        } else {
            return [new ControlData(this.lowControl.val().toString()), new ControlData(this.highControl.val().toString())];
        }
    }

    public setValues(values: ControlDataInterface | ControlDataInterface[]): void {

        if (!values || this.equals(values)) { return; }

        let lowValue, highValue;
        if (!Array.isArray(values)) {
            lowValue = values;
        } else {
            lowValue = values[0];
            highValue = values[1];
        }

        if (this.isRangeDatePicker()) {
            this.lowControl
                .parent()
                .data("DateTimePicker")
                .date(new Date(lowValue.value));

            if (highValue) {
                this.highControl
                    .parent()
                    .data("DateTimePicker")
                    .date(new Date(highValue.value));
            }
        } else {
            this.lowControl.val(lowValue.value);
            if (highValue) {
                this.highControl.val(highValue.value);
            }
        }

        this.runValueChangedCallbacks();
    }

    private isRangeDatePicker(): boolean {
        // We need to differentiate between input range and input range datepicker
        return this.fieldContainer.hasClass("input-daterange");
    }

}

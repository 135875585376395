import { doParentSourceChildRelationship } from "component/parentSourceChild/parentSourceChildUtils";
import { ControlData } from "controls/util/controlData";
import { ControlHelper } from "helper/controlHelper";
import { getSelect2Configuration } from "controls/util/select2Configuration";
import { HttpClient } from "../../component/httpClient/httpClient";
import { Locale } from "../../component/localeManager/localeManager";
import { Control, ControlCallbackFunction } from "./control";

export class Select extends Control {

    private readonly selectControl: JQuery;

    constructor(fieldContainer: JQuery, form?: FormInterface) {

        super(fieldContainer, form, "select");

        if (!this.isStaticControl) {
            this.selectControl = this.fieldContainer.find("select");
        }

    }

    public initialize(): void {

        this.initOnchangeRedirect();
        this.initParentSourceChild();
        this.initParentControl();
        ControlHelper.applyParentControlListener(this.fieldContainer, this);
    }

    public clear(): void {

        if (!this.hasValue) { return; }

        if (!this.isStaticControl) {
            // clear control with null and trigger select2 change event
            this.selectControl.val(null).trigger("change");
        } else {
            super.clearStaticInputBasedElement();
        }
    }

    public getValues(): ControlDataInterface[] {

        if (this.isStaticControl) {

            return super.getStaticSingleField();

        } else {

            return [this.getSelect2Value()];

        }
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }
    }

    public resetOptions(): void {

        if (this.isStaticControl) { return; }

        this.selectControl.html("");

        if (!this.getDataAttribute("data-field-attribute-no-blank")) {
            this.addOptions([new ControlData("", Locale.getTranslation("select"))]);
        }
    }

    public addOptions(childData: ControlDataInterface[]): void {

        if (this.isStaticControl) { return; }

        for (const item of childData) {
            this.selectControl.append(`<option value='${item.value}'>${item.displayValue}</option>`);
        }
    }

    public disableAllOptions(): void {

        if (this.isStaticControl) { return; }

        this.selectControl.find("option").prop("disabled", true);

        // need to trigger select2 again to update the disabled options
        this.selectControl.select2(getSelect2Configuration());
    }

    public enableOptions(childData?: ControlDataInterface[]): void {

        if (this.isStaticControl) { return; }

        if (childData) {
            for (const item of childData) {
                this.selectControl.find(`option[value='${item.value}']`).prop("disabled", false);
            }
        } else {
            this.selectControl.find("option").prop("disabled", false);
        }

        // need to trigger select2 again to update the enabled options
        this.selectControl.select2(getSelect2Configuration());
    }

    private initParentControl(): void {

        if (this.isStaticControl) { return; }


        this.bindOnValueChanged(() => {

            // Trigger Event to execute parentcontrol clear functionality
            this.fieldContainer.trigger("notifyChildControls", this.getValues()[0].value);

        });

    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        if (this.isStaticControl) { return; }

        super.bindOnValueChanged(callback);

        this.selectControl.on("change", () => {
            callback();
        });
    }

    private getSelect2Value(): ControlDataInterface {

        const selectedOptions = this.fieldContainer.find("option:selected");

        if (selectedOptions.length === 0) {
            return new ControlData("", "");
        }

        const value = selectedOptions.val() as string;
        const displayValue = selectedOptions.text();

        if (!value) {
            return new ControlData("", "");
        }

        return new ControlData(value || "", displayValue || "");

    }

    private setValue(controlData: ControlDataInterface): void {

        if (!this.isStaticControl) {
            this.setSelect2(controlData);
        } else {
            super.setStaticField(controlData.value, controlData.displayValue);
        }
    }

    private setSelect2(controlData: ControlDataInterface): void {
        this.selectControl.val(controlData.value);
        this.selectControl.trigger("change");
    }

    private initOnchangeRedirect(): void {

        if (this.isStaticControl) { return; }

        const pageName = this.selectControl.attr("data-onchange-redirect");

        if (pageName != null) {

            this.bindOnValueChanged(() => {
                this.doRedirect(this.selectControl, pageName);

            });
        }
    }

    private doRedirect(selectTag: JQuery, pageName: string): void {

        const paramName = selectTag.attr("name");
        const paramValue = selectTag.find(":selected").val();
        if (paramName != null && paramValue != null) {

            // if there is a value in data attribute we will use it as pagename
            // otherwise we use current page and change param
            if (pageName.length > 0) {
                HttpClient.browserRedirect(`${pageName}?${paramName}=${paramValue.toString()}`);
            } else {
                const params = new URLSearchParams(window.location.search.slice(1));
                params.set(paramName, paramValue.toString());
                window.location.search = `?${params.toString()}`;
            }
        }
    }

    private initParentSourceChild(): void {
        doParentSourceChildRelationship(this);

        this.bindOnValueChanged(() => {
          doParentSourceChildRelationship(this);
        });
    }

}

import { blockUI, ShowAlert } from "frontpage/frontpageHelper";
import { Util } from '../helper/util';
import { KeyCodes } from '../enum/keyCodes';

export class Support {

    public static init() {

        $(".support-form").validate({
            focusInvalid: false, // do not focus the last invalid input
            rules: {
                reason: {
                    required: true,
                },
                email: {
                    required: true,
                    email: true,
                },
                lastname: {
                    required: true,
                },

            },

            messages: {
                reason: {
                    required: $("select[name=reason]").data("validationmessage"),
                },
                email: {
                    required: $("input[name=email]").data("validationmessage"),
                    email: $("input[name=email]").data("validationmessage"),
                },
                lastname: {
                    required: $("input[name=lastname]").data("validationmessage"),
                },
            },

            invalidHandler(event, validator) { // display error alert on form submit
                ShowAlert(validator);
            },

            highlight(element) { // hightlight error inputs
                $(element)
                    .closest(".form-group").addClass("has-error"); // set error class to the control group
            },

            success(label) {
                label.closest(".form-group").removeClass("has-error");
                label.remove();
            },

            errorPlacement(error, element) {
                error.insertAfter(element.closest(".input-icon"));
            },

            submitHandler(form) {
                blockUI();

                // we need a little timeout for awesome IE. Otherwise it will not render image and animation
                // form validation success, call ajax form submit
                setTimeout(() => form.submit(), 50);
            },
        });

        $(".support-form input").keypress(function (e) {
            if (Util.getKeyCode(e) === KeyCodes.Enter) { 

                $(".support-form").submit(); // form validation will be triggered when submit 

                return false;
            }
            return true;
        });
    }
}

import { Log } from "component/logging/logging";
import * as Controls from "controls/allControls";
import { ControlTypes } from "enum/allEnums";

export class ControlFactory {

    public static instantiateControlByType(element: JQuery<HTMLElement>, form?: FormInterface): ControlInterface {

        let controlObject: ControlInterface;
        const controlType = parseInt(element.attr("data-control-type")) as ControlTypes;

        switch (controlType) {

            case ControlTypes.OnlyLabel:
            case ControlTypes.Invisible:
            case ControlTypes.Image:
            case ControlTypes.Hypertext:
            case ControlTypes.HypertextWithoutLabel:
                break;

            case ControlTypes.FreeText:
            case ControlTypes.FreetextWithoutEval:
            case ControlTypes.Password:
            case ControlTypes.ReadOnly:
            case ControlTypes.TOTP:
                controlObject = new Controls.FreeText(element, form);
                break;

            case ControlTypes.Datepicker:
                controlObject = new Controls.Datepicker(element, form);
                break;

            case ControlTypes.Hourpicker:
                controlObject = new Controls.Hourpicker(element, form);
                break;

            case ControlTypes.Durationpicker:
                controlObject = new Controls.DurationPicker(element, form);
                break;

            case ControlTypes.HiddenField:
                controlObject = new Controls.HiddenField(element, form);
                break;

            case ControlTypes.Radio:
                controlObject = new Controls.Radio(element, form);
                break;

            case ControlTypes.Bitmask:
                controlObject = new Controls.Bitmask(element, form);
                break;

            case ControlTypes.Checkbox:
                controlObject = new Controls.Checkbox(element, form);
                break;

            case ControlTypes.MultipleCheckbox:
                controlObject = new Controls.MultipleCheckbox(element, form);
                break;

            case ControlTypes.Select:
            case ControlTypes.PeriodOfCurrentAndLastYear:
                controlObject = new Controls.Select(element, form);
                break;

            case ControlTypes.SelectSort:
                controlObject = new Controls.SelectSort(element, form);
                break;

            case ControlTypes.Lookup:
                controlObject = new Controls.Lookup(element, form);
                break;

            case ControlTypes.LookupMultiple:
                controlObject = new Controls.LookupMultiple(element, form);
                break;

            case ControlTypes.Textarea:
                controlObject = new Controls.Textarea(element, form);
                break;

            case ControlTypes.SavableReadOnly:
                controlObject = new Controls.SaveableReadOnly(element, form);
                break;

            case ControlTypes.RangePicker:
            case ControlTypes.RangePickerMonth:
                controlObject = new Controls.RangePicker(element, form);
                break;

            case ControlTypes.SelectMultiple:
                controlObject = new Controls.SelectMultiple(element, form);
                break;

            case ControlTypes.Fileupload:
                controlObject = new Controls.Fileupload(element, form);
                break;

            case ControlTypes.Container:
                controlObject = new Controls.Container(element, form);
                break;

            default:
                const message = `Control Type ${controlType} not implemented! Form ID: ${form.formId}}`;
                Log.logError(message);
        }

        return controlObject;
    }
}

export class Frontpage {

    // main function to initiate the module
    public static init() {
        $(".focus").trigger("focus");

    }
    public static windowLoad() {

        $(".login-bg").backstretch(
            [
                "images/login/bg1.jpg",
                "images/login/bg2.jpg",
                "images/login/bg3.jpg",
                "images/login/bg4.jpg",
                "images/login/bg5.jpg",
            ],
            {
                fade: 1000,
                duration: 8000,
            },
        );

    }
}

import { HttpClient } from "component/httpClient/httpClient";
import { Log } from "component/logging/logging";
import { Dispatcher } from "dispatcher/dispatcher";
import { SubmitHelper } from "dispatcher/submitHelper";
import { FormAction, ModalType } from "enum/allEnums";
import { FormTransfer } from "transfer/FormTransfer";
import { PageTransfer } from "transfer/pageTransfer";
import { HttpClientError } from "../../helper/httpClientError";

export class BackgroundSubmit {

    private readonly form: FormInterface;

    constructor(form: FormInterface) {
        this.form = form;
    }

    public wireBackgroundSubmit(backgroundSubmitButtons: NodeListOf<Element> | Element[]): void {

        for (let i = 0; i < backgroundSubmitButtons.length; i++) {

            backgroundSubmitButtons[i].addEventListener("click", (event: Event) => {

                event.preventDefault();

                const buttonElement = $(event.target).closest("a") as JQuery<HTMLAnchorElement>;

                this.backgroundSubmitAndRedirect(buttonElement);
            });
        }
    }

    public async backgroundSubmitAndRedirect(buttonElement: JQuery<HTMLButtonElement | HTMLAnchorElement>, additionalSessionProperties?: Dictionary<string | number>): Promise<void> {

        const { pageName, formName, successUrl, sessionPropertiesAndValues } = this.getSubmitData(buttonElement, additionalSessionProperties);

        if (!pageName || !formName) {
            return Promise.resolve();
        }

        const hasSucceeded = await this.backgroundSubmit(pageName, formName, sessionPropertiesAndValues);
        if (hasSucceeded) {
            this.redirectToUrl(successUrl);
        }
    }

    /**
     * Returns true when submit was successfull and no error messages were returned
     * @param url
     * @param formName
     * @param sessionPropertiesAndValuesToSet
     */
    public async backgroundSubmit(url: string, formName: string, sessionPropertiesAndValuesToSet?: Dictionary<string | number>): Promise<boolean> {


        try {
            const pageTransfer = await this.getPageTransfer(url, formName, sessionPropertiesAndValuesToSet);

            const data = await SubmitHelper.submitData({
                submitUrl: "update.app",
                currentPage: HttpClient.getPageName(url),
                formData: Dispatcher.createFormDataIncludingURLParameters(pageTransfer),
            }) as ResponseMessage;

            this.submitDoneHandler(data);

            return !data.HasMessages;
        } catch (e) {
            this.submitErrorHandler(e);
        }

        return false;
    }

    private getSubmitData(buttonElement: JQuery<HTMLButtonElement | HTMLAnchorElement>, additionalSessionProperties?: Dictionary<string | number>): {
        pageName: string,
        formName: string,
        successUrl: string,
        sessionPropertiesAndValues: Dictionary<string | number>;
    } {

        const pageName = buttonElement.attr("data-backgroundsubmit-page-name");
        const formName = buttonElement.attr("data-backgroundsubmit-form-name");
        const successUrl = buttonElement.attr("data-backgroundsubmit-success-url");

        const sessionPropertiesAndValuesToSetJson = buttonElement.attr("data-backgroundsubmit-session-properties-to-set");
        const sessionPropertiesAndValues: Dictionary<string | number> = JSON.parse(sessionPropertiesAndValuesToSetJson);
        if (additionalSessionProperties) {
            $.extend(sessionPropertiesAndValues, additionalSessionProperties);
        }

        return { pageName, formName, successUrl, sessionPropertiesAndValues };

    }

    private async getPageTransfer(url: string, formName: string, sessionPropertiesAndValuesToSet?: Dictionary<string | number>): Promise<PageTransfer> {

        const pageTransfer = new PageTransfer();

        pageTransfer.modalType = ModalType.None;

        pageTransfer.action = FormAction.BackgroundSubmit;

        $.blockUI();

        const formId = await this.getFormId(formName);

        const formTransfer: FormTransfer = new FormTransfer(formId);

        pageTransfer.addForm(formTransfer);

        const sessionValuesByProperties: Dictionary<string | number> = {};

        HttpClient
            .getUrlParameters(url)
            .forEach((value, name) => {
                sessionValuesByProperties[name] = value;
            });

        if (sessionPropertiesAndValuesToSet) {
            for (const sessionProperty in sessionPropertiesAndValuesToSet) {
                sessionValuesByProperties[sessionProperty] = sessionPropertiesAndValuesToSet[sessionProperty];
            }
        }

        pageTransfer.sessionValuesByProperties = sessionValuesByProperties;

        return pageTransfer;
    }

    private redirectToUrl(url: string): void {
        if (url === "") {
            window.location.reload();
        } else if (url != null) {
            HttpClient.browserRedirect(url);
 }
    }

    private getFormId(formName: string): Promise<string> {

        const url = "Form/GetFormId";
        const params = new URLSearchParams();
        params.append("formName", formName);
        return HttpClient.httpGet<string>(url, params);
    }

    private submitDoneHandler(data: ResponseMessage): void {

        $.unblockUI();
        if (data.HasMessages) {

            const messages = data.Messages.join().toString();
            Log.logInfo(messages);
            this.form.showInlineMessage(messages, "error");
        }

    }

    private submitErrorHandler(error: HttpClientError): void {
        $.unblockUI();
        Log.logError("BackgroundSubmit: Post failed: " + error.message);
        this.form.showInlineMessage(error.message, "error");
    }

}

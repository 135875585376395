import { Locale } from "component/localeManager/localeManager";
import { Form } from "forms/form";
import { FormStyles } from "uiFramework/enum/allEnums";

export class CalendarForm extends Form {

    constructor(dispatcher: DispatcherInterface, portletBody: JQuery, formCallbacks?: FormCallback | FormCallback[]) {

        super(dispatcher, portletBody, formCallbacks);

    }

    public get formStyle(): FormStyles {
        return FormStyles.Calendar ;
    }

    public async initializeForm(): Promise<void> {

        await this.initCalendar(this.portletBody);

        super.initializeButtons();

        super.setFormInitialized();
    }

    public isValid(): boolean {
        return true;
    }

    private async initCalendar(context?: JQuery): Promise<void> {

        const element = $("#calendar", context).get(0);

        const { Calendar } = await import(/* webpackChunkName: "fullcalendar" */ '@fullcalendar/core');
        const { default: dayGridPlugin } = await import(/* webpackChunkName: "fullcalendar" */ '@fullcalendar/daygrid');
        const { default: timeGridPlugin } = await import(/* webpackChunkName: "fullcalendar" */ '@fullcalendar/timegrid');
        const { default: bootstrapPlugin } = await import(/* webpackChunkName: "fullcalendar" */ '@fullcalendar/bootstrap');
        const { default: deLocale } = await import(/* webpackChunkName: "fullcalendar" */ '@fullcalendar/core/locales/de');



        const language = Locale.getCurrentLanguage().substr(0, 2);
        let locale = null;
        if (language === "de")
            locale = deLocale;


        const calendar = new Calendar(element, {
            initialView: 'dayGridMonth',
            locale: locale,
            headerToolbar: {
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
            },
            events:
            {
                url: "calendar_feed.app",
            },
            plugins: [dayGridPlugin, timeGridPlugin , bootstrapPlugin],
            themeSystem: 'bootstrap'
        });

        calendar.render();

    }

    
}

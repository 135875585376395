import { ControlData } from "controls/util/controlData";
import { ControlHelper } from "helper/controlHelper";
import { LookupHelper } from "uiFramework/helper/lookupHelper";
import { Control } from "./control";

export class LookupMultiple extends Control {

    get isEnabled(): boolean {
        return !this.fieldContainer.find("input").prop("disabled");
    }

    private get isSortable(): boolean {
        const selectTag = this.fieldContainer.find("select");

        return selectTag.hasClass("sortable");
    }

    private readonly selectorPopupButton = "button.popup-multiple";
    private readonly selectorClearButton = "button.clearpopup-multiple";

    constructor(fieldContainer: JQuery, form?: FormInterface) {

        super(fieldContainer, form, "select");

    }

    public initialize(): void {

        LookupHelper.initializeLookupModal(this.selectorPopupButton, this.fieldContainer, this);
        LookupHelper.initializeLookupClear(this.selectorClearButton, this.fieldContainer, this);

        this.initSelect2Event();

        ControlHelper.applyParentControlListener(this.fieldContainer, this);

        if (!this.isStaticControl) {
            LookupHelper.disableInput(this.fieldContainer);
        }
    }

    public focus(): void {

        this.fieldContainer.find(".select2-search__field").focus();

    }

    public clear(triggerCallback = true): void {

        if (!this.hasValue) { return; }

        if (this.isStaticControl) {

            super.clearStaticInputBasedElement();

        } else {

            const visibleControl = this.fieldContainer.find("select");
            const oldValues      = [this.getValues()]; // save old Values before clear control

            // clear control with null and trigger select2 change event
            visibleControl.val(null);

            // remove options in dom
            visibleControl.empty();

            // Trigger Event to execute parentcontrol clear functionality
            this.fieldContainer.trigger("notifyChildControls", oldValues); // array in array to prevent flattening by jquery

        }

        if (triggerCallback) {
            this.runValueChangedCallbacks();
        }

    }

    public enable(): void {

        if (this.isStaticControl) { return; }

        this.fieldContainer.find("input").prop("disabled", false);
        this.fieldContainer.find("button").prop("disabled", false);

    }

    public disable(): void {

        if (this.isStaticControl) { return; }

        this.fieldContainer.find("input").prop("disabled", true);
        this.fieldContainer.find("button").prop("disabled", true);

    }

    public getValues(): ControlDataInterface[] {

        if (this.isStaticControl) {

            return this.addEmptyControlDataWhenEmpty(super.getStaticMultipleField());

        } else {

            if (this.isSortable) {
                return this.addEmptyControlDataWhenEmpty(super.getValuesFromSortableSelect());
            } else {
                return this.addEmptyControlDataWhenEmpty(this.getValuesStandard());
            }

        }

    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        this.clear(false);

        if (!Array.isArray(controlData)) {
            this.addValue(controlData, false);
        } else {
            for (const cd of controlData) {
                this.addValue(cd, false);
            }
        }

        // Trigger Event to execute parentcontrol clear functionality
        this.fieldContainer.trigger("notifyChildControls", [this.getValues()]); // array in array to prevent flattening by jquery

        this.runValueChangedCallbacks();
    }

    /**
    * Add the value of a multiple lookup field
    * @param textValue the visible value
    * @param guidValue the guid value submitted to the server
    */
    public addValue(controlData: ControlDataInterface, triggerCallback = true): void {

        if (!controlData || this.containsValue(controlData)) { return; }

        if (this.isStaticControl) {
            super.addStaticValueMultipleField(controlData);
        } else {
            this.addSelect2BoxOption(controlData.displayValue, controlData.value);
        }

        if (triggerCallback) {
            this.runValueChangedCallbacks();
        }

    }

    private getValuesStandard(): ControlDataInterface[] {

        const controls = this.fieldContainer.find("option:selected");
        const values: ControlDataInterface[] = [];
        controls.each((i: number, elem: Element) => {
            values.push(new ControlData($(elem).val().toString(), $(elem).text()));
        });
        return values;
    }

    // Add an entry to a select2 multiple control
    private addSelect2BoxOption(text: string, value: string): void {

        // We need to do 2 steps to add the new entry
        // First we need to add a new <option> tag to the select box, if not already exists
        // Second we need to add the value to the control to mark it as selected

        const selectControl = this.fieldContainer.find("select");
        if ( selectControl.length > 0 ) {

            const existingOption = selectControl.find(`option[value='${value}']`);

            // check if entry already exists, if so then remove it
            if (existingOption.length > 0) {
                existingOption.remove();
                // Trigger Event to execute parentcontrol clear functionality only for removal
                this.fieldContainer.trigger("notifyChildControls", [this.getValues()]); // array in array to prevent flattening by jquery
            } else {
                // only add option if not exists
                // Add option to hidden select control
                selectControl.append(new Option(text, value));
            }

            // Add the value to the visible control
            const oldValues: string[] = selectControl.val() as string[];

            if ( $.inArray(value, oldValues) === -1 ) {
                const newValues: string[] = [value, ...oldValues];
                selectControl.val(newValues);
            }

        }
    }

    private initSelect2Event(): void {

        const selectTag = this.fieldContainer.find("select");

        // need to remove <option> from select, after something is removed with x in select2
        selectTag.on("select2:unselect", (e: any) => {

            const value = e.params.data.id;

            const existingOption = selectTag.find(`option[value='${value}']`);
            // check if entry already exists, if so then remove it
            if (existingOption.length) {
                existingOption.remove();
            }
        });

    }

}

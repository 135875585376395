export function blockUI() {
    $.blockUI.defaults.message = "<div class=\"cssload-thecube\"><div class=\"cssload-cube cssload-c1\"></div><div class=\"cssload-cube cssload-c2\"></div><div class=\"cssload-cube cssload-c4\"></div><div class=\"cssload-cube cssload-c3\"></div></div>";
    $.blockUI.defaults.css = {};
    $.blockUI();
}

export function ShowAlert(validator: JQueryValidation.Validator) {
    $(".alert-danger").removeClass("hide");
    $(".alert-danger").show();
    $(".alert-danger").empty();
    for (const validationError of validator.errorList) {
        $(".alert-danger").append(`<span>${validationError.message}</span>&nbsp;`);
    }
}

import { ControlData } from "controls/util/controlData";
import { Control, ControlCallbackFunction } from "./control";

export class Fileupload extends Control {

    public initialize(): void {

        if (!this.isStaticControl) {
            this.setInitialValue();
        }

    }

    public clear(): void {

        if (!this.hasValue) { return; }

        if (this.isStaticControl) {
            super.clearStaticInputBasedElement();
        } else {
            const fileInput = this.fieldContainer.find(":file");
            fileInput.filestyle("clear");
            fileInput.removeAttr("value");
        }

        this.runValueChangedCallbacks();
    }

    public enable(): void {

        if (this.isStaticControl) { return; }

        this.fieldContainer.find(":file").filestyle("disabled", false);

    }

    public disable(): void {

        if (this.isStaticControl) { return; }

        this.fieldContainer.find(":file").filestyle("disabled", true);

    }

    public focus(): void {

        // fileuploads are not possible to focus,
        // because input element is disabled
        return;

    }
    public getValues(): ControlDataInterface[] {

        if (this.isStaticControl) {
            return super.getStaticSingleField();
        } else {
            return [this.getValueFromFileupload()];
        }
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!this.isStaticControl || !controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }

            this.setValue(controlData[0]);
        }
    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        super.bindOnValueChanged(callback);
        this.fieldContainer.find("input").on("change", () => callback());
    }

    private setValue(controlData: ControlDataInterface): void {

        if (this.isStaticControl) {
            super.setStaticField(controlData.value, controlData.displayValue);
        } else {
            return; // not supported, because it is not reasonable to skip the file choosing dialog
        }
    }

    private setInitialValue() {
        // when there is already a file uploaded, we want to render the filename on page load
        const initialValue = this.fieldContainer.find(":file").attr("value");
        this.fieldContainer.find(":text").val(initialValue);
    }

    private getValueFromFileupload(): ControlDataInterface {

        const fileInput = this.fieldContainer.find("input[type=file]").get(0) as HTMLInputElement;

        if (fileInput == null) {
            throw new Error(`${this.fieldName}: Input Element not found`);
        }

        if (fileInput.files.length > 0) {
            return new ControlData(fileInput.files[0].name);
        } else {
            // fallback because its possible that no new file was uploaded when it was uploaded before
            return new ControlData(fileInput.getAttribute("value") || "");
        }
    }
}

export type BootstrapBreakpoints = "xs" | "sm" | "md" | "lg";

export class Util {

    public static readonly loginPage: string = "default.cshtml";

    public static isDictionaryNullOrEmpty(dictionary: Dictionary<any>): boolean {

        return dictionary === null || dictionary === undefined || Object.keys(dictionary).length === 0;

    }

    // check whether a date is not a"Invalid Date"
    public static isValidDate(date: Date): boolean {
        return !isNaN(date.getFullYear());
    }

    // type check for numeric values, copied from Angular 4.3
    // https://github.com/angular/angular/blob/4.3.x/packages/common/src/pipes/number_pipe.ts#L172
    public static isNumeric(value: any): boolean {

        return !isNaN(value - parseFloat(value));

    }

    public static stringIsNullOrEmpty(value: string): boolean {

        return (!value || value.length === 0);
    }

    public static toTitleCase(str: string): string {

        return str.replace(/\w\S*/g, function (txt: string): string { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });

    }

    public static isTime(value: string): boolean {

        return value.indexOf(":") !== -1;
    }

    public static convertTimeToIndustryHours(valueAsTime: string): string {

        if (!this.isTime(valueAsTime)) {
            return valueAsTime;
        }

        const tempArray: string[] = valueAsTime.split(":");
        const hours: number = parseInt(tempArray[0], 10);
        const minutes: number = parseInt(tempArray[1], 10);

        let industryHours: number = hours;
        industryHours += minutes / 60;

        return industryHours.toFixed(2);

    }

    public static convertIndustryHoursToTime(valueAsIndustryHours: string): string {

        if (valueAsIndustryHours.indexOf(":") !== -1) {

            const tempArray = valueAsIndustryHours.split(":");
            let hours = tempArray[0];
            const minutes = tempArray[1];

            hours = this.convertToDoubleDigits(hours);

            valueAsIndustryHours = `${hours}:${minutes}`;

        }

        return valueAsIndustryHours;
    }

    public static convertToDoubleDigits(value: string): string {

        if (value.length === 1) {
            value = 0 + value;
        }

        return value;
    }

    public static redirectToLoginPage(): void {

        // OVS: Disabled because in some cases this will cause problems
        // window.location.href = this.loginPage + "?m=SESSION_EXPIRED_OR_MISSING&why=30";

    }

    public static getViewPort() {

        if (!("innerWidth" in window)) {

            const d = document.documentElement || document.body;

            return {
                width: d.clientWidth,
                height: d.clientHeight,
            };
        }

        return {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    public static getResponsiveBreakpoint(size: BootstrapBreakpoints): number {

        // bootstrap responsive breakpoints
        const sizes = {
            xs: 480,
            sm: 768,
            md: 992,
            lg: 1200, // large
        };
        return sizes[size] ? sizes[size] : 0;
    }

    public static isBreakpointActive(breakpoint: BootstrapBreakpoints): boolean {

        const viewportWidth = Util.getViewPort().width;

        const breakpointWidth = Util.getResponsiveBreakpoint(breakpoint);

        return viewportWidth < breakpointWidth;
    }

    public static isTouchDevice(): boolean {

        // feature not supported by Opera Mini, IE<=9, Android<=2.3, ancient, or obscure; per http://caniuse.com/#feat=matchmedia
        if (!window.matchMedia) { return false; }

        const touchEnabledQuery = window.matchMedia("(touch-enabled),(-moz-touch-enabled),(-ms-touch-enabled),(-webkit-touch-enabled)");
        return touchEnabledQuery.matches || this.isIOS();

    }

    public static isIOS(): boolean {
        // because of IOS hover behavior: need double tap to click when hover shows or hides another element

        // ie11 on windows phone also says iphone
        return /(iPad|iPhone|iPod)/g.test(navigator.userAgent) && !(window as any).MSStream;

    }

    public static htmlDecode(input: string): string {

        if (!input) { return input; }

        const e = document.createElement("div");
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;

    }

    public static formatDataAttribute(name: string): string {

        if (name.lastIndexOf("data-", 0) === 0) {
            return name;
        }
        else {
            return `data-${name}`;
        }

    }

    public static safeEnableControls(...controls: ControlInterface[]): void {

        for (const control of controls) {
            if (!control) { continue; }

            control.enable();
        }
    }

    public static safeDisableControls(...controls: ControlInterface[]): void {
        for (const control of controls) {
            if (!control) { continue; }

            control.disable();
        }
    }

    public static safeClearControls(...controls: ControlInterface[]): void {
        for (const control of controls) {
            if (!control) { continue; }

            control.clear();
        }
    }

    public static safeSetValues(control: ControlInterface, controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!control) { return; }
        control.setValues(controlData);
    }

    public static safeGetFirstValue(control: ControlInterface): string {

        if (!control || !control.hasValue) return "";
        return control.getValues()[0].value;
    }

    public static getKeyCode(event: JQuery.Event): number {
        const theChar = event.key;
        if (theChar?.length === 1) {
            return theChar.toUpperCase().codePointAt(0);
        }
        switch (theChar) {
            case "Backspace":
                return 8;
            case "Tab":
                return 9;
            case "Enter":
                return 13;
            case "Escape":
                return 27;
            case "PageUp":
                return 33;
            case "PageDown":
                return 34;
           
            case "Unidentified":
                return 0;
        }
        return 0;
    }

}

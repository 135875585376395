import { ControlData } from "controls/util/controlData";
import { ControlHelper } from "helper/controlHelper";
import { LookupHelper } from "uiFramework/helper/lookupHelper";
import { Control } from "./control";

export class Lookup extends Control {

    private readonly selectorPopupButton = "button.popup";
    private readonly selectorClearButton = "button.clearpopup";

    public initialize(): void {

        LookupHelper.initializeLookupModal(this.selectorPopupButton, this.fieldContainer, this);
        LookupHelper.initializeLookupClear(this.selectorClearButton, this.fieldContainer, this);

        ControlHelper.applyParentControlListener(this.fieldContainer, this);

        if (!this.isStaticControl) {
            LookupHelper.disableInput(this.fieldContainer);
        }
    }

    public clear(): void {

        if (!this.hasValue) { return; }

        if (this.isStaticControl) {

            super.clearStaticInputBasedElement();
            this.fieldContainer.find("input:hidden").removeAttr("data-guid");

        } else {

            const oldValue = this.getValue(); // save oldValue before clear control

            const control = this.fieldContainer.find("input");
            control.removeAttr("data-guid");
            control.val("");

            // Trigger Event to execute parentcontrol clear functionality
            this.fieldContainer.trigger("notifyChildControls", oldValue);

        }

        this.runValueChangedCallbacks();
    }

    public enable(): void {

        if (this.isStaticControl) { return; }

        super.enableElement("input");
        this.fieldContainer.find("button").prop("disabled", false);

    }

    public disable(): void {

        if (this.isStaticControl) { return; }

        super.disableElement("input");
        this.fieldContainer.find("button").prop("disabled", true);

    }

    public getValues(): ControlDataInterface[] {

        return [this.getValue()];
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }
    }

    private getValue(): ControlDataInterface {

        const control = this.fieldContainer.find("input");

        if (!control) {
            return new ControlData("");
        }

        const value = this.nullToEmptyString(control.attr("data-guid"));
        if (this.isStaticControl) {
            const span = this.fieldContainer.find("span");

            return new ControlData(value, span.text());

        } else {
            const displayValue = this.nullToEmptyString(control.val().toString());
            return new ControlData(value, displayValue);
        }

    }

    private setValue(controlData: ControlDataInterface): void {

        if (this.isStaticControl) {
            const control = this.fieldContainer.find("input:hidden");
            control.attr("data-guid", controlData.value);

            const span = this.fieldContainer.find("span");
            span.text(controlData.displayValue);
        } else {

            const control = this.fieldContainer.find("input");
            const oldValue = this.getValue();

            control.attr("data-guid", controlData.value);
            control.val(controlData.displayValue);

            // Trigger Event to execute parentcontrol clear functionality
            this.fieldContainer.trigger("notifyChildControls", oldValue);

        }

        this.runValueChangedCallbacks();
    }

}

import { Modal } from '../../../core/modals/modal';
import { ModalType } from '../../../enum/allEnums';
import { DialogType } from '../../../core/modals/enum/dialogType';
import { HttpClient } from '../../httpClient/httpClient';
import { Locale } from '../../localeManager/localeManager';
import { SessionHubConnector } from './sessionHubConnector';
import { Log } from '../../logging/logging';
import { PageHelper } from '../../../helper/pageHelper';

export class SessionValidator {

    private _modalTimer: NodeJS.Timeout;
    private _logoutTimer: NodeJS.Timeout;
    private _sessionHubConnector: SessionHubConnector;
    private _sessionEndsModal: Modal;

    private readonly MODAL_TIMEOUT = 5; //minutes

    public initialize(hubConnector: SessionHubConnector) {

        this._sessionHubConnector = hubConnector;
        this.registerEvents();

        const expiryTime = this.getExpiryValue();
        this.setExpiryTimers(expiryTime);

    }

    private updateExpiryTime(expiryTime: string) {

        // do this for all tabs, maybe only WebApi was triggered and no Site reload
        document.querySelector("body[data-expires]").setAttribute("data-expires", expiryTime);
        this.setExpiryTimers(expiryTime);

        if (this._sessionEndsModal && this._sessionEndsModal.isActiveModal())
            this._sessionEndsModal.closeModal();
    }

    private loggedOut(redirectUrl: string, allTabs = false) {

        // only redirect not visible tabs, because the visible tab must have triggered the logout action and will be redirected by server
        if (allTabs || document.hidden) {

            HttpClient.browserRedirect(redirectUrl, true);
        }
    }

    private registerEvents() {

        this._sessionHubConnector.RegisterLoggedOutCallback((redirectUrl: string, allTabs: boolean) => {
            this.loggedOut(redirectUrl, allTabs);
        });

        this._sessionHubConnector.RegisterSessionUpdatedCallback((expiryTime: string) => {
            this.updateExpiryTime(expiryTime);
        });
    }

    private getExpiryValue() {

        return document.querySelector("body[data-expires]").getAttribute("data-expires");

    }

    private setExpiryTimers(expiryTime: string) {

        this.clearTimers();

        const expiryDate = new Date(expiryTime);
        const milliseconds = this.getMilliseconds(expiryDate);
        const fiveMinutes = this.MODAL_TIMEOUT * 60 * 1000;
        const millisecondsUntilModal = milliseconds - fiveMinutes;

        if (millisecondsUntilModal > 0) {
            this._modalTimer = setTimeout(() => {

                // show Modal 5minutes before session timeout
                this.showSessionEndsModal(expiryDate);

            }, millisecondsUntilModal);
        }

        if (milliseconds > 0) {

            this._logoutTimer = setTimeout(async () => {

                try {
                    // server will clear session and informs all tabs to logout
                    const redirectUrl = await this._sessionHubConnector.SessionExpired();
                    if (redirectUrl)
                        this.loggedOut(redirectUrl, true);
                }
                catch (e) {
                    if (e instanceof Error)
                        Log.logInfo(`Session expired but couldn't contact backend: ${e.message}`);
                    else
                        Log.logError(e);

                    // in case connection is broken, we manually redirect to login page
                    this.loggedOut(PageHelper.sessionExpiredPage, true);
                }

            }, milliseconds);

        }

    }

    private clearTimers() {
        if (this._modalTimer)
            clearTimeout(this._modalTimer);

        if (this._logoutTimer)
            clearTimeout(this._logoutTimer);
    }

    private getMilliseconds(expiryDate: Date): number {

        const now = new Date();

        return expiryDate.getTime() - now.getTime();
    }

    private showSessionEndsModal(expiryDate: Date) {

        this._sessionEndsModal = new Modal(ModalType.Single, "SessionEndsModal");

        const text1 = Locale.getTranslation("session expires text 1");
        const text2 = Locale.getTranslation("session expires text 2")
        const defaultText = text1 + this.MODAL_TIMEOUT + ":00" + text2;

        this._sessionEndsModal.openModalDialog(
            Locale.getTranslation("session expires header"),
            defaultText,
            DialogType.CloseOnly,
            {
                overrideCloseCallback: async () => {

                    if (!document.hidden) // only the leading tab will extend the session, otherwise we have multiple session updates
                        await this._sessionHubConnector.ExtendSession();
                },
                overrideCloseButtonText: Locale.getTranslation("continue")
            }
        );

        const modalBody = this._sessionEndsModal.getModalDialogContainer().find(".modal-body");
        
        const interval = setInterval(() => {
            const t = this.getMilliseconds(expiryDate);
            const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((t % (1000 * 60)) / 1000); 
            const secondsFormatted = ("0" + seconds).slice(-2);
            modalBody.text(text1 + minutes + ":" + secondsFormatted + text2);

            if (minutes <= 0 && seconds <= 0)
                clearInterval(interval);

        }, 1000);


    }


}
import { HttpClient } from './httpClient';
import { Util } from '../../helper/util';

export class TokenUtil {

    public static hasAccessToken(): boolean {
                
        const token = this.getAccessToken();

        return !Util.stringIsNullOrEmpty(token);

    }

    public static getAccessToken(): string {

        const parameters = HttpClient.getUrlParameters();
        return parameters.get("access_token");

    }


}
import { DetailForm } from "../../../uiFramework/core/forms/detailForm";
import { Modules } from "../../../uiFramework/enum/allEnums";
import { PageHelper } from "../../../uiFramework/helper/pageHelper";
import { Calculation } from "../calculation";

export class CalculationHelper {

    public InitiateCalculateWorkUnitsWithForms() {

        // Try to get "Project: New Step 1"-Form
        let form = PageHelper.getPage().getForm("a146b36b-6c9d-44b2-a8bd-914f9029a56f");
        let module = Modules.Project;

        // If form wasnt found, try to get "Project-Details-Order"-Form
        if (form == null) {
            form = PageHelper.getPage().getForm("c84cc517-ee43-41d1-8e6f-c9d0362c5c92");
        }

        if (form == null) {
            // If form wasnt found, try to get "Award-New"-Form
            form = PageHelper.getPage().getForm("ab18c334-1e6b-46fa-b12a-7a4ff0ac1e9f");
            module = Modules.Award;
        }

        if (form == null) {
            // If form wasnt found, try to get "Award-Edit"-Form
            form = PageHelper.getPage().getForm("797e29e5-df5e-445f-9540-428755a9aff1");
            module = Modules.Award;
        }

        if (form == null) {
            // If form still wasnt found, try to get "Award-CR-New-2"-Form
            form = PageHelper.getPage().getForm("ae30c52e-da8d-4538-b2f0-ad9c8a36e0cf");
            module = Modules.ChangeRequest;
        }

        if (form == null) {
            return;
        }

        const calculation = new Calculation(module);
        calculation.openWorkUnitCalculateModal(form as DetailForm);

    }

}

import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";
import { Log } from '../logging/logging';
import { TokenUtil } from '../httpClient/tokenUtil';

export class HubConnector {

    protected _connection: HubConnection;


    protected async connectHub(hubName: string) {

        try {

            this._connection = new HubConnectionBuilder()
                .withUrl(hubName, this.getSignalRConfig())
                .withAutomaticReconnect()
                .build();
                    
            await this._connection.start();

        } catch (e) {

            if (e instanceof Error) {
                const message = `Starting connection failed: ${e.message}`;
                if (e.message === "Not Found") {
                    Log.logInfo(message);
                } else {
                    Log.logError(message);
                }
            } else {
                Log.logError(e);
            }
        }
    }

    private getSignalRConfig(): signalR.IHttpConnectionOptions {

        if (TokenUtil.hasAccessToken()) {
            return {
                accessTokenFactory: () => TokenUtil.getAccessToken()
            };
        }
        else {
            return {};
        }



    }
}
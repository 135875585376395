import { HttpClient } from "component/httpClient/httpClient";
import { Locale } from "component/localeManager/localeManager";
import { Log } from "component/logging/logging";
import { LogLevels } from "component/logging/logLevels";
import { DataTablesColumnType, PageSelectPosition } from "enum/allEnums";
import { Util } from "helper/util";
import deJson from "../../i18n/datatables/datatables-de.json";
import enJson from "../../i18n/datatables/datatables-en.json";

export class DataTableWrapper {

    private readonly tableNode: JQuery<HTMLTableElement>;
    private dataTable: DataTables.Api;

    constructor(tableNode: JQuery<HTMLTableElement>) {
        this.tableNode = tableNode;
    }

    public getTableNode(): JQuery<HTMLTableElement> {
        return this.tableNode;
    }

    public getRowData(rowIndex: number): Dictionary<GridContentData | Object> {

        if (!this.dataTable) { return null; }

        return this.dataTable.row(rowIndex).data() as Dictionary<GridContentData | Object>;
    }

    public getCellData(rowIndex: number, columnName: string): GridContentData | Object {

        if (!this.dataTable) { return null; }

        const rowData = this.getRowData(rowIndex);
        return rowData[columnName];
    }

    public setCellDisplayValue(rowIndex: number, columnName: string, newValue: string): void {

        if (!this.dataTable) { return; }

        const cell = this.dataTable.cell(rowIndex, `${columnName}:name`);
        const cellContent = cell.data() as GridContentData;

        cellContent.display = newValue;
        cell.invalidate(); // rerender cell

    }

    public get rowCount(): number {

        if (!this.dataTable) {
            return 0;
        }

        return this.dataTable.rows().count();
    }

    public get columnCount(): number {

        if (!this.dataTable) {
            return 0;
        }

        return this.dataTable.columns().count();
    }

    public * getRows(applySearchFilter = false): IterableIterator<GridRow> {

        if (!this.dataTable) {
            return;
        }

        let options;
        if (applySearchFilter) {
            options = { search: "applied" };
        }

        const rowIndexes = this.dataTable.rows(options).indexes();
        const count = rowIndexes.length;

        for (let i = 0; i < count; i++) {
            const rowIndex = rowIndexes[i];
            const row = this.dataTable.row(rowIndex);
            const data = row.data() as Dictionary<GridContentData | Object>;
            yield { index: rowIndex, node: $(row.node() as HTMLElement), data };
        }

    }

    public selectRow(rowIndex: number): void {

        if (!this.dataTable) {
            return;
        }

        this.dataTable.row(rowIndex).select();
    }

    /**
     *  Add Callback when + button for responsive row is clicked
     * @param callback
     */
    public addResponsiveCallback(callback: (index: number, childRowNode: JQuery, childRowIsVisible: boolean) => void): void {

        this.dataTable.on("responsive-display", function (event: Event, datatable: DataTables.Api, row: DataTables.RowMethods, showHide: boolean, update: boolean) {
            callback(row.index(), row.child(), showHide);
        });
    }

    public initializeGrid(): Promise<void> {

        return new Promise((resolve) => {

            // Check if we already initialized this table (e.g. if we reopen a modal)
            if (!$.fn.dataTable.isDataTable(this.tableNode)) {

                const groupingOptions = this.getGroupingOptions();
                const langJson = this.getLanguageJsonAndSetLanguage();
                const self = this;
                this.tableNode.DataTable({
                    paging: false,
                    lengthChange: false,
                    pagingType: "full_numbers",
                    dom: this.getSDOM(),
                    searching: false,
                    info: false,
                    ordering: false,
                    language: langJson,
                    autoWidth: false, // disable fixed width and enable fluid table
                    data: this.getContentDataJSON(),
                    columns: this.getHeaderDataJSON(),
                    orderFixed: groupingOptions.fixedOrdering,
                    rowGroup: groupingOptions.rowGroup,
                    fixedHeader: this.getFixedHeaderOptions(),
                    initComplete(): void {
                        self.dataTable = this.api();
                        resolve();
                    },
                    footerCallback(): void {
                        self.footerCallback(this.api(), self.tableNode);
                    },
                });

                window.addEventListener("load", function () {
                    // workaround for responsive width bug
                    self.tableNode.DataTable().columns.adjust();
                });

                this.initializeGridControls();
            } else {
                resolve();
            }
        });
    }

    public getSearchValue(): string {

        const filter = $(".dataTables_filter input", this.tableNode.closest("div.dataTables_wrapper")).val();

        return filter != null ? filter.toString() : "";

    }

    public setSearchValue(filter: string): void {

        if (!filter) { return; }

        this.dataTable.search(filter).draw();
    }

    /// Configuration Helper Functions

    private getHeaderDataJSON(): DataTables.ColumnSettings[] {
        const headerData = this.tableNode.attr("data-header");
        // replace the render enum value with the function (some kind of ugliness here)
        let headerDataJson: DataTables.ColumnSettings[] = null;
        if (headerData) {
            headerDataJson = JSON.parse(headerData);
        }

        if (!headerDataJson)
            return null;

        for (const columnEntry of headerDataJson) {

            if (columnEntry.render) {

                const renderer = columnEntry.render as DataTablesColumnType;

                switch (renderer) {

                    case DataTablesColumnType.Checkbox:
                        columnEntry.render = this.renderCheckbox;
                        break;

                    case DataTablesColumnType.Link:
                        columnEntry.render = this.renderLink;
                        break;

                    case DataTablesColumnType.Date:
                        columnEntry.render = this.renderDate;
                        break;

                    case DataTablesColumnType.Image:
                        columnEntry.render = this.renderImage;
                        break;

                    default:
                        columnEntry.render = this.renderText;
                        break;
                }

            }

        }

        return headerDataJson;
    }

    private getContentDataJSON(): any {
        const contentData = this.tableNode.attr("data-content");

        let contentDataJson = null;
        if (contentData) {
            contentDataJson = JSON.parse(contentData);
        }

        return contentDataJson;
    }

    private getSDOM(): string {

        let paginationTop = this.getLengthChangeColumn();

        // Paging-bars-position
        const pageSelectPosition = parseInt(this.tableNode.data("page-select-position")) as PageSelectPosition;

        // top pagebar
        if (pageSelectPosition === PageSelectPosition.Top || pageSelectPosition === PageSelectPosition.Both) {
            paginationTop += "<'col-sm-8'p>";
        }

        // bottom pagebar
        let paginationBottom = "";
        if (pageSelectPosition === PageSelectPosition.Bottom || pageSelectPosition === PageSelectPosition.Both) {
            paginationBottom = "<'row'<'col-sm-12'p>>";
        }

        return `<'row'<'col-sm-12'f>>
                <'row'${paginationTop}>
                <'row'<'col-sm-12'tr>>
                <'row'<'col-sm-12'i>>${paginationBottom}`;
    }

    private getLengthChangeColumn(): string {
        // Page-length-dropdown (entries per page)
        let changeLengthColumn;
        const enableLenghtChange = this.tableNode.data("length-change");
        if (enableLenghtChange) {
            changeLengthColumn = "<'col-sm-4'l>";
        } else {
            changeLengthColumn = "<'col-sm-4'>";
        } // empty dummy

        return changeLengthColumn;
    }

    private getFixedHeaderEnabled(): boolean {
        // Enable fixedHeader DataTables Plugin
        const fixedHeaderEnabled = this.tableNode.attr("data-fixed-header-enabled");
        let fixedHeaderEnabledValue = false;
        if (fixedHeaderEnabled === "true") {
            fixedHeaderEnabledValue = true;
        }

        return fixedHeaderEnabledValue;
    }

    private getFixedHeaderOptions(): DataTables.FixedHeaderSettings | boolean {

        const isFixedHeader = this.getFixedHeaderEnabled();

        if (isFixedHeader) {

            let fixedHeaderOffset = 0;
            if (Util.getViewPort().width < Util.getResponsiveBreakpoint("md")) {
                if ($(".page-header").hasClass("page-header-fixed-mobile")) {
                    fixedHeaderOffset = $(".page-header").outerHeight(true);
                }
            } else if ($(".page-header").hasClass("navbar-fixed-top")) {
                fixedHeaderOffset = $(".page-header").outerHeight(true);
            } else if ($("body").hasClass("page-header-fixed")) {
                fixedHeaderOffset = 64; // admin 5 fixed height
            }

            return {
                header: isFixedHeader,
                headerOffset: fixedHeaderOffset,
            };
        }

        return false;
    }

    private getGroupingOptions(): { rowGroup: DataTables.RowGroupSettings, fixedOrdering: Array<string | number> } {

        // Set Grid Grouping Options
        const rowGroupCol = this.tableNode.attr("data-row-group-col");
        let rowGroup: DataTables.RowGroupSettings = null;
        let fixedOrdering: Array<string | number> = null;
        if (rowGroupCol) {

            rowGroup = { dataSrc: "groupByFields" };

            // When we use row group we fix the ordering of the groups. That allow us to sort within every group.
            // Set number of column which is the group by column
            fixedOrdering = [parseInt(rowGroupCol), "asc"];

        }

        return { rowGroup, fixedOrdering };
    }

    private getLanguageJsonAndSetLanguage(): DataTables.LanguageSettings {

        const lang = Locale.getCurrentLanguage();

        let langJson = enJson;
        if (lang === "en-US") {

            // Set Date Formats for moment.js
            // $.fn.dataTable.moment('HH:mm MMM D, YY');
            // $.fn.dataTable.moment(Locale.getDateformat(), Locale.getCurrentLanguage());
        } else if (lang === "de-DE") {
            langJson = deJson;

            // Set Date Formats for moment.js
            // $.fn.dataTable.moment('HH:mm MMM D, YY');
            // $.fn.dataTable.moment(Locale.getDateformat(), Locale.getCurrentLanguage());
        }

        return langJson;
    }

    /// End Configuration Helper Functions

    /// Initialize Grid Control Functions

    private initializeGridControls(): void {

        this.initializeGridCheckboxes();

        this.applyRowNumbers();

    }

    private initializeGridCheckboxes(): void {

        // currently needed because somehow it doesnt work with fatarrow
        const self = this;

        this.tableNode.on("click", "input[type=checkbox]:not(.group-checkable)", function () {

            let cell: DataTables.CellMethods;
            const td = $(this).closest("td");

            // for responsive plugin - element is child tr, we need cell in parent tr
            if (td.hasClass("child")) {
                const li = $(this).closest("li");
                const rowIndex = parseInt(li.attr("data-dt-row"));
                const colIndex = parseInt(li.attr("data-dt-column"));
                cell = self.dataTable.cell(rowIndex, colIndex);
            } else {
                cell = self.dataTable.cell(td);
            }

            const setValue = function (value: number) {
                cell.data().display = value;
                cell.data().value[0] = value;
            };

            parseInt(cell.data().value[0]) === 1 ? setValue(0) : setValue(1);

            if (Log.getLogLevel() <= LogLevels.Debug) {
                console.info("new value: " + cell.data().display);
            }

        });

        this.bindCheckAllCheckbox();
    }

    private bindCheckAllCheckbox() {

        // currently needed because somehow it doesnt work with fatarrow
        const self = this;

        this.tableNode.on("click", "input[type=checkbox].group-checkable", function () {

            const checkAll = $(this).is(":checked");
            if ($(this).hasClass("group-checkable")) {

                const th = $(this).closest("th");
                const columnData = self.dataTable.column(th).data();

                // update internal data for editable fields
                columnData.each(function (data, i) {
                    if (!data.isReadonly) {
                        if (checkAll) {
                            data.display = 1;
                            data.value[0] = 1;
                        } else {
                            data.display = 0;
                            data.value[0] = 0;
                        }
                    }
                });

                // de/select all checkboxes in gui
                const nodes = self.dataTable.column(th).nodes();
                for (let i = 0; i < nodes.length; i++) {
                    if (checkAll) {
                        $(nodes[i]).find("input[type=checkbox]").prop("checked", true);
                    } else {
                        $(nodes[i]).find("input[type=checkbox]").prop("checked", false);
                    }
                }
            }
        });
    }

    private applyRowNumbers(): void {

        // Add Row Numbers dynamically
        const showRowNumber = this.tableNode.attr("data-row-number");

        if (showRowNumber === "true") {

            const rowNumberColumn = this.tableNode.attr("data-responsive") === undefined ? 0 : 1;

            this.dataTable.on("draw", () => {

                $(this.dataTable.column(rowNumberColumn, { search: "applied", order: "applied" }).nodes()).each((i, cell) => {
                    $(cell).text(i + 1);
                    $(cell).val(i + 1);
                });
            }).draw();
        }
    }

    private footerCallback(api: DataTables.Api, tableNode: JQuery): void {

        const totalColumnsAttribute = tableNode.attr("data-total-columns");
        if (totalColumnsAttribute === undefined) { return; }

        const totalColumnNumbers: Int16Array[] = JSON.parse(totalColumnsAttribute);

        const currencyId = tableNode.attr("data-currency");
        const isPagingEnabled = this.tableNode.attr("data-paging");
        for (const columnNumber of totalColumnNumbers) {

            const isCurrencyColumn = this.isCurrencyColumn(api, columnNumber);
            let overallTotal = this.getOverallTotal(api, columnNumber);
            let pageTotal = this.getPageTotal(isPagingEnabled, api, columnNumber);

            // round to 2 decimal places
            pageTotal = (Math.round(pageTotal * 100) / 100);
            overallTotal = (Math.round(overallTotal * 100) / 100);

            let pageTotalString, totalString;
            if (isCurrencyColumn) {
                pageTotalString = Locale.formatCurrency(pageTotal, parseInt(currencyId));
                totalString = Locale.formatCurrency(overallTotal, parseInt(currencyId));
            } else {
                pageTotalString = Locale.formatNumber(pageTotal).toString();
                totalString = Locale.formatNumber(overallTotal).toString();
            }

            // Update footer
            $(api.column(columnNumber).footer()).html(
                (isPagingEnabled === "true") ? pageTotalString + " (" + totalString + ")" : totalString,
            );

        }
    }

    private isCurrencyColumn(api: DataTables.Api, columnNumber: Int16Array): boolean {

        const header = api.columns(columnNumber).header();

        return $(header).hasClass("currency");

    }

    private getOverallTotal(api: DataTables.Api, columnNumber: Int16Array): number {

        // Total over all pages
        return api
            .column(columnNumber)
            .data()
            .reduce((a: any, b: any) => this.parseInt(a) + this.parseInt(b), 0);

    }

    private getPageTotal(isPagingEnabled: string, api: DataTables.Api, columnNumber: Int16Array): number {

        if (isPagingEnabled !== "true")
            return 0;

        // Total over this page
        return api
                .column(columnNumber, { page: "current" })
                .data()
                .reduce((a: any, b: any) => this.parseInt(a) + this.parseInt(b), 0);
        
    }

    private parseInt(originalValue: any): number {
        // Remove the formatting to get integer data for summation

        // Cell Value could be an object
        let valueToConvert;
        if ($.isPlainObject(originalValue)) {
            valueToConvert = originalValue.value[0];
        } else {
            valueToConvert = originalValue;
        }

        // when value is a string, we need to do several things
        if (typeof valueToConvert === "string") {

            if (Util.isTime(valueToConvert)) {
                const resultTime = parseFloat(Util.convertTimeToIndustryHours(valueToConvert));
                if (Util.isNumeric(resultTime)) {
                    return resultTime;
                } else {
                    return 0;
                }
            }
            else {
                const result = Locale.parseNumber(valueToConvert);
                if (Util.isNumeric(result)) {
                    return result;            
                } else {
                    return 0;
                }
            }


        } else if (typeof valueToConvert === "number") { // for number everything is fine
            return valueToConvert;
        } else { // otherwise not convertable
            return 0;
        }
    };

    /// End Initialize Grid Control Functions

    /// Column Render Functions

    private renderCheckbox(d: GridContentData, type: any, row: any, meta: DataTables.CellMetaSettings) {

        if (type !== "display") { // take value for sorting, filtering and type detection
            return d.value;
        }

        if (d.isReadonly) {
            return d.display;
        }

        // called 12 times for 2 rows, when repsonsive and autoWidth enabled
        let checked = "";
        if (d.value[0].toString() === "1") {
            checked = "checked";
        }

        return '<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">' +
            `<input data-display-name="Checkbox" type="checkbox" name="checkbox" class="form-control" ${checked}><span></span>` +
            "</label>";
    }

    private renderLink(d: any, type: any, row: any, meta: DataTables.CellMetaSettings) {

        if (type !== "display") { // take display text for sorting, filtering and type detection
            return d.display;
        }

        let url;
        if (typeof (d.value) === "object")
            url = d.value[0];
        else
            url = d.value;

        if (!url)
            return "";

        let target = "";
        let dataBindClick = "";
        if (d.openInNewWindow === true) {
            target = "target=\"_blank\"";
        } else {
            dataBindClick = "data-bind-click=\"redirect\"";
        }

        if (window.name) {
            url = HttpClient.addURLParameter(url, "pagestate", window.name);
        }

        // called 12 times for 2 rows, when repsonsive and autoWidth enabled
        return `<a href="${url}" ${dataBindClick} ${ target }> ${ d.display } </a>`;
    }

    private renderDate(d: any, type: any, row: any, meta: DataTables.CellMetaSettings) {

        if (type !== "display" && type !== "filter") { // take value for sorting, filtering and type detection
            return d.value;
        }

        // called 12 times for 2 rows, when repsonsive and autoWidth enabled
        return d.display;
    }

    private renderText(d: any, type: any, row: any, meta: DataTables.CellMetaSettings) {

        // called 12 times for 2 rows, when repsonsive and autoWidth enabled
        return d.display;
    }

    private renderImage(d: any, type: any, row: any, meta: DataTables.CellMetaSettings) {
        return (type === "sort")
            ? d.value // to sort images we need the value
            : d.display;
    }

    /// End Column Render Function

}

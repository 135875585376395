import { findIndex } from "lodash";
import { Util } from "uiFramework/helper/util";
import { ControlData } from "../controls/util/controlData";
import { Mode } from "./detailFormWithDataGrid";
import { GridForm } from "./gridForm";

export class FormDataWrapper {

    private gridForm: GridForm;
    private mode: Mode;
    private activeRow: number;
    private formData: FormData[];
    private insertRowMode: boolean;
    private dirtyData: boolean;

    constructor(gridForm: GridForm, mode: Mode) {

        this.gridForm = gridForm;
        this.mode = mode;
        this.initFormData();
        this.setInitialActiveRow();
        this.setInitialInsertRowMode();
        this.dirtyData = false;
    }

    public getActiveRow(): number {
        return this.activeRow;
    }

    public setActiveRow(which: number | "add" | "next" | "previous"): void {

        if (which === "next" || which === "previous") {

            this.setActiveRowByNavigation(which);
            this.insertRowMode = false;

        } else if (which === "add") {
            this.setNewRow();
        } else { // number = local index, not grid index
            this.gridForm.setActiveRow(this.getGridIndexByLocalIndex(which));
            this.activeRow = which;
            this.insertRowMode = false;
        }

    }

    public insertOrUpateFormControlData(controls: Dictionary<ControlDataInterface[]>): void {

        this.dirtyData = true;
        if (this.insertRowMode) {
            this.insertFormControlData(controls);
        } else {
            this.updateFormControlData(controls);
        }

    }

    public getFormControlData(which: number | "current", onlyChangedData?: boolean): Dictionary<ControlDataInterface[]> {

        const entry = (which === "current") ? this.formData[this.activeRow] : this.formData[which];
        if (entry == null) { return null; }

        if (entry.controls == null && onlyChangedData) {
            return null;
        }

        if (entry.controls != null) {
            return entry.controls;
        }

        return this.getFormControlDataFromGrid(entry.gridIndex);

    }

    public rowContainsChangedData(): boolean {

        const data = this.formData[this.activeRow];
        return data != null && data.controls != null;

    }

    // rows of grid + newly added data
    public rowCount(): number {
        return this.formData.length;
    }

    public containsDirtyData(): boolean {
        return this.dirtyData;
    }

    public rowExist(which: "next" | "previous"): boolean {

        if (which === "next") {
            return this.activeRow + 1 < this.rowCount();
        } else {
            if (this.activeRow > 0) {
                return true;
            } else if (this.insertRowMode && this.mode === "edit") {
                // there is always a prev row, when add button was triggered before
                // for addOnly mode there is no previous page at the beginning
                return true;
 }
            return false;
        }

    }

    private initFormData(): void {

        this.formData = new Array<FormData>();

        if (this.mode === "add") { return; }

        this.initializeGridMapping();
    }

    private initializeGridMapping() {

        // only use rows of current search
        const rows = this.gridForm.getRows(true);
        for (const rowObject of Array.from(rows)) {
            const tempFormData: FormData = { gridIndex: rowObject.index };
            this.formData.push(tempFormData);
        }
    }

    private setInitialInsertRowMode(): void {

        if (this.mode === "add") {
            this.insertRowMode = true;
        } else {
            this.insertRowMode = false;
        }

    }

    private setInitialActiveRow(): void {

        if (this.mode === "add") {
            this.activeRow = 0;
        } else {
            const localIndex = this.getLocalIndexByGridIndex(this.gridForm.getActiveRowIndex());
            this.activeRow = localIndex;
        }
    }

    private getLocalIndexByGridIndex(gridIndex: number): number {

        const index = findIndex(this.formData, (d) => d.gridIndex === gridIndex);

        return (index === -1) ? null : index;

    }

    private getGridIndexByLocalIndex(localIndex: number): number | null {
        return this.formData[localIndex].gridIndex;
    }

    private setNewRow() {

        if (!this.insertRowMode) {

            this.activeRow++;
            this.gridForm.setActiveRow(null);
            this.insertRowMode = true;

        }
    }

    private setActiveRowByNavigation(which: "next" | "previous") {

        if (this.checkMovePointer(which)) {
            (which === "next") ? this.activeRow++ : this.activeRow--;
        }

        // map FormDataRow to gridRow
        const gridIndex = this.formData[this.activeRow].gridIndex;
        if (gridIndex != null) {
            this.gridForm.setActiveRow(gridIndex);
        }
    }

    private checkMovePointer(which: "next" | "previous") {

        if (this.insertRowMode && which === "next") { return false; }

        return true;
    }

    private insertFormControlData(controls: Dictionary<ControlDataInterface[]>): void {

        const newFormData: FormData = { controls };
        this.formData.splice(this.activeRow, 0, newFormData);

        this.insertRowMode = false;
    }

    private updateFormControlData(controls: Dictionary<ControlDataInterface[]>): void {

        const formDataEntry = this.formData[this.activeRow];
        formDataEntry.controls = controls;

    }

    private getFormControlDataFromGrid(index: number): Dictionary<ControlDataInterface[]> {

        const formDataEntry: Dictionary<ControlDataInterface[]> = {};

        const visibleFields = this.gridForm.getVisibleFields(index);
        for (const fieldName in visibleFields) {
            const field = visibleFields[fieldName];
            const displayValues = field.display.split(",<br>");
            formDataEntry[fieldName] = field.value.map((v, i) => new ControlData(v.toString(), Util.htmlDecode(displayValues[i])));

        }

        const hiddenFields = this.gridForm.getHiddenFields(index);
        for (const key in hiddenFields) {

            const field = hiddenFields[key];
            formDataEntry[key] = field.map((v) => new ControlData(v.toString()));
        }

        return formDataEntry;
    }

}

interface FormData {

    gridIndex?: number;
    controls?: Dictionary<ControlDataInterface[]>;

}

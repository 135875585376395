import { ButtonBuilder } from "buttons/buttonBuilder";
import { FormValidation } from "component/formValidation/formValidation";
import { HttpClient } from "component/httpClient/httpClient";
import { Log } from "component/logging/logging";
import { ControlData } from "controls/util/controlData";
import { FormAction, FormStyles } from "enum/allEnums";
import { ControlFactory } from "factory/controlFactory";
import { Form } from "forms/form";
import { ControlHelper } from "helper/controlHelper";
import { Util } from "helper/util";

export class DetailForm extends Form {

    public get formStyle(): FormStyles {
        return FormStyles.Detail;
    }

    protected controls: ControlInterface[];
    protected validationInstance: FormValidation;

    constructor(dispatcher: DispatcherInterface, portletBody: JQuery, formCallbacks?: FormCallback | FormCallback[]) {

        super(dispatcher, portletBody, formCallbacks);

        this.controls = new Array<ControlInterface>();

    }

    public async initializeForm(removeOldControls: boolean = false): Promise<void> {

        if (removeOldControls) {
            this.controls = new Array<ControlInterface>();
        }

        this.initializeDetailForm();

        super.setFormInitialized();
    }

    public isValid(): boolean {
        return this.validationInstance.validate();
    }

    public getAllControls(): ControlInterface[] {
        return this.controls;
    }

    public getTransferData(): FormTransferInterface {

        const transfer = this.createTransferData();

        for (const control of this.controls) {

            const data = this.gatherInputData(control);

            transfer.addField(0, control.fieldName, data);

        }

        return transfer;
    }

    public getControl(controlName: string): ControlInterface {

        const control = this.controls.filter((ci: ControlInterface) => {
            return ci.fieldName === controlName;
        });

        if (control) {
            return control[0];
        } else {
            return undefined;
        }
    }

    public getControls(...controlNames: string[]): Array<ControlInterface> {
        let result = new Array<ControlInterface>();

        controlNames.forEach(controlName => {
            let control = this.getControl(controlName);
            if (control) {
                result.push(control);
            }
        });

        return result;
    }

    public getControlByDataAttribute(attributeName: string): ControlInterface {

        const controls = this.controls.filter((c) => c.getDataAttribute("data-" + attributeName) != null);

        if (controls) {
            return controls[0];
        } else {
            return undefined;
        }
    }

    public resetControls(): void {
        for (const control of this.controls) {
            control.clear();
        }
    }

    public dispatch(button: ButtonInterface): Promise<void> {

        const actionType = button.formAction;
        if (actionType === FormAction.Close) {
            return Promise.resolve();
        } // Modal Close Action: Modal will close automatically, because of data-dismiss attribute on button
        if (actionType === FormAction.Resetform) {
            return this.triggerResetFormButton();            
        } else {
            return super.dispatch(button);
        }
    }

    public getCurrencyId(): number {

        const currencyControl = this.getControl("currency_id");
        if (!currencyControl) { return undefined; }

        const values = currencyControl.getValues();
        if (values.length === 1
            && Util.isNumeric(Number(values[0].value))) {
            return parseInt(values[0].value);
        } else {
            return undefined;
        }
    }

    public createButton(buttonText: string, toolTipText?: string, clickFunction?: Function): void {

        const formContainer = super.getFormContainer();

        const isModal = this.getModal() != null;
        const anchorButton = ButtonBuilder.createDefaultAnchorButton(buttonText, isModal, toolTipText, clickFunction);
        const buttonGroup = formContainer.get(0).querySelector("div.btn-group");

        buttonGroup.appendChild(anchorButton);
    }

    public fillControlsFromTransferData(transferData: DataRowTransferInterface): void {

        for (const control of this.controls) {

            const values = transferData[control.fieldName];
            if (values == null) {
                continue;
            }

            control.setValues(values.map((v) => new ControlData(v.toString())));

        }
    }

    protected initializeDetailForm(): void {
        ControlHelper.applyTextEditor(this.portletBody);
        ControlHelper.applySelect2(this.portletBody);
        ControlHelper.applySelect2Multiple(this.portletBody);
        ControlHelper.applyDateTimePicker(this.portletBody);
        ControlHelper.applyTouchspin(this.portletBody);
        ControlHelper.disableMouseScrollOnNumberInputs(this.portletBody);
        ControlHelper.applyBootstrapSwitch(this.portletBody);
        ControlHelper.applyShorten(this.portletBody);
        ControlHelper.applyFilestyle(this.portletBody);

        this.validationInstance = new FormValidation(this);

        super.initializeButtons();
        this.initializeControls();
    }

    protected resetValidation(): void {
        this.validationInstance.reset();
    }

    // Key = Field Name; Value = ControlData[] in case of multiple value control
    protected getControlDataDictionary(): Dictionary<ControlDataInterface[]> {

        const formControls: Dictionary<ControlDataInterface[]> = {};

        for (const control of this.controls) {
            const controlData = control.getValues();
            formControls[control.fieldName] = controlData;
        }

        return formControls;
    }

    protected gatherInputData(control: ControlInterface): string[] {

        let data = control.getValues().map(function(controlData: ControlData) {
            return controlData.value;
        });

        if (data.length === 0) {
            data = [""];
        }

        return data;
    }

    private initializeControls(): void {

        $("div.fieldcontainer", this.portletBody).each((i: number, element: HTMLElement) => {

            const fieldContainer = $(element);

            const control = ControlFactory.instantiateControlByType(fieldContainer, this);
            if (control) {
                this.controls.push(control);
            }
        });

        // start initialization after creating all controls, because some controls depend on others (e.g. parent source child)
        this.controls.forEach((c) => c.initialize());

    }

    private triggerResetFormButton() : Promise<void> {

        // prevent standard html reset event
        event.preventDefault();
        $.blockUI();
        try {

            // Reset all form elements
            this.resetControls();

            // remove saved search from session
            const url = "Form/ResetSearchData";
            const params = new URLSearchParams();
            params.append("formId", this.formId);

            return HttpClient
                .httpDelete(url, params)
                .then(() => {
                    $.unblockUI();
                }).catch((error) => {
                    Log.logError(error);
                    $.unblockUI();
                });

        } catch (e) {
            // if something went wrong, make sure ui is not blocked
            $.unblockUI();
            return Promise.resolve();
        }

    }

}

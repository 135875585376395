import { Log } from "component/logging/logging";

export class ClientPageExtensionHelper {

    public static getInstance(): ClientPageExtensionHelper {

        if (!ClientPageExtensionHelper.instance) {
            new ClientPageExtensionHelper();
        }

        return ClientPageExtensionHelper.instance;
    }

    public static registerExtension(extension: ClientPageExtension): void {

         this.getInstance().extensions.push(extension);

         if (this.getInstance().run) {
            extension.start();
         }
    }

    public static resetExtensions(): void {

        this.getInstance().run = false;

    }

    public static triggerStart(): void {

        if (!this.getInstance().run) {

            this.getInstance().extensions.forEach((e) => e.start());

            this.getInstance().run = true;
        } else {
            Log.logDebug("ClientPageExtension Helper: Extensions already running!");
        }
    }

    private static instance: ClientPageExtensionHelper;
    private extensions: ClientPageExtension[];
    private run: boolean;

    constructor() {

        if (ClientPageExtensionHelper.instance) {
            throw new Error("Error: Instantiation failed: Use ClientPageExtensionHelper.getInstance() instead of new.");
        }

        ClientPageExtensionHelper.instance = this;
        this.init();
    }

    private init(): void {

        this.extensions = new Array<ClientPageExtension>();
        this.run = false;

    }

}

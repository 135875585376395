import { wireTooltips } from "component/tooltipWrapper/tooltipWrapper";
import { FormAction, ModalType } from "enum/allEnums";
import { FormFactory } from "factory/formFactory";
import { ClientPageExtensionHelper } from "helper/clientPageExtensionHelper";
import { Util } from '../../helper/util';
import { KeyCodes } from '../../enum/keyCodes';

export class Page implements PageInterface {

    private readonly forms: FormInterface[];

    constructor() {
        this.forms = [];
    }

    public async initialize(): Promise<void> {

        await this.initializeForms();

        wireTooltips();

        ClientPageExtensionHelper.triggerStart();

        this.onKeyDownNavigationEvents();
    }

    /**
     *  Add or Replace Form when already exist on Page object
     * @param form
     */
    public addOrReplaceForm(form: FormInterface) {

        const existingForm = this.getForm(form.formId);
        if (existingForm == null) {
            this.forms.push(form);
        } else {

            for (let i = 0; i < this.forms.length; i++) {

                if (this.forms[i].formId === form.formId) {
                    this.forms.splice(i, 1, form);
                    return;
                }

            }
        }
    }

    public getForm(formId: string): FormInterface {
        const forms = this.forms.filter((f) => f.formId === formId);

        if (forms.length > 0) {
            return forms[0];
        } else {
            return null;
        }
    }

    public getForms(): FormInterface[] {
        return this.forms;
    }

    public getFormsByFormClass(formclass: string): FormInterface[] {
        return this.forms.filter((f) => f.formClass === formclass);
    }

    private async initializeForms(): Promise<void[]> {

        const promises = [];
        const portlets = $("div.portlet-body");
        for (let i = 0; i < portlets.length; i++) {
            const context = $(portlets.get(i));

            const form = FormFactory.instantiateForm(context);
            if (form) {
                this.addOrReplaceForm(form);
                promises.push(form.initializeForm());
            }
        }

        return Promise.all(promises);

    }

    private getActiveModalRecursion(index: number, depth: number, form: FormInterface): ModalInterface {

        const formModalChild: ModalInterface = form.getChildModal();
        const formModal: ModalInterface = form.getModal();

        if ((formModalChild != null) && (formModalChild.form != null)) {
            return this.getActiveModalRecursion(index, depth + 1, formModalChild.form);
        } else if (formModalChild != null) {
            return formModalChild;
        } else if (formModal != null) {
            return formModal;
        } else {
            return null;
        }

    }

    private getActiveModal(): ModalInterface {

        let modalActive: ModalInterface;

        const forms: FormInterface[] = this.getForms();
        let index = 0;
        for (const form of forms) {
            index++;
            const modal: ModalInterface = this.getActiveModalRecursion(index, 1, form);
            if ((modal != null) && (modal.isActiveModal)) {
                modalActive = modal;
            }
        }

        return modalActive;

    }

    private keyDownEventHandlerCloseModal(form: FormInterface, keyCode: number): boolean {

        const KeyCodeExit = KeyCodes.Escape; 
        if (keyCode === KeyCodeExit) {
            const button: JQuery = form.getFormContainer().find("button.close");
            if ((button != null) && (button.length > 0)) {
                button[0].click();
                return true;
            }
        }
        return false;

    }

    private getActiveModalNoFormButtons(): Array<KeyValuePair<number, string>> {

        return [
            { key: KeyCodes.Escape, value: "button.close" },
        ];

    }

    // special case not handled by ui framework => discussed with DRE
    private keyDownEventHandlerModalNoForm(modal: ModalInterface, keyCode: number): void {

        const container = modal.getModalDialogContainer();
        if ((container == null) || (container.length < 1)) {
            return;
        }

        const keyCommands: Array<KeyValuePair<number, string>> = this.getActiveModalNoFormButtons();
        for (const keyPress of keyCommands) {
            if (keyCode === keyPress.key) {
                const button = container.find(keyPress.value);
                if ((button != null) && (button.length > 0)) {
                    button[0].click();
                    return;
                }
            }
        }

    }

    private getActiveModalDataTableNavButtons(): Array<KeyValuePair<number, string>> {

        return [
            { key: KeyCodes.PageUp, value: "li.paginate_button.previous a" },
            { key: KeyCodes.PageDown, value: "li.paginate_button.next a" },
        ];

    }

    private keyDownEventHandlerModalDataTable(form: FormInterface, keyCode: number): void {

        if (form.getFormContainer().find("div.dataTables_paginate").length > 0) {
            const keyCommands: Array<KeyValuePair<number, string>> = this.getActiveModalDataTableNavButtons();
            for (const keyPress of keyCommands) {
                if (keyCode === keyPress.key) {
                    const button = form.getFormContainer().find(keyPress.value);
                    if ((button != null) && (button.length > 0)) {
                        button[0].click();
                        return;
                    }
                }
            }
        }

    }

    private getActiveModalUIButtons(form: FormInterface): Array<KeyValuePair<number, ButtonInterface[]>> {

        const keyCommands: Array<KeyValuePair<number, ButtonInterface[]>> = [
            { key: KeyCodes.Enter, value: form.getButtonsByActionType(FormAction.Search) },
            { key: KeyCodes.PageUp, value: form.getButtonsByActionType(FormAction.Prevrow) },
            { key: KeyCodes.PageDown, value: form.getButtonsByActionType(FormAction.Nextrow) },
        ];

        return keyCommands.filter((item) => (item.value.length > 0));
    }

    private keyDownEventHandlerModalUI(form: FormInterface, keyCode: number): void {

        const keyCommands: Array<KeyValuePair<number, ButtonInterface[]>> = this.getActiveModalUIButtons(form);
        for (const keyPress of keyCommands) {
            if (keyCode === keyPress.key) {
                if (keyPress.value.length > 0) {
                    keyPress.value[0].click();
                }
            }
        }

    }

    private keyDownEventHandler(event: JQuery.Event): void {

        const modal: ModalInterface = this.getActiveModal();
        if (modal == null) {
            return;
        }

        const keyCode = Util.getKeyCode(event);
        if ([KeyCodes.PageUp, KeyCodes.PageDown].indexOf((keyCode)) !== -1) {
            event.preventDefault();
        }

        this.handleSpecialFormBehavior(event, keyCode);

        const form: FormInterface = modal.form;
        if (form == null) {
            this.keyDownEventHandlerModalNoForm(modal, keyCode);
            return;
        }

        if (this.keyDownEventHandlerCloseModal(form, keyCode)) {
            return;
        }

        this.keyDownEventHandlerModalDataTable(form, keyCode);

        this.keyDownEventHandlerModalUI(form, keyCode);        

    }

    private handleSpecialFormBehavior(event: JQuery.Event, keyCode: number) {

        // this is weird stuff. If browser finds only one input field in form, it fires submit event when hitting enter key. That we want to prevent
        if (keyCode === KeyCodes.Enter && document.activeElement.nodeName === "INPUT")
            event.preventDefault();

    }

    private onKeyDownNavigationEvents(): void {

        const doc = $(document);
        if ((doc == null) || (doc.length !== 1)) {
            return;
        }

        doc.on("keydown.keyNavigation", this.keyDownEventHandler.bind(this));

    }

}

import { Form } from "forms/form";
import { ControlHelper } from "helper/controlHelper";
import { FormStyles } from "uiFramework/enum/allEnums";

export class GraphicalForm extends Form {

    constructor(dispatcher: DispatcherInterface, portletBody: JQuery, formCallbacks?: FormCallback | FormCallback[]) {

        super(dispatcher, portletBody, formCallbacks);
    }

    public get formStyle(): FormStyles {
        return FormStyles.Graphical;
    }

    public isValid(): boolean {
        return true;
    }

    public async initializeForm(): Promise<void> {

        ControlHelper.applyChartJs();

        super.initializeButtons();

        super.setFormInitialized();
    }

}

import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { setupCache } from "axios-cache-adapter";
import { Util } from "helper/util";
import * as localforage from "localforage";
import { HttpClientError } from "../../helper/httpClientError";
import { TokenUtil } from './tokenUtil';

export enum CacheLifeCycle {
    Short  =  1 * 60 * 1000,
    Medium =  5 * 60 * 1000,
    Long   = 10 * 60 * 1000,
}

export class HttpClient {


    public static readonly WebApiActionsPrefix = "api/actions/";

    public static setup() {

        if (TokenUtil.hasAccessToken()) {
            this.setAuthorizationToken(TokenUtil.getAccessToken());
        }
        
    }

    /**
     * convert a string url to URL Object
     * @param url
     */
    public static convertToUrl(url: string): URL {

        const baseUrl = this.getBaseUrl();
        return url.startsWith(baseUrl) ? new URL(url) : new URL(baseUrl + url);

    }

    /** Get the base url - e.g. http://localhost/development/ */
    public static getBaseUrl(): string {

        let baseUrl = $("base").attr("href");

        if (!baseUrl.endsWith("/"))
            baseUrl = `${baseUrl}/`;

        return baseUrl;

    }

    /**
     * Returns the page name e.g. inbox.app
     * @param url optional: use current url or specify a url to get the page name
     */
    public static getPageName(url?: string): string {

        let urlObject;
        if (!url) {
            urlObject = window.location;
        } else {
            urlObject = HttpClient.convertToUrl(url);
        }

        return urlObject.pathname.split("/").slice(-1)[0];
    }

    /**
     * Returns the url parameters
     * @param url optional: use current url or specify a url to get the params
     */
    public static getUrlParameters(url?: string): URLSearchParams {

        let urlObject;
        if (!url) {
            urlObject = window.location;
        } else {
            urlObject = HttpClient.convertToUrl(url);
        }

        return new URLSearchParams(urlObject.search);
    }

    /**
     * // simulates an http redirect and keeps the browser history updated
     * @param url : redirect target URL
     */
    public static browserRedirect(url?: string, ignorePagestate = false) {

        if (url) {
            if (window.name && !ignorePagestate) {
                if (url.startsWith('/'))
                    url = url.substring(1);

                url = HttpClient.addURLParameter(url, "pagestate", window.name);
            }

            if (!url.includes("/api/actions/document/download/") && !url.includes("/s/d/")) {
                $.blockUI();
            }

            window.location.href = url;
        }
    }
    /**
    * Converts url string to urlObject and update or add the name value to the url parameter.
    * @param url
    * @param name
    * @param value
    */
    public static addURLParameter(url: string, name: string, value: string): string {

        let urlObject;
        if (url && name && value) {
            urlObject = this.convertToUrl(url);
            urlObject.searchParams.set(name.toLowerCase(), value);

            return urlObject.toString();
        } else {
            return url;
        }
    }

    /**
     * Trigger HTTP Post Command
     * @param url
     * @param data
     * @param params
     * @param options Http Client Options
     */
    public static httpPost<T>(url: string, data?: any, params?: URLSearchParams, options?: HttpClientOptions): Promise<T> {

        const { newUrl, config } = this.buildAxiosConfig(options, url, params);

        return axios
            .post<T>(newUrl, data, config)
            .then((response) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if (error.response != null && error.response.status === 401) {
                    Util.redirectToLoginPage();
                }
                throw HttpClient.convertError(error);
            });

    }

    /**
     * Trigger HTTP Get Command
     * @param url
     * @param params
     * @param options Http Client Options
     */
    public static httpGet<T>(url: string, params?: URLSearchParams, options?: HttpClientOptions): Promise<T> {

        const { newUrl, config } = this.buildAxiosConfig(options, url, params);

        return axios
            .get<T>(newUrl, config)
            .then((repsonse) => {
                return repsonse.data;
            })
            .catch((error: AxiosError) => {
                if (error.response != null && error.response.status === 401) {
                    Util.redirectToLoginPage();
                }
                throw HttpClient.convertError(error);
            });

    }

    /**
     * Trigger HTTP Get Command
     * @param url
     * @param params
     * @param options Http Client Options
     */
    public static httpGetAll<T>(url: string[], params?: URLSearchParams, options?: HttpClientOptions): Promise<T[]> {

        const promises = new Array<Promise<T>>();

        url.forEach((x) => promises.push(this.httpGet<T>(x, params, options)));

        return axios
            .all(promises)
            .then(axios.spread((...data) => {
                return data;
            }))
            .catch((error: AxiosError) => {
                if (error.response != null && error.response.status === 401) {
                    Util.redirectToLoginPage();
                }
                throw HttpClient.convertError(error);
            });
    }

    /**
     * Trigger HTTP Delete Command
     * @param url
     * @param params
     * @param options Http Client Options
     */
    public static httpDelete(url: string, params?: any, options?: HttpClientOptions): Promise<any> {

        const { newUrl, config } = this.buildAxiosConfig(options, url, params);

        return axios.
            delete(newUrl, config)
            .then((response) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if (error.response != null && error.response.status === 401) {
                    Util.redirectToLoginPage();
                }
                throw HttpClient.convertError(error);
            });
    }

    /**
     * Set the default authroziation token in request headers.
     * @param token
     */
    public static setAuthorizationToken(token: string): void {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    private static buildAxiosConfig(options: HttpClientOptions, url: string, params?: URLSearchParams): ({ newUrl: string, config: AxiosRequestConfig }) {

        let newUrl = url;
        const config: AxiosRequestConfig = {};
        if (params != null) {
            config.params = params;
        }

        config.baseURL = HttpClient.getBaseUrl();

        if (options != null && options.headers != null && window.name) {
            options.headers.PageState = window.name;
            config.headers = options.headers;
        } else {
            config.headers = { PageState: window.name };
        }
                
        if (options != null && options.responseType != null) {
            config.responseType = options.responseType;
        }

        if (options == null || options.prependWebApiPrefix) {
            newUrl = this.WebApiActionsPrefix + url;
        }

        if (options != null && options.cacheEnabled != null && options.cacheEnabled === true) {
            const store = localforage.createInstance({
                driver: [
                    localforage.INDEXEDDB,
                    localforage.LOCALSTORAGE,
                    localforage.WEBSQL,
                ],
                name: "3SS-cache",
            });

            let cacheLifeCycle = CacheLifeCycle.Short;
            if (options != null && options.cacheLifeCycle != null) {
                cacheLifeCycle = options.cacheLifeCycle;
            }

            let cache;
            if (options != null && options.cacheKey != null) {
                cache = setupCache({ debug: false, maxAge: cacheLifeCycle, store, exclude: { query: false }, key() { return options.cacheKey + "/" + newUrl; } });
            } else {
                cache = setupCache({ debug: false, maxAge: cacheLifeCycle, store, exclude: { query: false } });
            }

            config.adapter = cache.adapter;
            newUrl = this.WebApiActionsPrefix + url;
        }

        return { newUrl, config };
    }

    private static convertError(error: AxiosError): HttpClientError {

        const errorObject: HttpClientError = new HttpClientError(error.message, error.name);

        if (error.stack) {
            errorObject.stack = error.stack;
        } // stacktrace

        if (error.config && error.config.url) {
            errorObject.url = error.config.url;
        } // called url

        if (error.request) {
            errorObject.request = error.request;
        }

        if (error.response) {
            if (error.response.status) {
                errorObject.statusCode = error.response.status;
            } // http status code e.g. 500

            if (error.response.data) {
                errorObject.responseData = error.response.data; // in some cases we need the data returned form the server in the error response

                if (error.response.data.exceptionMessage) {
                    errorObject.exceptionMessage = error.response.data.exceptionMessage;
                } // Web API Controller Exception Message is here
            }
        }

        // write a custom formatted message to message attribute
        // because uncaught exceptions are logged via jsnLog and it will only log the message not the whole object
        errorObject.message = this.formatErrorMessage(errorObject, error);

        return errorObject;
    }

    private static formatErrorMessage(errorObject: HttpClientError, error: AxiosError): string {

        let customMessage = `HttpClientError: ${error.message} `;
        if (errorObject.url) {
            customMessage += `RequestURL: ${errorObject.url} `;
        }
        if (errorObject.exceptionMessage) {
            customMessage += `Message: ${errorObject.exceptionMessage} `;
        }

        return customMessage;
    }

}

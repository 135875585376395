// css
import "datatables.net-bs/css/dataTables.bootstrap.css";
import "datatables.net-fixedheader-bs/css/fixedHeader.bootstrap.css";
import "datatables.net-responsive-bs/css/responsive.bootstrap.css";
import "datatables.net-rowgroup-bs/css/rowGroup.bootstrap.css";
import "datatables.net-select-bs/css/select.bootstrap.css";

import "template/plugins/simple-line-icons/simple-line-icons.css";
import "webfonts/css/google-webfonts.css" // .../3SS/Cheetah/Controllers/WebController/Content/3SS/webfonts/css
import "font-awesome/css/font-awesome.css";
import "toastr/build/toastr.css";
import "bootstrap/dist/css/bootstrap.css";

import "template/global/css/components-md.css";
import "template/global/css/plugins-md.css";
import "template/layout/css/layout.css";
import "bs_modal_transition.css";
import "template/custom.css";
import "template/fx/modal/css/animate.css";

import "bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css";
import "bootstrap-touchspin/dist/jquery.bootstrap-touchspin.css";
import "select2/dist/css/select2.css";
import "select2-bootstrap-theme/dist/select2-bootstrap.css";

import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "trumbowyg/dist/ui/trumbowyg.css"

// js components
import "element-polyfill";
import "jquery-ui/ui/widgets/sortable";
import "block-ui";
import "jquery-shorten";
import "toastr";
import "select2-full";
import "select2-de";
import "bootstrap-touchspin";
import "jquery-slimscroll";
import "bootstrap";
import "bootstrap-tabdrop";
import "bootstrap-switch";
import "jquery-validation";
import "datatables.net";
import "datatables.net-bs";
import "datatables.net-fixedheader";
import "datatables.net-responsive";
import "datatables.net-responsive-bs";
import "datatables.net-rowgroup";
import "datatables.net-select";
import "bootstrap-filestyle2";
import "eonasdan-bootstrap-datetimepicker";
import "moment-timezone";
import "trumbowyg";
import "component/trumbowygPlugins/trumbowyg.cleanpaste.ts";
import "component/trumbowygPlugins/trumbowyg.maxlength.ts";
import "component/trumbowygPlugins/trumbowyg.cleanChanges";
import "trumbowyg/dist/langs/de";
import "trumbowyg/dist/ui/icons.svg"

import { TssApp } from "tssApp";

TssApp.getInstance().start();
import "jquery-ui";
import "block-ui";
import "select2-full";
import "select2-de";

import "bootstrap-touchspin";
import "jquery-slimscroll";
import "bootstrap";
import "bootstrap-tabdrop";
import "bootstrap-switch";
import "jquery-validation";
import "jquery-backstretch";

import "font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.css";
import "template/plugins/simple-line-icons/simple-line-icons.css";
import "bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css";
import "bootstrap-touchspin/dist/jquery.bootstrap-touchspin.css";
import "select2/dist/css/select2.css";
import "select2-bootstrap-theme/dist/select2-bootstrap.css";
import "webfonts/css/google-webfonts.css"

import "template/global/css/components-md.css";
import "template/global/css/plugins-md.css";
import "template/pages/css/frontPage.css";
import "template/pages/css/error.css";
import "template/pages/css/genericPages.css";


import { Frontpage } from "frontpage/3ssFrontpage";
import { Login } from "frontpage/3ssLogin";
import { Support } from "frontpage/3ssSupport";
import { Forget } from "frontpage/3ssForget";

export class FrontpageLoader {

    static start() {

        Frontpage.init();
        
        if ($(".login-form").length) {
            Login.init();
        }
        else if ($(".support-form").length) {
            Support.init();
        }
        else if ($(".forget-form").length) {
            Forget.init();
        }

        $(window).on("load", function () {
            Frontpage.windowLoad();
        });
    }

}

FrontpageLoader.start();
import { HttpClient } from "component/httpClient/httpClient";
import { Log } from "component/logging/logging";
import * as Globalize from "globalize/dist/globalize";
import "globalize/dist/globalize/currency";
import "globalize/dist/globalize/date";
import "globalize/dist/globalize/number";
import { Util } from "helper/util";
import { locale as momentLocale } from "moment";
import translations from "../../i18n/uiframework/translations.json";

// Public Locale Class
export class Locale {

    public static initializeLocale(): Promise<void> {
        return LocaleManager.getInstance().init();
    }

    public static getCurrentLanguage(): string {
        return LocaleManager.getInstance().getCurrentLanguage();
    }

    public static getTranslation(term: string): string {
        return LocaleManager.getInstance().getTranslation(term);
    }

    public static parseNumber(value: string): number {
        return LocaleManager.getInstance().parseNumber(value);
    }

    public static formatCurrency(value: string | number, currencyId?: number): string {
        return LocaleManager.getInstance().formatCurrency(value, currencyId);
    }

    public static formatNumber(value: string | number): string {
        return LocaleManager.getInstance().formatNumber(value);
    }

    public static formatDate(value: Date): string {
        return LocaleManager.getInstance().formatDate(value);
    }

    public static getDateformat(): string {
        return LocaleManager.getInstance().getTranslation("date format");
    }
}

// all 3SS Currency Codes with CurrencyIds
enum currencyCodes {
    "AUD" = 36,
    "CAD" = 124,
    "CNY" = 156,
    "DKK" = 208,
    "HKD" = 344,
    "INR" = 356,
    "JPY" = 392,
    "MYR" = 458,
    "MXN" = 484,
    "NZD" = 554,
    "NOK" = 578,
    "SGD" = 702,
    "SEK" = 752,
    "CHF" = 756,
    "GBP" = 826,
    "USD" = 840,
    "TWD" = 901,
    "RON" = 946,
    "EUR" = 978,
    "PLN" = 985,
    "HUF" = 348,
    "KRW" = 410
}

interface LangFile {
    lang: string;
    path: string;
}

// Not Visible outside
class LocaleManager {

    public static getInstance(): LocaleManager {

        if (!LocaleManager.instance) {
            new LocaleManager();
        }

        return LocaleManager.instance;
    }

    // cldr from http://www.unicode.org/repos/cldr-aux/json/26/
    private static readonly files: LangFile[] = [
        {
            lang: "base", path: "dist/cldr/supplemental/likelySubtags.min.json",
        },
        {
            lang: "base", path: "dist/cldr/supplemental/currencyData.min.json",
        },
        {
            lang: "base", path: "dist/cldr/supplemental/timeData.min.json",
        },
        {
            lang: "de-DE", path: "dist/cldr/de/numbers.min.json",
        },
        {
            lang: "de-DE", path: "api/actions/framework/getLanguage?locale=de",
        },
        {
            lang: "de-DE", path: "dist/cldr/de/ca-gregorian.min.json",
        },
        {
            lang: "en-US", path: "dist/cldr/en/numbers.min.json",
        },
        {
            lang: "en-US", path: "api/actions/framework/getLanguage?locale=en",
        },
        {
            lang: "en-US", path: "dist/cldr/en/ca-gregorian.min.json",
        },
    ];

    private static instance: LocaleManager;

    private currentLanguage: string;
    private currencyFormatter: (value: number) => string;
    private numberFormatter: (value: number) => string;
    private dateFormatter: (value: Date) => string;
    private dateTimeFormatter: (value: Date) => string;
    private dateParser: (value: string) => Date;
    private numberParser: (value: string) => number;
    private globalize: Globalize;

    // Using Singleton Pattern
    constructor() {
        if (LocaleManager.instance) {
            throw new Error("Error: Instantiation failed: Use LocaleManager.getInstance() instead of new.");
        }

        LocaleManager.instance = this;
    }

    public async init(): Promise<void> {

        this.initLanguage();

        // set Moment.js language globally
        momentLocale(this.currentLanguage);

        await this.initGlobalize();
    }

    /// Begin Format Functions
    public formatCurrency(value: string | number, currencyId?: number): string {

        // default is 0
        if (value == null) {
            if (currencyId == null) {
                return this.currencyFormatter(0).replace(/([\u00A0])/g, " ");
            } else {
                return this.globalize.formatCurrency(0, this.getCurrencyCode(currencyId)).replace(/([\u00A0])/g, " ");
            }
        }

        // Wenn Zahlenformat im JS Format (z.B. 27.00)
        if (typeof value !== "number") {
            value = parseFloat(value);
        }

        if (currencyId == null) {
            value = this.currencyFormatter(value);
        } else {
            value = this.globalize.formatCurrency(value, this.getCurrencyCode(currencyId));
        }

        // OVS: Der Seperator in JS für Währung ist nicht wie in .NET erwartet ein Space CharCode 32 sondern
        // ein unicode mit CharCode 160. Dies wird hier ersetzt.
        return value.replace(/([\u00A0])/g, " ");
    }

    public formatNumber(value: string | number): string {

        if (value == null) {
            return NaN.toString();
        }

        let convertValue: number;
        if (typeof value === "string") {
            convertValue = parseFloat(value);
        } else if (typeof value === "number") {
            convertValue = value as number;
        } else {
            return NaN.toString();
        }

        return this.numberFormatter(convertValue);
    }

    public formatDate(value: Date): string {

        if (value == null) {
            return new Date().toString();
        }

        if (this.dateFormatter === undefined) {
            Log.logError("Globalize not finished with initialization. Use Locale. to make sure initialization has finshed!");
        }

        return this.dateFormatter(value);
    }

    /// End  Format Functions

    /// Begin Parse Functions

    public parseNumber(value: string): number {

        if (value == null) {
            return NaN;
        }

        if (typeof value === "number") {
            return value;
        }

        value = value.replace(/[^-0-9\\.\\,]+/g, ''); // remove all invalid characters        
        value = value.trim();

        return this.numberParser(value);
    }

    // End Parse Functions

    public getTranslation(term: string): string {
        if (term === "" || term === undefined || term === null) { return ""; }
        if ((translations as Translations)[term.toLowerCase()] === undefined) {
            return term;
        }
        return (translations as Translations)[term.toLowerCase()][this.currentLanguage.replace("-", "")];
    }

    public getCurrentLanguage(): string {
        return this.currentLanguage;
    }

    private initLanguage(): void {
        let lang = $("body").attr("data-lang");

        if (!lang) {
            Log.logError("No Language Attribute found in body tag");
            lang = "en-US";
        }

        this.currentLanguage = lang;
    }

    private async initGlobalize(): Promise<void> {

        try {
            await this.loadRessources(LocaleManager.files);

            this.globalize = new Globalize(this.currentLanguage);

            if ($("[name=currency_id]").length > 0) {
                this.currencyFormatter = this.globalize.currencyFormatter(this.getCurrencyCode());
            }

            this.numberFormatter = this.globalize.numberFormatter({ minimumFractionDigits: 2, maximumFractionDigits: 3 });
            this.dateFormatter = this.globalize.dateFormatter({ date: "medium" });

            // currently not used, maybe later
            // this.dateTimeFormatter = this.globalize.dateFormatter({ datetime: "medium" });
            // this.dateParser = this.globalize.dateParser({ date: "medium" });
            this.numberParser = this.globalize.numberParser();
        }
        catch (e) {
            Log.logError(e);
        }

    }

    private async loadRessources(files: LangFile[]): Promise<void> {
        // cldr from http://www.unicode.org/repos/cldr-aux/json/26/

        // load base files and for current language
        const filteredFiles = files.filter((x) => x.lang === "base" || x.lang === this.currentLanguage);

        const loadFresh = new Array<LangFile>();
        for (const file of filteredFiles) {
            // try to load json from session storage
            // check browser support for session storage
            if (typeof (Storage) !== "undefined") {

                const jsonItem = sessionStorage.getItem(file.path);
                if (jsonItem && jsonItem !== "undefined") {
                    Globalize.load(JSON.parse(jsonItem));
                    continue;
                }
            }
            loadFresh.push(file);
        }

        return HttpClient
            .httpGetAll(loadFresh.map((x) => x.path), null, { prependWebApiPrefix: false })
            .then((dataArray) => {

                for (let i = 0; i < dataArray.length; i++) {
                    const url = loadFresh[i];
                    let ressource = dataArray[i];

                    if (typeof (ressource) == "string")
                        ressource = JSON.parse(ressource);

                    Globalize.load(ressource);
                    sessionStorage.setItem(url.path, JSON.stringify(ressource));
                }
            })
            .catch((error) => {

                let msg = error.message;
                if (error.config) {
                    const failedUrl = error.config.url;

                    msg = "LocaleManager: Could not load JSON File: " + failedUrl;
                }
                Log.logError(msg);

            });
    }

    private getCurrencyCode(currencyId?: number): string {

        if (currencyId) {
            try {
                return currencyCodes[currencyId];
            } catch (err) {
                // currency code ist nicht im array drin
                Log.logError(Locale.getTranslation("unknown currency id"));
                return "EUR";
            }
        }

        // Get Currency Code from Currency_id field

        if ($("[name=currency_id]").length > 0) {
            const cc = parseInt($("[name=currency_id]").val().toString());

            if (!Util.isNumeric(cc)) {
                Log.logInfo(Locale.getTranslation("currency field empty"));
                return "EUR";
            }

            try {
                return currencyCodes[cc];
            } catch (err) {
                // currency code ist nicht im array drin
                Log.logError(Locale.getTranslation("unknown currency id"));
                return "EUR";
            }
        }

        Log.logError(Locale.getTranslation("cannot find currency id"));

        // default currency Code
        return "EUR";
    }

}


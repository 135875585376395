import { Locale } from "component/localeManager/localeManager";
import { IncreaseType, Modules, PayTypeUsed } from "enum/allEnums";
import { DetailForm } from "../../uiFramework/core/forms/detailForm";
import { FormHelper } from "../../uiFramework/helper/formHelper";

export class CalculationTable {

    public static getInstance(): CalculationTable {

        if (!CalculationTable._instance) {
            CalculationTable._instance = new CalculationTable();
        }

        return CalculationTable._instance;

    }

    private static _instance: CalculationTable;

    public previousEmploymentError: any;
    public paymentGroupError: any;

    constructor() {
        if (CalculationTable._instance) {
            throw new Error("Error: Instantiation failed: Use CalculationTable.getInstance() instead of new.");
        }
        CalculationTable._instance = this;
    }

    public drawTable(module: Modules, rateTable: RatesTable) {
        if (rateTable === undefined || rateTable === null) {
            return;
        }

        // build basic table
        const table = $(document.createElement("table")).addClass("calc_table");
        const tbody = $(document.createElement("tbody"));
        const trHead = $(document.createElement("tr")).addClass("tvbz_nowrap");
        table.append(tbody);
        tbody.append(trHead);

        // Fuer EG / VEG brauchen wir 2 Tabellen mit und ohne Anrechnung
        if (rateTable.chargedPayRates != null
            && rateTable.chargedBillRates != null
            && rateTable.unchargedPayRates != null
            && rateTable.unchargedBillRates != null) {

            let activeStep = null;
            if (module === Modules.Award || module === Modules.ChangeRequest) {
                activeStep = this.getActiveLevelFromTime(rateTable.dateEffectives);
            } else {
                activeStep = this.getIndexFromDate(rateTable.dateEffectives, rateTable.startDate);
            }

            this.appendHeaderAndTable(rateTable, table, activeStep, module);
        }

        const calcTableDiv = this.getCalculationTableDiv();
        if (!calcTableDiv) { return; }

        if (this.calculationTableExists()) {
            calcTableDiv.removeChild(calcTableDiv.firstChild);
        }
        calcTableDiv.appendChild(table[0]);

        this.showCalculationTable(true);
    }

    public showCalculationTable(show: boolean) {
        if (!this.paymentGroupError && !this.previousEmploymentError && show) {
            $("div.calculationTable").show();
            $("div").remove("div #calculation_table_message");
        } else {
            $("div.calculationTable").hide();
        }
    }

    public replaceCalculationTableWithMessage(message: string) {
        this.showCalculationTable(false);
        if ($("div #calculation_table_message").length !== 0) {
            $("div").remove("div #calculation_table_message");
        }
        const messageDiv = document.createElement("div");
        messageDiv.style.color = "#f60";
        messageDiv.innerHTML = message;
        messageDiv.id = "calculation_table_message";
        $("div.calculationTable").after(messageDiv);
    }

    public getCalculationTableDiv(): HTMLElement {

        const form = document.querySelector("[data-form-class=calculationTemp]");

        if (!form) { return null; }

        let calculationTableDiv = form.querySelector("div.calculationTable") as HTMLElement;
        if (calculationTableDiv == null) {

            // if no div for calculation Table, create one
            const rowDiv = document.createElement("div");
            rowDiv.classList.add("row");

            calculationTableDiv = document.createElement("div");
            calculationTableDiv.classList.add("calculationTable");
            calculationTableDiv.setAttribute("style", "overflow-x: auto");

            rowDiv.appendChild(calculationTableDiv);
            form.querySelector("div.form-body").appendChild(rowDiv);

            return calculationTableDiv;
        } else {
            return calculationTableDiv;
        }

    }

    public appendTableHeaders(table: JQuery,
                       rateTable: RatesTable,
                       activeTvbzStep: number,
                       headerText: string,
    ) {

        // Überschrift
        const headerElem = $("<h4/>").text(Locale.getTranslation(headerText)).addClass("form-section");
        const thElem = $("<th/>").addClass("tvbz_th_header").attr("colspan", rateTable.dateEffectives.length + 1);
        thElem.append(headerElem);
        this.getLastRow(table).append(thElem);
        this.appendHeaderRows(table, rateTable, activeTvbzStep);
    }

    public appendTableRows(table: JQuery,
                    activeTvbzStep: number,
                    module: Modules,
                    payRates: number[],
                    billRates: number[],
                    chargeRates: number[],
                    payRateTerm: string,
                    billRateTerm: string,
                    chargeRateTerm: string,
    ) {
        // Data Rows mit Anrechnung
        this.appendTableRow(table, payRates, payRateTerm, "", "tvbz_th_row font-blue", "tvbz_td_row", this.formatCurrencyText, false, activeTvbzStep);
        this.appendTableRow(table, billRates, billRateTerm, "", "tvbz_th_row font-blue", "tvbz_td_row", this.formatCurrencyText, false, activeTvbzStep);

        if (module !== Modules.Bid && this.showChargeRateRow()) {
            this.appendTableRow(table, chargeRates, chargeRateTerm, "", "tvbz_th_row font-blue", "tvbz_td_row", this.formatCurrencyText, false, activeTvbzStep);
        }
    }

    public appendHeaderRows(table: JQuery, rateTable: RatesTable, currentTvbzStep: number) {
        // Date Row
        this.appendTableRow(table, rateTable.dateEffectives, Locale.getTranslation("Date"), "tvbz_tr_row", "tvbz_th font-blue", "tvbz_td_date font-blue", this.formatDateText, false, currentTvbzStep);
        this.appendTableRow(table, this.buildIncreaseDurationArray(rateTable), "", "tvbz_tr_row", "tvbz_th font-blue", "tvbz_td_date font-blue", null, false, currentTvbzStep);

        // Step Row - only for pay type EG, VEG, INDIVIDUAL and when they have Increases
        if (this.intersect(rateTable.payTypesUsed, [PayTypeUsed.PayGroup, PayTypeUsed.ComparativeEarning, PayTypeUsed.IndividualRegulation]).length > 0 && rateTable.increaseLevels.length > 1) {
            this.appendTableRow(table, rateTable.increaseLevels, "", "tvbz_tr_row", "tvbz_th_stufe", "tvbz_td_stufe_", this.formatLevelText, true, currentTvbzStep);
        }

        // Rate Reason
        this.appendTableRow(table, rateTable.increaseReasons, Locale.getTranslation("reason"), "", "tvbz_th_row font-blue", "tvbz_td_row", this.formatRateReasonText, false, currentTvbzStep);

        // TVBZ Surcharge Row
        if (this.intersect(rateTable.payTypesUsed, [PayTypeUsed.PayGroup, PayTypeUsed.ComparativeEarning, PayTypeUsed.IndividualRegulation]).length > 0) {
            const surchargeText = rateTable.payTypesUsed[0] === PayTypeUsed.IndividualRegulation ? Locale.getTranslation("surcharge") : Locale.getTranslation("surcharge tvbz");

            const increases = this.formatIncreases(rateTable.increases, rateTable.increaseTypes);

            this.appendTableRow(table, increases, surchargeText, "", "tvbz_th_row font-blue", "tvbz_td_row", null, false, currentTvbzStep);
        }
    }

    public intersect(a: any, b: any) {
        let t;
        if (b.length > a.length) { t = b, b = a, a = t; } // indexOf to loop over shorter
        return a.filter(function(e: any) {
            return b.indexOf(e) > -1;
        });
    }

    public calculationTableExists() {
        if ($("table.calc_table").length > 0) {
            return true;
        } else {
            return false;
        }
    }

    public formatIncreases(increases: number[], increaseTypes: any[]) {

        const formattedIncreases = new Array<string>();

        for (let i = 0; i < increases.length; i++) {

            if (increases[i] === 0) {
                formattedIncreases.push("");
            } else {
                const value = (increaseTypes[i] === IncreaseType.Percentage) ? this.formatPercentageText(increases[i]) : this.formatCurrencyText(increases[i].toString());
                formattedIncreases.push(value);
            }
        }

        return formattedIncreases;

    }

    // Text Format Callback Functions
    public formatCurrencyText(value: string) {
        if (value != null && value !== null && value !== "") {
            return Locale.formatCurrency(value);
        }
        return "";
    }

    public formatDateText(value: any) {
        if (value != null && value !== null && value !== "") {
            return Locale.formatDate(new Date(Date.parse(value)));
        }
        return "";
    }

    public formatPercentageText(value: any) {
        if (value != null && value !== null && value !== "" && value !== 0) {
            return Locale.formatNumber(value) + "%";
        }
        return "";
    }

    public formatLevelText(level: any) {
        if (level != null && level !== null && level !== "" && level > 0) {
            return `${Locale.getTranslation("step")} ${level}`;
        }
        return "";
    }

    public formatRateReasonText(value: number) {
        const reasons = [];
        if ((value & 1) === 1) {
            reasons.push(Locale.getTranslation("init"));
        }
        if ((value & 2) === 2) {
            reasons.push(Locale.getTranslation("change order rate"));
        }
        if ((value & 4) === 4) {
            reasons.push(Locale.getTranslation("regular base pay factor after training phase"));
        }
        if ((value & 8) === 8) {
            reasons.push(Locale.getTranslation("increase step"));
        }
        if ((value & 16) === 16) {
            reasons.push(Locale.getTranslation("payment group rule effective change"));
        }
        if ((value & 32) === 32) {
            reasons.push(Locale.getTranslation("increases effective change"));
        }
        if ((value & 64) === 64) {
            reasons.push(Locale.getTranslation("original start date"));
        }
        if ((value & 128) === 128) {
            reasons.push(Locale.getTranslation("equal pay start"));
        }
        if ((value & 256) === 256) {
            reasons.push(Locale.getTranslation("day before maximum operational period"));
        }
        if ((value & 512) === 512) {
            reasons.push(Locale.getTranslation("tvbz change"));
        }
        return reasons.join(", ");
    }

    public appendTableRow(table: JQuery,
                   rowData: any[],
                   thText: string,
                   trClassName: string,
                   thClassName: string,
                   tdClassName: string,
                   formatFunction: Function,
                   useRowDataAsTDClassIterator: boolean,
                   activeColumnNumber: number) {

        if (rowData === undefined || rowData === null || rowData.length === 0) {
            return "";
        }
        const lastRow = this.getLastRow(table);
        // add th first
        if (thClassName != null) {
            lastRow.append($("<th/>").text(thText).addClass(thClassName));
        } else {
            lastRow.append($("<th/>").text(thText));
        }
        // add td's
        for (let i = 0; i < rowData.length; i++) {
            //// here are some tricky format things
            // defaults
            let tdText = rowData[i];
            let generatedTdClassName = tdClassName;
            // iterated td class name and pass iterator to format function for Step Row
            // format the text with the right callback function
            if (useRowDataAsTDClassIterator && formatFunction != null && formatFunction !== null) {
                generatedTdClassName = tdClassName + rowData[i];
                tdText = formatFunction(rowData[i]);
            } else if (formatFunction != null && formatFunction !== null) {
                if (formatFunction === this.formatCurrencyText && rowData[i] === 0) {
                    tdText = "-";
                } else {
                    tdText = formatFunction(rowData[i]);
                }
            }
            const td = $("<td/>").text(tdText);
            if (tdClassName != null) {
                td.addClass(generatedTdClassName);
            }
            // highlight active row
            if (activeColumnNumber != null && activeColumnNumber === i) {
                td.css("font-weight", "600");
                if (tdClassName === "tvbz_td_row") {
                    td.css("background-color", "#FFFBCC");
                }
            }
            lastRow.append(td);
        }
        if (trClassName != null) {
            lastRow.addClass(trClassName);
        }
        return lastRow;
    }

    //
    //// helper functions
    //
    public getLastRow(table: JQuery) {
        return $("<tr/>").appendTo(table.find("tbody:last"));
    }

    public buildIncreaseDurationArray(rateTable: RatesTable) {
        const dateArray = [];
        for (let i = 0; i < rateTable.dateEffectives.length; i++) {
            if (i === 0 && rateTable.increaseLevels[i] > 0 && rateTable.hasPreEmployment) {
                dateArray[i] = Locale.getTranslation("previous employment");
            } else if ((rateTable.increaseReasons[i] & 8) === 8) {
                dateArray[i] = (rateTable.workingDuration[i] > 0) ? ` > ${rateTable.workingDuration[i]} ${this.getDurationName(rateTable.durationType[i])}` : "";
 }
        }
        return dateArray;
    }

    // Translates the Duration Type
    public getDurationName(durationType: number) {
        let vocab = "";
        switch (durationType) {
            case 1:
                vocab = Locale.getTranslation("days");
                break;
            case 2:
                vocab = Locale.getTranslation("weeks");
                break;
            case 3:
                vocab = Locale.getTranslation("months");
                break;
            case 4:
                vocab = Locale.getTranslation("years");
                break;
        }
        return vocab;
    }

    private appendHeaderAndTable(rateTable: RatesTable, table: JQuery, activeStep: number, module: Modules) {

        if (rateTable.payTypesUsed[0] === PayTypeUsed.None || rateTable.payTypesUsed[0] === PayTypeUsed.IndividualRegulationNoIncreases) {

            // this pay types only have one table and there is everything in non charged rate fields

            this.appendTableHeaders(table, rateTable, activeStep, "extra_pay_header");
            this.appendTableRows(table, activeStep, module, rateTable.unchargedPayRates, rateTable.unchargedBillRates, rateTable.unchargedChargeRates, rateTable.payRateTerm, rateTable.billRateTerm, rateTable.chargeRateTerm,);

        } else if (module === Modules.Award || module === Modules.ChangeRequest) {

            // Award and CR: Show only one table, because after bid, decision is made whether having charged rates or not

            this.appendTableHeaders(table, rateTable, activeStep, rateTable.hasChargedRates ? "tvbz_header_1" : "tvbz_header_2");
            this.appendTableRows(table,
                activeStep,
                module,
                rateTable.hasChargedRates ? rateTable.chargedPayRates : rateTable.unchargedPayRates,
                rateTable.hasChargedRates ? rateTable.chargedBillRates : rateTable.unchargedBillRates,
                rateTable.hasChargedRates ? rateTable.chargedChargeRates : rateTable.unchargedChargeRates,
                rateTable.payRateTerm, rateTable.billRateTerm, rateTable.chargeRateTerm);

        } else {
            // show both tables, for charged rates and not charged rates

            let firstTableActiveStep, secondTableActiveStep;
            if (module === Modules.Project) {
                firstTableActiveStep = secondTableActiveStep = activeStep;
            } else { // Bid
                firstTableActiveStep = rateTable.hasChargedRates ? activeStep : null;
                secondTableActiveStep = rateTable.hasChargedRates ? null : activeStep;
            }

            this.appendTableHeaders(table, rateTable, firstTableActiveStep, "tvbz_header_1");
            this.appendTableRows(table,
                firstTableActiveStep,
                module,
                rateTable.chargedPayRates,
                rateTable.chargedBillRates,
                rateTable.chargedChargeRates,
                rateTable.payRateTerm,
                rateTable.billRateTerm,
                rateTable.chargeRateTerm,
            );

            this.appendTableHeaders(table, rateTable, secondTableActiveStep, "tvbz_header_2");
            this.appendTableRows(table,
                secondTableActiveStep,
                module,
                rateTable.unchargedPayRates,
                rateTable.unchargedBillRates,
                rateTable.unchargedChargeRates,
                rateTable.payRateTerm,
                rateTable.billRateTerm,
                rateTable.chargeRateTerm,
            );

        }
    }

    private showChargeRateRow(): boolean {
        const form = FormHelper.getFormsByFormClass("calculationTemp");
        if (form == null || form.length === 0) { return false; }

        // when control is disabled in template, getControl returns null
        const c = (form[0] as DetailForm).getControl("charge_rate");
        return c != null;
    }

    private getActiveLevelFromTime(dateArray: string[]) {
        const currentDate = new Date();
        return this.getIndexFromDate(dateArray, currentDate.toISOString());
    }

    private getIndexFromDate(dateArray: string[], startDate: string) {
        let level = 0;
        while (level !== dateArray.length && new Date(startDate) >= new Date(dateArray[level])) {
            level++;
        }
        return (level - 1 < 0) ? 0 : level - 1;
    }

}

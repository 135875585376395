import "layout";
import "quick-nav";
import "quick-sidebar";

import { DebugBox } from "component/debugBox/debugBox";
import { Locale } from "component/localeManager/localeManager";
import { Log } from "component/logging/logging";
import { LogLevels } from "component/logging/logLevels";
import { Framework } from "framework";
import { Guid } from "guid-typescript";
import { Page } from "page/page";
import StickySidebar from "sticky-sidebar";
import { HttpClient } from "./component/httpClient/httpClient";
import { ModalType } from "./enum/allEnums";
import { FormHelper } from "./helper/formHelper";
import { SessionHubConnector } from './component/signalR/sessionValidator/sessionHubConnector';
import { SessionValidator } from './component/signalR/sessionValidator/sessionValidator';

export class TssApp {

    public static getInstance(): TssApp {

        if (!TssApp.instance) {
            TssApp.instance = new TssApp();
        }

        return TssApp.instance;

    }

    private static instance: TssApp;
    private page: PageInterface;

    private constructor() {

    }

    /* Any code that needs to fire after the page has loaded (hooking up events, etc) should be inside here */
    public async start(): Promise<void> {

        if (Log.getLogLevel() <= LogLevels.Debug) { // expose tssApp to global namespace for debugging
            (window as any).tssApp = this;
        }

        HttpClient.setup();

        await Locale.initializeLocale();

        this.initBlockUI();

        this.initWindow();        

        this.setupValidator();

        this.buildUserMenu();

        this.initializePage();

        this.wireGlobalSearch();

        this.initDebugBox();

        this.initSidebar();

        this.initScrollTop();

        this.initializeLinks();

        this.initSessionValidator();
    }

    
    public initializeLinks(): void {
        const anchorTags = $("a[data-bind-click='redirect']");
        anchorTags.click(function(event: JQuery.Event ) {
            const href = (this as HTMLAnchorElement).href;
            if (href.toLocaleLowerCase().startsWith("http")) {
                event.preventDefault();
                HttpClient.browserRedirect(href);
            }
        });

        anchorTags.removeData("bind-click");
    }

    private async initSessionValidator() {

        if (document.body.getAttribute("data-disable-hubs") === "1")
            return;

        const hubConnector = new SessionHubConnector();
        await hubConnector.connect();

        const validator = new SessionValidator();
        validator.initialize(hubConnector);

    }

    public getPage(): PageInterface {
        return this.page;
    }

    private initWindow() {
        if (!window.name || !Guid.isGuid(window.name)) {
            window.name = Guid.create().toString();
            if (!window.location.href.includes("inbox.app") &&
                !window.location.href.includes("punchout") &&
                !window.location.href.includes("autolink=true"))
                HttpClient.browserRedirect(window.location.href);
        }
    }

    private initDebugBox() {
        const debugBox = new DebugBox();
        debugBox.initialize();
    }

    private initSidebar() {

        const sidebarSelector = ".page-sidebar .navbar";
        const sidebar = document.querySelector(sidebarSelector);

        if (!sidebar) { return; }

        this.initStickySidebar(sidebarSelector, sidebar);

        Framework.reorderNavAndContent();
        $(window).on("resize", Framework.reorderNavAndContent);
    }

    private initStickySidebar(sidebarSelector: string, sidebar: Element) {

        const content = document.querySelector(".page-content-col .row");

        // enable sticky sidebar only when content is larger than sidebar, otherwise we have buggy/jumping scrollbar
        // wait for onload event, because otherwise heights are not correct
        window.onload = () => {
            if (content && content.clientHeight > sidebar.clientHeight) {
                new StickySidebar(sidebarSelector, {
                    topSpacing: 75,
                    bottomSpacing: 46,
                });
            }
        };

    }

    private initializePage(): void {

        this.page = new Page();
        this.page.initialize();
    }

    private initBlockUI(): void {
        /* set variables to defaults */
        $.blockUI.defaults.message = "<div class=\"cssload-thecube\"><div class=\"cssload-cube cssload-c1\"></div><div class=\"cssload-cube cssload-c2\"></div>" +
            "<div class=\"cssload-cube cssload-c4\"></div><div class=\"cssload-cube cssload-c3\"></div></div>";
        $.blockUI.defaults.css = {};
    }

    private wireGlobalSearch(): void {

        const searchBox = $("form.search input.form-control");
        searchBox.attr("placeholder", Locale.getTranslation("search..."));

        // prevent site search when search field is empty
        $("form.search").submit(function() {
            if ($.trim($("#searchbox").val() as string).length === 0) {
                return false;
            }
            return true;
        });

        // bind click event handler to link
        $("#globalsearch").click(function() {

            $(this).closest("form").trigger("submit");

            // return false to stop the default behavior of the link
            return false;
        });
    }

    private setupValidator(): void {

        $.extend($.validator.messages, {
            required: Locale.getTranslation("required"),
        });

        // Override resetElements function of JQuery-validation plugin
        // because default method removes error / success classes on the element directly.
        // But we need to remove the classes on the form-group
        $.validator.prototype.resetElements = function(elements: JQuery) {

            elements.each((i, element) => {

                $(element).closest(".form-group").removeClass("has-success has-error");
                $(element).parent(".input-icon").children("i").removeClass("fa-warning force-tooltip fa-check");

            });

        };
    }

    private initScrollTop(): void {

        $(window).scroll(function() {

            if ($(this).scrollTop() > 50) {
                $(".scrolltop:hidden").stop(true, true).fadeIn();
            } else {
                $(".scrolltop").fadeOut();
            }
        });

        $(".scroll").click(function() {
            $("html,body").animate({ scrollTop: 0 }, "1000");
        });
    }

    private buildUserMenu(): void {

        // Add titles to top right menu
        const hasTooltipClass = "has-tooltip";
        const titleAttr = "title";

        const topbar = $("div.topbar-actions");
        topbar.find("#orgmenu button").addClass(hasTooltipClass).attr(titleAttr, Locale.getTranslation("orgpicker"));
        topbar.find("#help a").addClass(hasTooltipClass).attr(titleAttr, Locale.getTranslation("help"));
        topbar.find("#support button").addClass(hasTooltipClass).attr(titleAttr, Locale.getTranslation("support"));
        topbar.find("#usermenu button").addClass(hasTooltipClass).attr(titleAttr, Locale.getTranslation("usermenu"));

        // Fill Support Menu
        this.getMenu("753388f9-4b55-4de9-9f7e-aaabe139ba09", "my_support.app")
            .then((menuHtmlElement) => {
                if (menuHtmlElement != null) {
                    const supportMenuRoot = $("ul.dropdown-menu-v2.supportmenu");
                    supportMenuRoot.append(menuHtmlElement);
                } else {
                    $("#support").hide();
                }
            });

        // Fill User Menu
        this.getMenu("86879879-59de-480f-863e-878f4d65703f", "my_account_contact_info.app")
            .then((menuHtmlElement) => {
                if (menuHtmlElement != null) {
                    const supportMenuRoot = $("ul.dropdown-menu-v2.usermenu");
                    supportMenuRoot.append(menuHtmlElement);
                }
            });

    }

    private getMenu(menuFormId: string, menuPageName: string): Promise<JQuery> {
        const formConfiguration: FormConfiguration = {
            formId: menuFormId,
            pageName: menuPageName,
            modalType: ModalType.None,
        };

        if (!menuFormId || !menuPageName) {
            return null;
        }

        // Fill Support Menu
        return FormHelper.loadFormDOMFromServer(formConfiguration)
            .then((data) => {

                if (data == null || data.length === 0) {

                    return null;
                }

                const rootNode = $(data);
                return rootNode.find("li");

            })
            .catch((error) => {
                if (error.message != null) {
                    Log.logError(error.message);
                } else {
                    Log.logError(error);
                }

                return null;
            });

    }
}

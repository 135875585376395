import { Dispatcher } from "dispatcher/dispatcher";
import { FormStyles } from "enum/allEnums";
import { CalendarForm } from "forms/calendarForm";
import { DetailForm } from "forms/detailForm";
import { DetailFormWithDataGrid } from "forms/detailFormWithDataGrid";
import { GraphicalForm } from "forms/graphicalForm";
import { GridForm } from "forms/gridForm";
import { TreeviewForm } from "forms/treeviewForm";
import { ContainerForm } from "../core/forms/containerForm";

export class FormFactory {
    /**
     * Creates a new Form instance
     * @param node should be a div.portlet-body (performance), but must be inside a div.portlet-body.
     */
    public static instantiateForm(node: JQuery, formCallbacks?: FormCallback | FormCallback[]): FormInterface {

        const portletBody = node.closest("div.portlet-body");

        const formStyle = parseInt(portletBody.find("form").data("form-style")) as FormStyles;

        const dispatcher = new Dispatcher();
        let form: FormInterface = null;
        switch (formStyle) {

            case FormStyles.Grid:
                form = new GridForm(dispatcher, portletBody, formCallbacks);
                break;

            case FormStyles.Detail:
                form = new DetailForm(dispatcher, portletBody, formCallbacks);
                break;

            case FormStyles.DetailFormWithDataGrid:
                form = new DetailFormWithDataGrid(dispatcher, portletBody, formCallbacks);
                break;

            case FormStyles.Graphical:
                form = new GraphicalForm(dispatcher, portletBody, formCallbacks);
                break;

            case FormStyles.Calendar:
                form = new CalendarForm(dispatcher, portletBody, formCallbacks);
                break;

            case FormStyles.Links:
                // nothing to do here
                break;

            case FormStyles.Treeview:
                form = new TreeviewForm(dispatcher, portletBody, formCallbacks);
                break;

            case FormStyles.Container:
                form = new ContainerForm(dispatcher, portletBody, formCallbacks);
                break;

            default:
                throw new Error("Method not implemented.");

        }

        return form;
    }
}

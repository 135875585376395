import { Util } from "../helper/util";

export class Framework {

    public static getInstance(): Framework {
        if (!Framework._instance) {
            Framework._instance = new Framework();
        }

        return Framework._instance;
    }

    // Swap positions for Sidebar and Content for Mobile View.
    // Otherwise Action Menu will open add buttom of the page
    public static reorderNavAndContent(): void {

        const sidebar = $("div.page-sidebar:not(.debug-sidebar)");
        if (sidebar.length === 0) { return; }

        const content = $("div.page-content-col:not(.debug-content)");

        const viewportWidth = Util.getViewPort().width;
        const mdBreakpoint = Util.getResponsiveBreakpoint("md");

        const button = $("div.page-content button.navbar-toggle");
        if (viewportWidth < mdBreakpoint && (sidebar.index() - content.index()) === 1) {
            // switching to mobile
            $(content).insertAfter(sidebar);
            button.css("display", "inline-block");
        } else if (viewportWidth >= mdBreakpoint && content.index() - sidebar.index() === 1) {
            // switching to desktop
            sidebar.insertAfter(content);
            button.css("display", "none");
        }
    }

    public static addBottomTextToControl(control: JQuery<Element>, text: string) {

        const fieldcontainer = control.closest("div.fieldcontainer");
        if (fieldcontainer == null) { return; }

        let infoTextSpan = fieldcontainer.find("span.help-block");

        if (infoTextSpan == null) {

            infoTextSpan = $(document.createElement("span"));
            infoTextSpan[0].classList.add("help-block");
            infoTextSpan.text(text);

            fieldcontainer.append(infoTextSpan);
        } else {
            infoTextSpan.text(text);
        }
    }

    public static verifyFieldcontainer(divFieldcontainer: JQuery): void {
        if (!divFieldcontainer.is("div") || !divFieldcontainer.hasClass("fieldcontainer")) {
            throw new Error("Specified <div> is not a fieldcontainer!" + divFieldcontainer.attr("class"));
        }
    }

    /**
     * Verifies that the given div is a portlet-body
     * @param divPortlet
     */
    public static verifyPortlet(divPortlet: JQuery): void {

        if (!divPortlet.is("div") || !divPortlet.hasClass("portlet-body")) {
            throw new Error("Specified <div> is not a portlet body!" + divPortlet.attr("class"));
        }
    }

    private static _instance: Framework;

    constructor() {
        if (Framework._instance) {
            throw new Error("Error: Instantiation failed: Use Framework.getInstance() instead of new.");
        }

    }

}

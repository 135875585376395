import { Locale } from "../../uiFramework/component/localeManager/localeManager";
import { DetailForm } from "../../uiFramework/core/forms/detailForm";
import { Modules, PayTypeUsed, Region } from "../../uiFramework/enum/allEnums";
import { ControlHelper } from "../../uiFramework/helper/controlHelper";
import { FormHelper } from "../../uiFramework/helper/formHelper";

export function getCalculationForm(): DetailForm {

    return FormHelper.getFormsByFormClass("calculationTemp")[0] as DetailForm;
}

export function isPayGroupBased(payTypeUsed: PayTypeUsed): boolean {

    return payTypeUsed === PayTypeUsed.PayGroup || payTypeUsed === PayTypeUsed.ComparativeEarning;

}

export function getPaymentGroupRuleId(module: Modules, form: DetailForm): string {

    let paymentGroupRuleControl;
    if (module === Modules.Bid) {
        paymentGroupRuleControl = form.getControl("bid_payment_group_rule_id");
    }
    else {
        paymentGroupRuleControl = form.getControl("payment_group_rule_id");
    }

    if (!paymentGroupRuleControl || !paymentGroupRuleControl.getValues()[0].value) { return "" }

    return paymentGroupRuleControl.getValues()[0].value;

}

export function getPayTypeUsed(module: Modules, form: DetailForm): PayTypeUsed {

    let payTypeUsedControl;
    if (module === Modules.Bid) {
        payTypeUsedControl = form.getControl("bid_pay_type_used");
    } else {
        payTypeUsedControl = form.getControl("pay_type_used");
    }

    if (!payTypeUsedControl || !payTypeUsedControl.getValues()[0].value) { return PayTypeUsed.None; }

    return parseInt(payTypeUsedControl.getValues()[0].value) as PayTypeUsed;

}

export function getPayGrade(module: Modules, form: DetailForm): string {

    let payGradeControl;
    if (module === Modules.Bid) {
        payGradeControl = form.getControl("bid_pay_group");
    } else {
        payGradeControl = form.getControl("pay_grade_new");
        if (!payGradeControl) {
            payGradeControl = form.getControl("required_skill");
        }
    }

    if (!payGradeControl || !payGradeControl.getValues()[0].value) { return ""; }

    return payGradeControl.getValues()[0].value;
}

export function getRegion(module: Modules, form: DetailForm): Region {

    let regionControl;
    if (module === Modules.Bid) {
        regionControl = form.getControl("bid_region_id");
    } else {
        regionControl = form.getControl("region_id");
    }

    if (!regionControl || !regionControl.getValues()[0].value) { return Region.West; }

    return parseInt(regionControl.getValues()[0].value) as Region;
}

export function getPayGradeRate(module: Modules, form: DetailForm): number {

    let payGradeRateControl;
    if (module === Modules.Bid) {
        payGradeRateControl = form.getControl("vk_value");
        if (!payGradeRateControl) {
            payGradeRateControl = form.getControl("bid_pay_group_rate");
        }
    } else {
        payGradeRateControl = form.getControl("pay_group_rate");
    }

    if (!payGradeRateControl || !payGradeRateControl.getValues()[0].value) { return 0; }

    return Locale.parseNumber(payGradeRateControl.getValues()[0].value);

}

export function getIndustryId(form: DetailForm): number {

    const industryIdControl = form.getControl("bid_surcharge_id");

    if (!industryIdControl || !industryIdControl.getValues()[0].value) { return 0; }

    return Locale.parseNumber(industryIdControl.getValues()[0].value);
}

export function getStartDate(module: Modules, form: DetailForm): Date {

    if (module === Modules.Award || module === Modules.ChangeRequest) {
        return ControlHelper.getSingleDateOrDefault(form, "award_start_date", null);
    } else {
        return ControlHelper.getSingleDateOrDefault(form, "start_date", null);
    }

}

export function getEndDate(module: Modules, form: DetailForm): Date {

    if (module === Modules.Award || module === Modules.ChangeRequest) {
        return ControlHelper.getSingleDateOrDefault(form, "award_end_date", null);
    } else {
        return ControlHelper.getSingleDateOrDefault(form, "end_date", null);
    }

}

export function getHasChargedRatesControl(module: Modules, form: DetailForm) {

    if (module === Modules.Project) {
        return form.getControl("supplier_calc_pay");
    } else {
        return form.getControl("calc_extra_pay");
    }

}

export function getHasChargedRates(module: Modules, form: DetailForm) {

    const value = ControlHelper.getSingleNumberOrDefaultByControl(getHasChargedRatesControl(module, form), 0);

    return value === 1;

}

export function getExtraPay1Control(module: Modules, form: DetailForm) {

    if (module === Modules.Project) {
        return form.getControl("supplier_extra_pay_1");
    } else {
        return form.getControl("extra_pay_1");
    }
}

export function getExtraPay1(module: Modules, form: DetailForm) {
    return ControlHelper.getSingleNumberOrDefaultByControl(getExtraPay1Control(module, form), 0);
}

export function getExtraPay2Control(module: Modules, form: DetailForm) {

    if (module === Modules.Project) {
        return form.getControl("supplier_extra_pay_2");
    } else {
        return form.getControl("extra_pay_2");
    }
}

export function getExtraPay2(module: Modules, form: DetailForm) {
    return ControlHelper.getSingleNumberOrDefaultByControl(getExtraPay2Control(module, form), 0);
}

export function getEqualPayFactorControl(module: Modules, form: DetailForm) {

    if (module === Modules.Project) {
        return form.getControl("supplier_factor_equal_pay");
    } else {
        return form.getControl("factor_equal_pay");
    }

}

export function getEqualPayFactor(module: Modules, form: DetailForm) {
    return ControlHelper.getSingleNumberOrDefaultByControl(getEqualPayFactorControl(module, form), null);
}

export function getIncreaseFactorControl(module: Modules, form: DetailForm) {

    if (module === Modules.Project) {
        return form.getControl("supplier_factor_tvbz");
    } else {
        return form.getControl("factor_tvbz");
    }

}

export function getIncreaseFactor(module: Modules, form: DetailForm) {
    return ControlHelper.getSingleNumberOrDefaultByControl(getIncreaseFactorControl(module, form), 1);
}

export function getBasePayFactorControl(module: Modules, form: DetailForm) {

    if (module === Modules.Project) {
        return form.getControl("supplier_factor_1");
    } else if (module === Modules.Bid) {
        return form.getControl("factor_1");
 } else if (module === Modules.Award || module === Modules.ChangeRequest) {
        return form.getControl("factor_pay_grade");
 }

    return null;
}

export function getBasePayFactor(module: Modules, form: DetailForm) {
    return ControlHelper.getSingleNumberOrDefaultByControl(getBasePayFactorControl(module, form), 1);
}

export function getExtraPayFactorControl(module: Modules, form: DetailForm) {

    if (module === Modules.Project) {
        return form.getControl("supplier_factor_2");
    } else if (module === Modules.Bid) {
        return form.getControl("factor_2");
 } else if (module === Modules.Award || module === Modules.ChangeRequest) {
        return form.getControl("factor_extra_pay");
 }

    return null;
}

export function getExtraPayFactor(module: Modules, form: DetailForm) {
    return ControlHelper.getSingleNumberOrDefaultByControl(getExtraPayFactorControl(module, form), 1);
}

export function getObjectId(module: Modules, form: DetailForm): string {

    let control;
    if (form) {
        switch (module) {

            case Modules.Project:

                control = form.getControl("project_id");
                break;

            case Modules.Bid:

                control = form.getControl("bid_id");
                break;

            case Modules.Award:

                control = form.getControl("award_id");
                break;

            case Modules.ChangeRequest:

                control = form.getControl("award_cr_id");
                break;

            default:

                throw new Error(`Module ${module} not implemented`);

        }
    }
    if (!form) {
        throw new Error(`GetObjectId: Form not set for module ${module}!`);
    } else if (!control) {
        throw new Error(`GetObjectId: Control for module ${module} not found!`);
    } else if (!control.hasValue) {
        throw new Error(`GetObjectId: Object Id for module ${module} not found!`);
 }

    return control.getValues()[0].value;
}

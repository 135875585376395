import { ButtonBuilder } from "buttons/buttonBuilder";
import { Locale } from "component/localeManager/localeManager";
import { FormAction, ModalType } from "enum/allEnums";
import { DialogType } from "modals/enum/dialogType";

export class ModalBuilder {

    private readonly modalId: string;
    private readonly modalType: ModalType;
    private modalContainer: JQuery;
    private readonly rootPortlet: JQuery;

    private readonly selectorSectionForm = ".section-form";

    constructor(rootPortlet: JQuery, modalId: string, modalType: ModalType) {
        this.modalId = modalId;
        this.rootPortlet = rootPortlet;
        this.modalType = modalType;
    }

    /// Modal Header Generators
    public buildModalHeaderByText(modalBody: JQuery, headerText: string, dialogType?: DialogType): JQuery {

        const h4 = $("<h4>").addClass("modal-title").text(headerText);
        const exitButton = this.getExitButton(dialogType);

        modalBody.find("div.portlet-title").remove();

        return exitButton.add(h4);
    }

    public buildModalHeaderByServerData(modalBody: JQuery, dialogType?: DialogType): JQuery {

        // Move Form Header to Modal Header
        const portletTitle = modalBody.find("div.portlet-title");
        const h4 = $("<h4>").addClass("modal-title").text(portletTitle.find("span").text());
        portletTitle.remove();

        const exitButton = this.getExitButton(dialogType);

        return exitButton.add(h4);
    }

    /// Modal Body Generators
    public buildModalBodyByDomData(domData: JQuery): JQuery {

        let resultNodes = domData.closest(this.selectorSectionForm);
        if (resultNodes.length === 0) {
            resultNodes = domData.find(this.selectorSectionForm);
        }

        // remove buttons
        resultNodes.find("div.form-actions").remove();

        return resultNodes;
    }

    public buildModalBodyByText(text: string): JQuery {

        return $("<span>").text(text);
    }

    /// Modal Footer Generators
    public buildModalFooterByDialogType(dialogType: DialogType, overrideSuccessAction?: FormAction, modalConfig?: ModalConfig): JQuery {

        let successButton, closeButton;
        let successText, closeText;

        // only append close-button
        if (dialogType === DialogType.CloseOnly) {
            closeText = modalConfig?.overrideCloseButtonText ?? Locale.getTranslation("close");
            return $(`<a class="text- button btn default" data-dismiss="modal"><span>${closeText}</span></a>`);
        }

        const btnGroup = $("<div class=\"btn-group btn-group-devided\"></div>");

        
        switch (dialogType) {

            case DialogType.YesNo:

                successText = modalConfig?.overrideSuccessButtonText ?? Locale.getTranslation("yes");
                closeText = modalConfig?.overrideCloseButtonText ?? Locale.getTranslation("no");
                successButton = ButtonBuilder.buildSuccessButton({ action: FormAction.Success, text: successText });
                closeButton = ButtonBuilder.buildCloseButton({ text: closeText }, true);

                btnGroup.append(successButton, closeButton);

                break;

            case DialogType.SaveCancel:

                successText = modalConfig?.overrideSuccessButtonText ?? Locale.getTranslation("save");
                closeText = modalConfig?.overrideCloseButtonText ?? Locale.getTranslation("cancel");
                successButton = ButtonBuilder.buildSuccessButton({ action: overrideSuccessAction, text: successText, actionType: modalConfig.action });
                closeButton = ButtonBuilder.buildCloseButton({text: closeText}, true);

                btnGroup.append(successButton, closeButton);

                break;

            case DialogType.SaveCancelPrevNext:
            case DialogType.AddSaveCancelPrevNext:

                btnGroup.append(ButtonBuilder.buildRowButton("prev"));
                btnGroup.append(ButtonBuilder.buildRowButton("next"));

                if (dialogType === DialogType.AddSaveCancelPrevNext) {
                    btnGroup.append(ButtonBuilder.buildRowButton("add"));
                }

                successText = modalConfig?.overrideSuccessButtonText ?? Locale.getTranslation("save");
                closeText = modalConfig?.overrideCloseButtonText ?? Locale.getTranslation("refuse");
                successButton = ButtonBuilder.buildSuccessButton({ responsiveText: true, iconClass: "fa fa-floppy-o", action: overrideSuccessAction, text: successText, tooltipText: Locale.getTranslation("saveall full") });
                closeButton = ButtonBuilder.buildCloseButton({ responsiveText: true, iconClass: "fa fa-times", text: closeText, tooltipText: Locale.getTranslation("refuse full") }, false);
                btnGroup.append(successButton, closeButton);

                break;

        }

        return btnGroup;
    }

    public buildModalFooterByDomData(domData: JQuery): JQuery {

        let resultNodes = domData.closest(this.selectorSectionForm);
        if (resultNodes.length === 0) {
            resultNodes = domData.find(this.selectorSectionForm);
        }

        return resultNodes.find(".btn-group");
    }

    // Main Build Method
    public buildModal(modalBodyContent: JQuery, modalHeaderContent?: JQuery, modalFooterContent?: JQuery, largeModal?: boolean): JQuery {

        // check if the container already exists, then we will reuse this container
        let containerExists = false;
        if (this.rootPortlet != null) {
            this.modalContainer = this.rootPortlet.find("div.modal#" + this.modalId);
        }
        let modalDialog = $("<div>").addClass("modal-dialog");

        if (this.modalContainer == null || this.modalContainer.length === 0) {

            this.modalContainer = $("<div>").addClass("modal fade zoom")
                .attr("id", this.modalId)
                .attr("data-modal-type", this.modalType)
                .attr("tabindex", "-1");

            // modal (also for modal in modal) must be appended to root portlet (= form on main page (outside modals) which has triggered the modal)
            if (this.rootPortlet != null) {
                this.rootPortlet.append(this.modalContainer);
            } else {
                $("body").append(this.modalContainer);
            }

        } else {

            containerExists = true;
            this.modalContainer.off("hidden.bs.modal");
            this.modalContainer.off("shown.bs.modal");
            // reuse current Modal Dialog Div
            modalDialog = this.modalContainer.find("div.modal-dialog");
        }

        /// Build Modal Header, Body
        const modalContent = this.buildModalContent(modalHeaderContent, modalBodyContent, modalFooterContent, modalDialog, largeModal);

        if (!containerExists) {
            modalDialog.append(modalContent);

            const backdrop = this.getBackdrop(); // use own backdrop, to hide inactive modals when modals are stacked
            this.modalContainer.append(backdrop);
            this.modalContainer.append(modalDialog);
        } else {
            this.modalContainer.find("div.modal-content").replaceWith(modalContent);
        }

        return this.modalContainer;
    }

    private getBackdrop(): string {
        return '<div class="modal-backdrop fade in"></div>';
    }

    private buildModalContent(modalHeaderContent: JQuery, modalBodyContent: JQuery, modalFooterContent: JQuery, modalDialog: JQuery, largeModal?: boolean) {

        const modalContent = $("<div>").addClass("modal-content");

        // Add Optional Header
        if (modalHeaderContent != null) {
            const modalHeader = $("<div>").addClass("modal-header");
            modalContent.append(modalHeader);
            modalHeader.append(modalHeaderContent);
        }

        // Add Content to Body
        const modalBody = $("<div>").addClass("modal-body");
        modalContent.append(modalBody);
        modalBody.append(modalBodyContent);

        // Add Optional Footer
        if (modalFooterContent != null) {
            const modalFooter = $("<div>").addClass("modal-footer");
            modalContent.append(modalFooter);
            modalFooter.append(modalFooterContent);
        }

        // Set Modal Large when there is a datatable
        if (modalBody.find("table.dataTable").length > 0 || largeModal) {
            modalDialog.addClass("modal-lg");
        } else {
            modalDialog.removeClass("modal-lg");
        }

        return modalContent;
    }

    /// Helper Functions
    private getExitButton(dialogType?: DialogType): JQuery {

        const dismiss = dialogType !== DialogType.AddSaveCancelPrevNext && dialogType !== DialogType.SaveCancelPrevNext ? `data-dismiss="modal"` : "";

        return $(`<button type="button" class="close" ${dismiss} aria-label="Close"><span aria-hidden="true">&times;</span></button>`);
    }

}

import { ControlData } from "controls/util/controlData";
import { Control } from "./control";

// Has always a hidden input and a visible span, no user interaction possible
export class SaveableReadOnly extends Control {

    private readonly selectorHiddenInput = "input:hidden";

    public initialize(): void {}

    public clear(): void {

        if (!this.hasValue) { return; }

        super.clearStaticInputBasedElement(); // always handled like a static field, because it is always static

        this.runValueChangedCallbacks();
    }

    public enable(): void {

        return;

    }

    public disable(): void {

        return;
    }

    get isEnabled(): boolean {
        return false;
    }

    public validate(): void {

        return;

    }

    public focus(): void {

        return;
    }

    public getValues(): ControlDataInterface[] {

        return [this.getValue()];
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        }
        else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }

        this.runValueChangedCallbacks();
    }

    private getValue(): ControlData {

        const value = this.fieldContainer.find(this.selectorHiddenInput).val().toString();

        return new ControlData(value, value);

    }

    private setValue(controlData: ControlData): void {

        super.setStaticField(controlData.value, controlData.displayValue);

    }

}

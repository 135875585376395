import { CalculationTable } from "Business/calculation/calculationTable";
import { CacheLifeCycle, HttpClient } from "component/httpClient/httpClient";
import { Locale } from "component/localeManager/localeManager";
import { Select } from "controls/allControls";
import { Datepicker } from "controls/datepicker";
import { ControlData } from "controls/util/controlData";
import { Modules, PayTypeUsed, Region } from "enum/allEnums";
import { DetailForm } from "forms/detailForm";
import { Util } from '../../uiFramework/helper/util';


export class PaymentGroupHelper {

    private readonly module: Modules;
    private readonly detailForm: DetailForm;
    private paymentGroupError = false;
    private controls: PaymentGroupData;

    constructor(module: Modules, detailForm: DetailForm) {
        this.module = module;
        this.detailForm = detailForm;
        this.prepareControlData();
    }

    public async fillPayGradeRate(): Promise<void> {

        const payTypeUsed = parseInt(this.controls.payTypeUsed.getValues()[0].value) as PayTypeUsed;
        if ($.inArray(payTypeUsed, [PayTypeUsed.Ratecard, PayTypeUsed.None, PayTypeUsed.IndividualRegulation, PayTypeUsed.IndividualRegulationNoIncreases]) >= 0) {
            return Promise.resolve();
        } // Do nothing

        const paymentGroupRuleId = Util.safeGetFirstValue(this.controls.paymentGroupRule);
        const startDate = this.controls.startDate.getDate();

        if (!paymentGroupRuleId || !startDate) {
            this.resetFields();
            return Promise.resolve();
        }

        // Load Payment Groups from server by payment group rule id, date, and optional region
        // In Project we cant select a region
        let region = null;
        if (this.module !== Modules.Project && Util.safeGetFirstValue(this.controls.region))
            region = parseInt(Util.safeGetFirstValue(this.controls.region));
        const payGradeControl = (this.module === Modules.Project) ? this.controls.requiredSkill : this.controls.payGrade;

        if (!payGradeControl || payGradeControl.isStaticControl) { // when pay grade cant changed, we do not need to get rate. e.g. overview pages, rate is already set in DB
            return Promise.resolve();
        }

        const payGrade = Util.safeGetFirstValue(payGradeControl);
        if (!payGrade) {
            this.resetFields();
            return Promise.resolve();
        }

        $.blockUI();
        const paymentGroups = await this.getPaymentGroupsFromServer(paymentGroupRuleId, startDate, region, payGrade);
        $.unblockUI();

        // When no or more than 1 payment group found for selected region and pay grade, throw error
        if (paymentGroups === null || paymentGroups.length !== 1) {
            this.resetFields();
            this.throwPaymentGroupError(Locale.getTranslation("pay grade not found"));
            return Promise.resolve();
        } else {
            this.resetPaymentGroupError();
        }

        this.setPayGroupRate(paymentGroups[0]);

        this.fillFieldsForProject(paymentGroups[0]);

        return Promise.resolve();
    }

    public async fillPaymentGroupRuleId(awardId: string): Promise<void> {

        const url = "paymentGroup/getPaymentGroupRuleId";
        const params = new URLSearchParams();
        params.append("awardId", awardId);
        const pgr = await HttpClient.httpGet<{item1: string, item2: string}>(url, params, { cacheEnabled: true, cacheLifeCycle: CacheLifeCycle.Short });

        this.controls.paymentGroupRule.setValues(new ControlData(pgr.item1, pgr.item2));
    }


    public async fillPayGradeDropdown(): Promise<void> {

        const paymentGroupRuleId = this.controls.paymentGroupRule.getValues()[0].value;
        const startDate = this.controls.startDate.getDate();

        const dropdownControl = (this.module === Modules.Project) ? this.controls.requiredSkill : this.controls.payGrade;

        if (dropdownControl.isStaticControl)
            return;

        if (!paymentGroupRuleId || !startDate || !Util.isValidDate(startDate)) {
            dropdownControl.clear();
            dropdownControl.resetOptions();
            return;
        }

        $.blockUI();
        const paymentGroups = await this.getPaymentGroupsFromServer(paymentGroupRuleId, startDate);
        $.unblockUI();

        if (paymentGroups === null || paymentGroups.length < 1) {
            dropdownControl.clear();
            dropdownControl.resetOptions();
            return;
        }

        // Fill required skill dropdown with options
        const entries = new Array<ControlData>();
        for (const pg of paymentGroups) {

            let controlData;
            if (this.module === Modules.Project) {
                controlData = new ControlData(pg.payGrade, pg.name);
            } else {
                controlData = new ControlData(pg.payGrade, pg.payGrade);
            }

            entries.push(controlData);
        }

        dropdownControl.clear();
        dropdownControl.resetOptions();
        dropdownControl.addOptions(entries);
    }

    private prepareControlData(): void {

        if (this.module === Modules.Project) {

            this.controls = {
                payGrade: this.detailForm.getControl("pay_grade_new") as Select,
                payGradeRate: this.detailForm.getControl("pay_group_rate"),
                region: this.detailForm.getControl("region_id"),
                payTypeUsed: this.detailForm.getControl("pay_type_used"),
                requiredSkill: this.detailForm.getControl("required_skill") as Select,
                startDate: this.detailForm.getControl("start_date") as Datepicker,
                paymentGroupRule: this.detailForm.getControl("payment_group_rule_id"),
            };
        } else if (this.module === Modules.Bid) {

            this.controls = {
                payGrade: this.detailForm.getControl("bid_pay_group") as Select,
                payGradeRate: this.detailForm.getControl("bid_pay_group_rate"),
                region: this.detailForm.getControl("bid_region_id"),
                payTypeUsed: this.detailForm.getControl("bid_pay_type_used"),
                startDate: this.detailForm.getControl("start_date") as Datepicker,
                paymentGroupRule: this.detailForm.getControl("bid_payment_group_rule_id"),
            };
        } else if (this.module === Modules.Award) {
            // not needed
            throw new Error("Method not implemented.");
        } else if (this.module === Modules.ChangeRequest) {

            this.controls = {
                payGrade: this.detailForm.getControl("pay_grade_new") as Select,
                payGradeRate: this.detailForm.getControl("pay_group_rate"),
                region: this.detailForm.getControl("award_region_id"),
                payTypeUsed: this.detailForm.getControl("pay_type_used"),
                startDate: this.detailForm.getControl("start_date") as Datepicker,
                paymentGroupRule: this.detailForm.getControl("payment_group_rule_id"),
            };
        }
    }

    private fillFieldsForProject(paymentGroup: MasterXPaymentGroupEntity): void {
        if (this.module !== Modules.Project) { return; }

        // Set Pay Group to Pay Group Field
        Util.safeSetValues(this.controls.payGrade, new ControlData(paymentGroup.payGrade, paymentGroup.payGrade));

        // Set Region Field (in Project region is not editable)
        const regionText = (paymentGroup.regionId === Region.East) ? Locale.getTranslation("east") : Locale.getTranslation("west");
        Util.safeSetValues(this.controls.region, new ControlData(paymentGroup.regionId.toString(), regionText));

        // Add Tooltip with description of Payment Group
        if (this.controls.requiredSkill) {
            const description = (paymentGroup.description === null) ? Locale.getTranslation("no description") : paymentGroup.description;
            this.controls.requiredSkill.addLabelTooltip(description);
        }
    }

    private setPayGroupRate(paymentGroup: MasterXPaymentGroupEntity): void {
        const value = Locale.formatCurrency(paymentGroup.rate);
        this.controls.payGradeRate.setValues(new ControlData(value, value));
    }

    private async getPaymentGroupsFromServer(paymentGroupRuleId: string, effectiveDate: Date, regionId?: number, payGrade?: string): Promise<MasterXPaymentGroupEntity[]> {
        let pgs: MasterXPaymentGroupEntity[];

        // default region is west
        let url = `paymentgroup/${paymentGroupRuleId}?effectiveDate=${effectiveDate.toISOString()}`;
        if (regionId != null) {
            url += `&regionId=${regionId}`;
        }

        pgs = await HttpClient.httpGet<MasterXPaymentGroupEntity[]>(url, null, { cacheEnabled: true, cacheLifeCycle: CacheLifeCycle.Short });

        if (payGrade) {
            pgs = pgs.filter((pg) => pg.payGrade === payGrade);
        }

        return pgs;
    }

    private resetFields(): void {

        Util.safeClearControls(this.controls.payGrade, this.controls.payGradeRate);        

        if (this.module === Modules.Project) {
            Util.safeClearControls(this.controls.region);
        }

    }

    private throwPaymentGroupError(message: string) {
        this.paymentGroupError = true;
        CalculationTable.getInstance().paymentGroupError = true;
        CalculationTable.getInstance().replaceCalculationTableWithMessage(message);
    }

    private resetPaymentGroupError() {
        if (this.paymentGroupError) {
            this.paymentGroupError = false;
            CalculationTable.getInstance().paymentGroupError = false;
            CalculationTable.getInstance().showCalculationTable(true);
        }
    }

}

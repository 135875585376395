import { ControlData } from "controls/util/controlData";
import { Control } from "./control";

export class HiddenField extends Control {

    get isEnabled(): boolean {
        return false;
    }

    public initialize(): void { }

    public clear(): void {

        if (!this.hasValue) { return; }

        this.fieldContainer.find("input:hidden").val("");
        this.runValueChangedCallbacks();
    }

    public enable(): void {

        return;
    }

    public disable(): void {

        return;

    }

    public validate(): void {

        return;

    }

    public focus(): void {

        return;
    }

    public getValues(): ControlDataInterface[] {

        return [this.getValue()];
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        }
        else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }

        this.runValueChangedCallbacks();
    }

    public getDate(): Date {

        const control = this.fieldContainer.find("input");
        if (!control) {
            throw new Error(`HiddenField(${this.fieldName}): Input Element not found`);
        }

        const isoDate = control[0].dataset.isoDate;

        if (!isoDate) {
            return null;
        }

        return new Date(isoDate);
    }

    private getValue(): ControlDataInterface {

        const control = this.fieldContainer.find("input");
        if (!control) {
            throw new Error(`HiddenField(${this.fieldName}): Input Element not found`);
        }

        const value = control.val() as string;

        return new ControlData(value);

    }

    private setValue(controlData: ControlDataInterface): void {

        this.fieldContainer.find("input").val(controlData.value);
    }

}


import { HttpClient } from "component/httpClient/httpClient";
import { Locale } from "component/localeManager/localeManager";
import { getSelect2Configuration, getSelect2MultipleConfiguration } from "controls/util/select2Configuration";
import { SetOptions, Tooltips } from "eonasdan-bootstrap-datetimepicker";
import { DetailForm } from "forms/detailForm";
import { ChartjsHelper } from "helper/chartjshelper";
import { Util } from "helper/util";
import { utc } from "moment";
import { ControlTypes } from "uiFramework/enum/allEnums";
import { PageHelper } from "./pageHelper";

export class ControlHelper {

    public static getSingleNumberOrDefault(form: DetailForm, fieldName: string, defaultValue: number | null): number | null {

        const control = form.getControl(fieldName);
        if (control && control.hasValue) {
            return Locale.parseNumber(control.getValues()[0].value);
        } else {
            return defaultValue;
        }
    }

    public static getSingleNumberOrDefaultByControl(control: ControlInterface, defaultValue: number | null): number | null {

        if (control && control.hasValue) {
            return Locale.parseNumber(control.getValues()[0].value);
        } else {
            return defaultValue;
        }
    }

    public static getSingleDateOrDefault(form: DetailForm, fieldName: string, defaultvalue: Date | null): Date | null {

        const control = form.getControl(fieldName);
        if (control && control.hasValue) {
            if (control.type === ControlTypes.HiddenField || control.type === ControlTypes.Datepicker) {
                return (control as Datepicker | HiddenField).getDate();
            } else {
                const isoDate = control.getDataAttribute("data-iso-Date");
                if (isoDate) {
                    return new Date(isoDate);
                }
            }
        }
        return defaultvalue;
    }

    public static applyParentControlListener(fieldContainer: JQuery, control: ControlInterface): void {

        const buttonParentControl = $("button[data-parent-control]", fieldContainer);
        if (buttonParentControl.length === 0) { return; }

        const parentFieldName = buttonParentControl.attr("data-parent-control");
        const form = fieldContainer.closest("div.section-form");
        const parentControl = (control.form as DetailForm).getControl(parentFieldName);

        if (!parentControl) {
            throw new Error(`Control ${parentFieldName} not found in Form`);
        }

        // save value of conttrol before clear to inform other child controls
        const currentControlValue = control.getValues();

        const parentControlrInputGroup = form.find(`[name=${parentFieldName}]`).closest("div.fieldcontainer");
        // Bind events on the parent node to inform the child that they have to delete their value
        parentControlrInputGroup.on("notifyChildControls", (event: JQuery.Event, parentControlPreviousValue: ControlDataInterface | ControlDataInterface[]) => {
           
            // When there is a change in the parent control, clear current control
            if (parentControl.type === ControlTypes.Lookup
                && !(parentControlPreviousValue instanceof Array)
                && control.hasValue) {

                const newValue = parentControl.getValues()[0].value;

                if (newValue !== parentControlPreviousValue.value) {
                    control.clear();
                    fieldContainer.trigger("notifyChildControls", [currentControlValue]);
                }

            } else if (parentControl.type === ControlTypes.LookupMultiple
                && parentControlPreviousValue instanceof Array
                && control.hasValue) {

                control.clear();
                fieldContainer.trigger("notifyChildControls", [currentControlValue]);

            } else if (parentControl.type === ControlTypes.Select
            && control.hasValue) {

            control.clear();
            fieldContainer.trigger("notifyChildControls", [currentControlValue]);
        }
        });

        // For Lookup Multipile, we need to notify children when an option was unselected/removed
        if (parentControl.type === ControlTypes.LookupMultiple) {
            const parentGuiControl = ControlHelper.getGUIControl(parentControlrInputGroup);

            parentGuiControl.on("select2:unselect", () => {

                control.clear();

                // notify recursive until the last child hears the bang
                fieldContainer.trigger("notifyChildControls", [currentControlValue]);
            });
        }

    }

    // Modify URL for Parent Control Feature
    public static getUrlWithParametersFromParentControl(url: string, parentFieldNames: string[], form: DetailForm): string {

        if (parentFieldNames == null || parentFieldNames.length === 0) { return url; }

        // Iterate over all parent control fields and check if they are filled
        const messages: string[] = [];

        for (const parentFieldName of parentFieldNames) {

            const parentControl = form.getControl(parentFieldName);

            if (!parentControl) { continue; }

            const parentValue = parentControl.getValues().map((x) => x.value).join(";");
            if (parentValue == null || parentValue.length === 0) {

                // We have identified a missing depency, throw alert and return empty url
                let parentDisplayName = parentControl.displayName;
                if (parentDisplayName == null) { parentDisplayName = parentFieldName; }

                messages.push(parentDisplayName + Locale.getTranslation("is_required"));
            }

            url = HttpClient.addURLParameter(url, parentFieldName, parentValue);
        }

        if (messages.length > 0) {

            form.showInlineMessage(messages, "error");

            return "";
        }

        return url;
    }

    public static applyTextEditor(context?: JQuery): void {
        const lang = Locale.getCurrentLanguage().substr(0, 2);
        const svgPath = "./dist/trumbowygIcons.svg";
        let tagsToKeep= ["p", "br", "ul", "li", "b", "strong", "i"];
        let btns = [["undo", "redo"], ["formatting"], ["strong", "em", "underline", "del"], ["unorderedList", "orderedList"], ["removeformat"], ["fullscreen"]];

        $(".text-editor:not(.link-enabled)", context).trumbowyg({ lang, svgPath, btns, tagsToKeep });

        // initate editors with links enabled
        tagsToKeep = ["p", "br", "ul", "li", "b", "strong", "i", "a"];
        btns = [["undo", "redo"], ["formatting"], ["strong", "em", "underline", "del"], ["unorderedList", "orderedList"], ["removeformat"], ['link'], ["fullscreen"]];
        const minimalLinks = true;
        const defaultLinkTarget = "_blank"
        $(".text-editor.link-enabled", context).trumbowyg({ lang, svgPath, btns, tagsToKeep, minimalLinks, defaultLinkTarget});

        $("button", context).addClass("has-tooltip");

    }

    public static applySelect2(context?: JQuery): void {

        $("select:not([multiple])", context).each(function() {
            const selectElement = $(this);

            selectElement.select2(getSelect2Configuration())
                .on("select2:select", function() {
                    $(this).valid();

                }).on("select2:close", function() {
                    $(this).valid();
                });

        });
    }

    public static applySelect2Multiple(context?: JQuery): void {

        $("select[multiple]", context).each((i, el) => {

            // For dropdowns add a please choose text
            const selectElement = $(el);
            const select2Options = getSelect2MultipleConfiguration(selectElement);

            selectElement.select2(select2Options)
                .on("select2:select", function(evt: any) {

                    // make sure to add new elements always at the end
                    const element = evt.params.data.element;
                    const $element = $(element);

                    $element.detach();
                    $(this).append($element);
                    $(this).trigger("change");
                })
                .on("change", function() {
                    selectElement.valid();

                }).on("select2:opening", function(event: Event) {
                    // hide dropdown on multiple select with lookup button
                    if (!selectElement.hasClass("select2-dropdown")) {
                        event.preventDefault();
                    }

                }).on("select2:close", function() {
                    selectElement.valid();
                });

            this.applySortable(selectElement);
        });
    }

    public static applyDateTimePicker(context?: JQuery): void {

        $(".date-time-picker", context).each((i: number, element: HTMLElement) => {

            const inputGroups = $(".input-group", element);

            if (inputGroups.length === 0) {
                // Init datetimepicker in this input-group
                ControlHelper.initDateTimePickerElement(element, element);
            } else {
                // check for child-inputgroups and loop through them for daterange-picker (2 child-inputgroups)
                inputGroups.each((ii: number, inputGroup: HTMLElement) => {
                    ControlHelper.initDateTimePickerElement(element, inputGroup);
                });
            }

        });
    }

    public static applyTouchspin(context?: JQuery): void {
        $("input[type='number']", context).each(function() {

            // check if there is another element at this input, f.e. a button for lookups
            if ($(this).next().length > 0) {
                return;
            }

            $(this).TouchSpin({
                verticalbuttons: true,
                mousewheel: false,
                max: 2147483647,
            }).on("change", function(e) {
                // trigger required field validation
                $(this).valid();
            });

            // add class to set width - not for range picker
            const fieldcontainer = $(this).closest(".fieldcontainer");
            if (!fieldcontainer.is(`[data-control-type='${ControlTypes.RangePicker}']`)) {
                const inputGroup = $(this).closest(".input-group");
                inputGroup.addClass("input-picker");
            }

            // for required input, add extra div with icon
            if ($(this).is("[required]")) {
                $(this).wrap("<div class=\"input-icon right\">");
                $(this).parent().prepend("<i class=\"fa\">");
            }
        });
    }

    public static applyBootstrapSwitch(context?: JQuery): void {

        $("input.switch[type='checkbox']", context).each(function() {

            let valTrue = "<i class='fa fa-check'></i>";
            let valFalse = "<i class='fa fa-times'></i>";

            if ($(this).attr("pickkeyvalues")) {
                const pickkeyvalues = $(this).attr("pickkeyvalues").split(";");

                if (pickkeyvalues.length > 0) {
                    valTrue = pickkeyvalues[0];
                    valFalse = pickkeyvalues[1];
                }
            }

            $(this).bootstrapSwitch({
                onText: valTrue,
                offText: valFalse,
            });
        });
    }

    public static disableMouseScrollOnNumberInputs(context?: JQuery): void {
        $("form", context).on("focus", "input[type=number]", function(e) {
            $(this).on("mousewheel.disableScroll", function(e) {
                e.preventDefault();
            });
        });
    }

    public static applyChartJs(context?: JQuery): void {

        const charts = $(".chart", context);

        if (charts.length === 0) { return; }

        import(/* webpackChunkName: "chartjs" */ "chart.js").then((Chart) => {

            charts.each(function(index: number, elem: Element) {

                if (!(elem instanceof HTMLCanvasElement)) {
                    return;
                }

                // default
                let chartType = "bar";

                if ($(this).data("type")) {
                    chartType = $(this).data("type");
                }

                let showDataLabels = false;

                if ($(this).data("showdatalabels")) {
                    showDataLabels = $(this).data("showdatalabels");
                }

                let showGridLines = false;

                if ($(this).data("showgridlines")) {
                    showGridLines = $(this).data("showgridlines");
                }

                const json = jQuery.parseJSON(elem.getAttribute("data"));

                if (!json) {
                    return;
                }

                let labels: string[] = [];
                const chartData: string[][] = [];
                const values: number[][] = [];
                const chartLabels: string[] = [];

                const totalValues = ChartjsHelper.collectValuesAndLabelsAndGetTotalValue(json, chartData, labels, values, chartType, chartLabels);

                const titleText = ChartjsHelper.getTitleText($(this).data("title"), totalValues, chartType);

                const ctx = (elem as HTMLCanvasElement).getContext("2d");

                if (chartType === "pie") {
                    // initial height
                    elem.height = 300;

                    if (!showDataLabels) {
                        labels = [];
                    }
                }

                const chartConfiguration = ChartjsHelper.getChartConfiguration(chartType, labels, chartLabels, totalValues, titleText, values, showGridLines);

                new Chart.Chart(ctx, chartConfiguration);
            });

        });

    }

    public static applyShorten(context: JQuery) {

        /* Look for any HTML elements with an "read more" attribute and call the readmore() function */
        $("span[readmore]", context).each(function() {
            const moreLinkText = '<i class="fa fa-angle-double-down" aria-hidden="true" style="font-weight:bold;margin-left:5px;"></i>';
            const lessLinkText = '<i class="fa fa-angle-double-up" aria-hidden="true" style="font-weight:bold;margin-left:5px;"></i>';
            if (typeof $(this).attr("readmore") !== typeof undefined && $(this).attr("readmore").length > 0) {
                $(this).shorten({ showChars: parseInt($(this).attr("readmore")), moreText: moreLinkText, lessText: lessLinkText });
            } else {
                $(this).shorten({ showChars: 2000, moreText: moreLinkText, lessText: lessLinkText });
            }
        });
    }

    public static applyFilestyle(context?: JQuery) {
        $(":file", context).each(function(index: number, fileInputElement: HTMLInputElement) {
            $(fileInputElement).filestyle({
                onChange: (files: FileList) => {
                    if (files.length === 0) {
                        $(":text", $(fileInputElement.parentElement)).val($(fileInputElement).data("value"));
                    }
                },
                placeholder: $(fileInputElement).data("placeholder"),
                btnClass: $(fileInputElement).data("buttonclass"),
                text: $(fileInputElement).data("buttontext"),
                htmlIcon: `<span class="${$(fileInputElement).data("iconclass")}"></span>`,
            });

            // move the required tag from the file-input to text-input (empty fileinputs arent wrong if the file was uploaded before)
            if ($(fileInputElement).data("required") === true) {
                $(":text", $(fileInputElement.parentElement)).prop("required", true);
            }
            // fill text-input value with the filename (file which was uploaded before)
            if ($(fileInputElement).data("value") != null) {
                $(":text", $(fileInputElement.parentElement)).val($(fileInputElement).data("value"));
            }

        });
    }

    public static buildDateDisplayValueFromValue(controlData: ControlDataInterface, format: string): string {

        let displayValue = controlData.displayValue;

        if (!displayValue && controlData.value) {
            const date = new Date(controlData.value); // value contains iso date
            displayValue = utc(date).format(format);
        }

        return displayValue;
    }

    public static InitFieldButton(fieldName: string, callback: Function) {

        const forms: FormInterface[] = PageHelper.getPage().getForms();
        if (forms == null) { return; }

        for (const form of forms) {
            const btn = form.getFormContainer().find(`[data-field-name="${fieldName}"]`).find("a.field-btn");

            btn.off("click").on("click", (event: JQuery.Event) => {

                event.preventDefault();

                callback();
            });
        }
    }

    private static readonly selectorOptionsSelected = "option:selected";

    // Deprecated do not use. Use UI Framework instead
    private static getGUIControl(inputGroup: JQuery): JQuery {

        let control = inputGroup.find("select");
        if (control.length > 0) { return control; }

        control = inputGroup.find("input:visible");
        return control;

    }

    private static applySortable(selectElement: JQuery): void {

        const formGroup = selectElement.closest(".form-group");
        const select = formGroup.find("select");
        if (select.hasClass("sortable")) {

            const selectUl = formGroup.find("ul");

            selectUl.sortable();
        }
    }

    private static initDateTimePickerElement(element: HTMLElement, inputGroup: HTMLElement) {
        // only the first 2 letters are required because of momentJS-format
        let locale = Locale.getCurrentLanguage();
        if (locale.length > 2) {
            locale = locale.substring(0, 2);
        }

        const { isDateOnlyPicker, config } = ControlHelper.getDatepickerconfig(locale, element, inputGroup);

        // Write iso date to isoDate attribute when value has changed
        $(inputGroup).datetimepicker(config).on("dp.change", function() {

            const dpInput = inputGroup.querySelector("input");
            let isoDate = $(inputGroup).data("DateTimePicker").date();
            if (isoDate != null) {
                if (isDateOnlyPicker) {
                    // first: reset time component to 00:00 becuase of datepicker bug, where it uses current time on date selection when field is empty
                    // second: add utc offset to selected date to get UTC, because selected date is in local time.
                    isoDate = isoDate.startOf("date");
                    isoDate = isoDate.add(isoDate.utcOffset(), "minutes");
                }

                dpInput.dataset.isoDate = isoDate.toISOString();
            } else {
                dpInput.dataset.isoDate = "";
            }
        });
    }

    private static getDateFormat(locale: string, element: HTMLElement): string {

        let dateFormat: string = element.dataset.dateFormat;
        // default format
        if (dateFormat == null || dateFormat.length === 0) {
            if (locale.toLowerCase() === "de") {
                dateFormat = "DD.MM.YYYY";
            } else {
                dateFormat = "DD-MMM-YYYY";
            }
        }
        return dateFormat;
    }

    private static getMinuteIncrement(element: HTMLElement): number {

        let minutesIncrement = parseInt(element.dataset.minutesincrement);
        if (minutesIncrement == null) {
            minutesIncrement = 1;
        }

        return minutesIncrement;
    }

    private static getDatepickerconfig(locale: string, element: HTMLElement, inputGroup: HTMLElement): { isDateOnlyPicker: boolean, config: SetOptions } {

        const dpInput = inputGroup.querySelector("input");
        const dateFormat = ControlHelper.getDateFormat(locale, element);
        const dtConfig: SetOptions = {
            locale,
            showTodayButton: element.dataset.showTodayButton === "true",
            format: dateFormat,
            stepping: ControlHelper.getMinuteIncrement(element),
            defaultDate: dpInput.dataset.isoDate,
            icons: {
                today: "fa fa-calendar-check-o",
            },
            tooltips: ControlHelper.getGermanTooltips(locale),
            useCurrent: false,
            keyBinds: {
                pageUp: null, pageDown: null,
            }, // stop catching this keys by datetimepicker
        };

        if (dpInput != null && dpInput.dataset.minValue != null) {
            const tempMinDate = new Date(dpInput.dataset.minValue);
            if (Util.isValidDate(tempMinDate)) {
                dtConfig.minDate = new Date(dpInput.dataset.minValue);
            }
        }
        if (dpInput != null && dpInput.dataset.maxValue != null) {
            const tempMaxDate = new Date(dpInput.dataset.maxValue);
            if (Util.isValidDate(tempMaxDate)) {
                dtConfig.maxDate = new Date(dpInput.dataset.maxValue);
            }
        }

        // set the datepicker popup to min or max date when current date is outside the bounds
        // otherwise user will only see grayed out dates
        const currentDate = new Date();
        if (currentDate > dtConfig.maxDate) {
            dtConfig.viewDate = dtConfig.maxDate;
        }
        else if (currentDate < dtConfig.minDate) {
            dtConfig.viewDate = dtConfig.minDate;
        }
        const isUtc = dpInput.closest('.date-time-picker').getAttribute('is-utc');
        if (isUtc && isUtc === '1') {
            dtConfig.timeZone = "UTC";
        } else {
            const timeZone = dpInput.closest('.date-time-picker').getAttribute('data-date-timezone');
            if (!timeZone || timeZone.length === 0) {
                dtConfig.timeZone = "UTC"; // work with UTC, no local Timezone conversion
            } else {
                dtConfig.timeZone = timeZone;
            }
        }

        const isDateOnlyPicker = dateFormat.indexOf("H") === -1; // check if format mask contains Hours
        return { isDateOnlyPicker, config: dtConfig };
    }

    private static getGermanTooltips(locale: string): Tooltips {
        let tooltips: Tooltips;
        if (locale === "de") {
            tooltips = {
                today: Locale.getTranslation("go_today"),
                clear: Locale.getTranslation("clear_selection"),
                close: Locale.getTranslation("close_picker"),
                selectMonth: Locale.getTranslation("select_month"),
                prevMonth: Locale.getTranslation("previous_month"),
                nextMonth: Locale.getTranslation("next_month"),
                selectYear: Locale.getTranslation("select_year"),
                prevYear: Locale.getTranslation("previous_year"),
                nextYear: Locale.getTranslation("next_year"),
                selectDecade: Locale.getTranslation("select_decade"),
                prevDecade: Locale.getTranslation("previous_decade"),
                nextDecade: Locale.getTranslation("next_decade"),
                prevCentury: Locale.getTranslation("previous_century"),
                nextCentury: Locale.getTranslation("next_century"),
                incrementHour: Locale.getTranslation("increment_hour"),
                pickHour: Locale.getTranslation("pick_hour"),
                decrementHour: Locale.getTranslation("decrement_hour"),
                incrementMinute: Locale.getTranslation("increment_minute"),
                pickMinute: Locale.getTranslation("pick_minute"),
                decrementMinute: Locale.getTranslation("decrement_minute"),
                incrementSecond: Locale.getTranslation("increment_Second"),
                pickSecond: Locale.getTranslation("pick_second"),
                decrementSecond: Locale.getTranslation("decrement_second"),
                selectTime: Locale.getTranslation("select_time"),
                togglePeriod: Locale.getTranslation("toggle_period"),
            };
        }
        return tooltips;
    }

}



export enum KeyCodes  {
    
    Backspace = 8,
    Tab = 9,
    Enter = 13,
    Escape = 27,
    PageUp = 33,
    PageDown = 34
}





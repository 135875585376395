import { ControlData } from "controls/util/controlData";
import { Duration } from "js-joda";
import { debounce, isArray, isString, startsWith } from "lodash";
import { Control, ControlCallbackFunction } from "./control";

export class DurationPicker extends Control {

    get hasValue(): boolean {
        const data = this.getValue();
        return data.value !== "" && data.value !== this.emptyValue;
    }

    private readonly emptyValue = "PT0H0M";

    public initialize(): void {

        const durationData: string = this.fieldContainer.data("duration");
        if (this.isParsableDuration(durationData)) {
            this.setValue(new ControlData(durationData));
        }

    }

    public getValues(): ControlDataInterface[] {
        return this.addEmptyControlDataWhenEmpty([this.getValue()]);
    }

    public clear(): void {

        if (!this.hasValue) { return; }

        if (this.isStaticControl) {
            this.getStaticInput().val("");
        } else {
            this.getHoursInput().val("");
            this.getMinutesInput().val("");
        }

        this.runValueChangedCallbacks();
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (Array.isArray(controlData)) {
            if (controlData.length > 0) {
                this.setValue(controlData[0]);
            }
        } else {
            this.setValue(controlData);
        }

        this.runValueChangedCallbacks();
    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        super.bindOnValueChanged(callback);

        this.fieldContainer.find("input").on("keyup", debounce(() => callback(), 200));
        this.fieldContainer.find("button").on("click", debounce(() => callback(), 200));

    }

    private getMinutesInput(): JQuery {
        return this.fieldContainer.find("input[name='minutes']");
    }

    private getHoursInput(): JQuery {
        return this.fieldContainer.find("input[name='hours']");
    }

    private getStaticInput(): JQuery {
        return this.fieldContainer.find("input");
    }

    private getValue(): ControlDataInterface {

        if (this.isStaticControl) {
            const val = this.defaultIfEmpty(this.getStaticInput().val(), this.emptyValue);
            return new ControlData(val);
        } else {
            const hours = this.defaultIfEmpty(this.getHoursInput().val(), 0);
            const minutes = this.defaultIfEmpty(this.getMinutesInput().val(), 0);
            return new ControlData(`PT${hours}H${minutes}M`);
        }

    }

    private setValue(controlData: ControlDataInterface): void {
        const duration = Duration.parse(controlData.value);
        const hours = duration.toHours();
        const minutes = duration.minusHours(hours).toMinutes();
        if (hours + minutes > 0) {
            if (this.isStaticControl) {
                this.getStaticInput().val(controlData.value);
            } else {
                this.getHoursInput().val(hours);
                this.getMinutesInput().val(minutes);
            }
        }
    }

    private isParsableDuration(duration?: string): boolean {
        return duration != null
            && duration.length > 0
            && !startsWith(duration, "PTH");
    }

    private defaultIfEmpty(value: string | number | string[], defaultValue: any): any {
        if (value == null) { return defaultValue; }
        if ((isArray(value) || isString(value)) && value.length === 0) { return defaultValue; }
        return value;
    }
}

import { ControlData } from "controls/util/controlData";
import { Control } from "./control";

export class Container extends Control {

    public initialize(): void { }

    public clear(): void {

        if (!this.hasValue) { return; }

        this.fieldContainer.find(".generic-container").data("field-value", "");

        this.runValueChangedCallbacks();

    }

    public enable(): void {

        return;

    }

    public disable(): void {

        return;
    }

    get isEnabled(): boolean {
        return false;
    }

    public validate(): void {

        return;

    }

    public focus(): void {

        return;
    }

    public getValues(): ControlDataInterface[] {

        let value = this.fieldContainer.find(".generic-container").data("field-value");
        if (typeof value === "object") {
            value = JSON.stringify(value);
        }

        return [new ControlData(value, value)];
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        }
        else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }
    }

    private setValue(controlData: ControlDataInterface): void {

        this.fieldContainer.find(".generic-container").data("field-value", controlData.value);

        this.runValueChangedCallbacks();
    }

}

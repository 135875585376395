import { Locale } from "component/localeManager/localeManager";
import { Log } from "component/logging/logging";
import { FormAction, ModalType } from "enum/allEnums";
import { FormFactory } from "factory/formFactory";
import { FormHelper } from "helper/formHelper";
import { PageHelper } from "helper/pageHelper";
import { DialogType } from "modals/enum/dialogType";
import { ModalBuilder } from "modals/modalBuilder";
import { ModalOptions } from 'bootstrap';

export class Modal implements ModalInterface {

    public form: FormInterface; // the form in the current modal (if existing)
    public readonly parentForm: FormInterface; // parent (e.g. a parent modal or the normal page)
    public readonly rootForm: FormInterface; // root is the normal page (no modal)

    public readonly modalType: ModalType;
    public dialogType: DialogType;

    private readonly modalId: string;
    private modalContainer: JQuery;
    private isVisible: boolean;

    private readonly defaultConfig: ModalOptions = {
        backdrop: "static",
        show: true,
        keyboard: false,
    };

    /**
     * Constructor for new Modal
     * @param modalType Specify modaltype. Single or Multiple. Multiple when modal can return multiple values
     * @param modalId A unique modal id for the current portlet/form
     * @param parentForm parent form of the modal
     */
    constructor(modalType: ModalType, modalId: string, parentForm?: FormInterface) {

        this.modalId = modalId;
        this.modalType = modalType;

        if (parentForm != null) {

            this.parentForm = parentForm;

            this.rootForm = parentForm.getModal() != null ? parentForm.getModal().rootForm : parentForm;

            parentForm.setChildModal(this);

        }
        this.isVisible = false;
    }

    /// Public Methods

    /**
     * Open a Modal by showing the given page
     * @param pageName *.app with url parameters
     * @param formCallback
     * @param modalConfig pass optional Modal Configuration
     */
    public openModalByURL(pageName: string, formCallbacks?: FormCallback | FormCallback[], modalConfig?: ModalConfig): void {

        this.blockParentModalOrPage();

        PageHelper.loadPageDOMFromServer(pageName, this.modalType)
            .then((domData: JQuery) => this.renderModalFromDomData(domData, formCallbacks, modalConfig))
            .catch((err) => this.handleModalError(err));
    }

    /**
     * Open Modal By showing a given form
     * @param formConfig
     * @param formCallback
     * @param modalConfig pass optional Modal Configuration
     */
    public openModalByForm(formConfig: FormConfiguration, formCallbacks?: FormCallback | FormCallback[], modalConfig?: ModalConfig): void {

        this.blockParentModalOrPage();

        formConfig.modalType = this.modalType;

        FormHelper.loadFormDOMFromServer(formConfig)
            .then((domData: JQuery) => this.renderModalFromDomData(domData, formCallbacks, modalConfig))
            .catch((err) => this.handleModalError(err));
    }

    /**
     * Open a Modal and show the passed DOM Data
     * @param domData
     * @param callback
     * @param modalConfig pass optional Modal Configuration
     */
    public openModalByDOMData(domData: JQuery, formCallbacks?: FormCallback | FormCallback[], modalConfig?: ModalConfig): void {

        // Build Modal
        this.buildModal(domData, modalConfig);

        // Need to initialize modal form
        this.initPortlet(formCallbacks);

        // Show Modal on last step, to make sure everything is done before user sees anything
        this.modalContainer.modal(this.defaultConfig);

    }

    /**
     * Simple Modal Dialog with 2 Buttons and a custom text
     * @param dialogHeaderText
     * @param dialogText
     * @param modalDialogType
     * @param modalConfig
     */
    public openModalDialog(dialogHeaderText: string, dialogText: string, modalDialogType: DialogType, modalConfig?: ModalConfig): void {

        const container = this.rootForm?.getFormContainer() ?? $("#wrapper");
        const modalBuilder = new ModalBuilder(container, this.modalId, this.modalType);
        this.dialogType = modalDialogType;

        const body = modalBuilder.buildModalBodyByText(dialogText);
        const footer = modalBuilder.buildModalFooterByDialogType(modalDialogType, null, modalConfig);
        const header = modalBuilder.buildModalHeaderByText(body, dialogHeaderText, modalDialogType);
        this.modalContainer = modalBuilder.buildModal(body, header, footer);

        this.bindDisplayEventToProperty(modalConfig);

        this.bindSuccessButtonAndFocus(modalConfig);

        this.modalContainer.modal(this.defaultConfig);

    }

    private bindSuccessButtonAndFocus(modalConfig: ModalConfig) {

        if (modalConfig?.overrideSuccessCallback) {

            const successButton = $(`a.text-button[data-form-action=${FormAction.Success}]`, this.modalContainer) as JQuery<HTMLAnchorElement>;
            if (successButton.length > 0) {

                this.focusButton(successButton);

                if (modalConfig?.overrideSuccessCallback) {
                    successButton.click((e) => {
                        e.preventDefault();
                        modalConfig.overrideSuccessCallback();
                    });
                }
            }
        }
    }

    public closeModal(): void {
        this.modalContainer.modal("hide");
    }

    public getModalDialogContainer(): JQuery {

        if (this.modalContainer == null) { return null; }

        return this.modalContainer.find("div.modal-dialog");
    }

    public isActiveModal(): boolean {
        if (this.form == null) {

            if (this.isVisible)
                return true;

            return false;
        }
        return this.form.getChildModal() == null; // in case we have stacked modals, this returns whether the modal is the one in the front
    }

    /// End Public Methods

    /// Private Methods

    private buildModal(domData: JQuery, modalConfig: ModalConfig): void {

        const rootFormContainer = this.rootForm ? this.rootForm.getFormContainer() : null;
        const modalBuilder = new ModalBuilder(rootFormContainer, this.modalId, this.modalType);
        if (typeof (domData) === "string") {
            domData = $(domData);
        }

        // need to build footer first, because body is removing buttons
        let modalFooter;
        if (modalConfig && modalConfig.dialogType) {
            modalFooter = modalBuilder.buildModalFooterByDialogType(modalConfig.dialogType, modalConfig.successAction, modalConfig);
            this.bindSuccessButtonAndFocus(modalConfig);
        } else {
            modalFooter = modalBuilder.buildModalFooterByDomData(domData);
        }

        let modalBody;
        if (!modalConfig || !modalConfig.copyDomToBody) {
            modalBody = modalBuilder.buildModalBodyByDomData(domData);
        } else {
            modalBody = domData;
        }

        let modalHeader;
        const optionalDialogType = (modalConfig != null) ? modalConfig.dialogType : null;
        if (modalConfig && modalConfig.headerText) {
            modalHeader = modalBuilder.buildModalHeaderByText(modalBody, modalConfig.headerText, optionalDialogType);
        } else {
            modalHeader = modalBuilder.buildModalHeaderByServerData(modalBody, optionalDialogType);
        }

        // set default to false and check if config and largeModal are defined
        let useLargeModal = false;
        if (modalConfig && modalConfig.largeModal) {
            useLargeModal = true;
        }

        this.modalContainer = modalBuilder.buildModal(modalBody, modalHeader, modalFooter, useLargeModal);

        this.bindDisplayEventToProperty(modalConfig);
    }

    private bindDisplayEventToProperty(modalConfig?: ModalConfig) {
        this.modalContainer.on("show.bs.modal", () => {
            this.isVisible = true;
        });
        this.modalContainer.on("hide.bs.modal", () => {
            this.isVisible = false;
            if (this.parentForm != null) {
                this.parentForm.setChildModal(null);
            } // remove modal from childForm on close

            if (modalConfig?.overrideCloseCallback)
                modalConfig.overrideCloseCallback();
        });
    }

    private renderModalFromDomData(domData: JQuery, formCallbacks?: FormCallback | FormCallback[], modalConfig?: ModalConfig): void {

        this.unblockParentModalOrPage();

        // if page error exists, do not render modal, show message on parent form
        const pageAlert = domData.find("#page-alert");

        if (pageAlert.length > 0) {
            if (this.parentForm != null) {
                this.parentForm.showInlineMessage(pageAlert.text(), "error");
            }
        } else {
            try {
                this.openModalByDOMData(domData, formCallbacks, modalConfig);
            } catch (e) {
                this.handleModalError(e);
            }
        }

    }

    private handleModalError(error: any) {

        this.unblockParentModalOrPage();

        if (error.message) {
            Log.logError(error.message);
        } else {
            Log.logError(error);
        }

        if (this.parentForm != null) {
            this.parentForm.showInlineMessage(Locale.getTranslation("form load error"), "error");
        }
    }

    private initPortlet(formCallbacks?: FormCallback | FormCallback[]) {

        const portletBody = this.modalContainer.find("div.portlet-body");

        if (portletBody.length === 0) {
            return;
        }

        this.form = FormFactory.instantiateForm(portletBody, formCallbacks);

        this.form.setModal(this);

        this.form.initializeForm();
    }

    private blockParentModalOrPage(): void {
        if (this.parentForm != null && this.parentForm.getModal()) {
            this.parentForm.getFormContainer().block({ message: null });
        } else if (this.isVisible) {
            this.getModalDialogContainer().block({ message: null });
        } else {
            $.blockUI();
        }
    }

    private unblockParentModalOrPage(): void {
        if (this.parentForm != null && this.parentForm.getModal()) {
            this.parentForm.getFormContainer().unblock();
        } else if (this.isVisible) {
            this.getModalDialogContainer().unblock();
        } else {
            $.unblockUI();
        }
    }

    private focusButton(button: JQuery<HTMLAnchorElement>): void {

        if (this.dialogType !== DialogType.YesNo) { return; }

        this.modalContainer.on("shown.bs.modal", () => {
            button.trigger("focus");
        });

    }
}

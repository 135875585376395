import { Locale } from "component/localeManager/localeManager";
import { ActionTypes, FormAction } from "enum/allEnums";

export class ButtonBuilder {

    public static createDefaultAnchorButton(buttonText: string, inModal: boolean, toolTipText?: string, clickFunction?: Function): HTMLAnchorElement {

        const anchorButton = document.createElement("a");
        anchorButton.className = "text-button btn default";
        anchorButton.setAttribute("href", "");

        const span = document.createElement("span");
        span.innerText = buttonText;

        anchorButton.appendChild(span);

        if (toolTipText) {
            anchorButton.className += " has-tooltip";
            anchorButton.setAttribute("title", toolTipText);
        }

        anchorButton.addEventListener("click", (event: Event) => {

            if (clickFunction) {
                clickFunction();
                event.preventDefault();
            }
        });

        return anchorButton;
    }

    public static buildRowButton(direction: "prev" | "next" | "add", config?: ButtonConfig): JQuery<HTMLAnchorElement> {

        if (!config) {
            config = {};
        }

        if (!config.iconClass) {

            if (direction === "prev") {
                config.iconClass = `fa fa-arrow-left`;
                config.tooltipText = Locale.getTranslation("prev row");
                config.action = FormAction.Prevrow;
                
            } else if (direction === "next") {
                config.iconClass = `fa fa-arrow-right`;
                config.tooltipText = Locale.getTranslation("next row");
                config.action = FormAction.Nextrow;

            } else if (direction === "add") {
                config.iconClass = "fa fa-plus";
                config.tooltipText = Locale.getTranslation("add row");
                config.action = FormAction.Addrow;

            }
        }

        return this.buildButton(config);
    }

    public static buildSuccessButton(config: ButtonConfig): JQuery<HTMLAnchorElement> {

        config.color = "blue";

        if (!config.action) {
            config.action = FormAction.Saveform;
        }

        if (!config.actionType) {
            config.actionType = ActionTypes.Add;
        }

        return this.buildButton(config);
    }

    public static buildCloseButton(config: ButtonConfig, modalAutoCloseOnExitButton: boolean): JQuery<HTMLAnchorElement> {

        config.action = FormAction.Close;

        let attributes;
        if (modalAutoCloseOnExitButton) {
             attributes = [`data-dismiss="modal"`];
        }

        return this.buildButton(config, attributes);
    }

    public static buildButton(config: ButtonConfig, extraDataAttributes?: string[]): JQuery<HTMLAnchorElement> {

        let color = "";
        if (config.color) {
            color = config.color;
        } else {
            color = "default";
        }

        let action = "";
        if (config.action) {
            action = `data-button-id="${config.action}"`;
            action += ` data-form-action="${config.action}"`;
        }

        let actionType = "";
        if (config.actionType) {
            actionType = `data-action-type="${config.actionType}"`;
        }

        let hasTooltip = "";
        let toolTipText = "";
        if (config.tooltipText) {
            hasTooltip = `has-tooltip`;
            toolTipText = `title="${config.tooltipText}"`;
        }

        let content = "";
        if (config.responsiveText && config.iconClass) {
            content = `<span class="hidden-xs">${config.text}</span>
                        <span class="visible-xs"><i class="${config.iconClass}" aria-hidden="true"></i></span>`;
        } else if (config.iconClass) {
            content = `<span><i class="${config.iconClass}" aria-hidden="true"></i></span>`;
        } else {
            content = `<span>${config.text}</span>`;
        }

        let attributes = "";
        if (extraDataAttributes != null && extraDataAttributes.length > 0) {
            attributes = extraDataAttributes.join(" ");
        }

        return $(`<a href="#" tabindex="0" class="text-button btn ${color} ${hasTooltip}" ${action} ${actionType} ${toolTipText} ${attributes}>${content}</a>`);

    }

}

import { Locale } from "../../component/localeManager/localeManager";
import { Control } from "./control";
import { ControlData } from "./util/controlData";

export class SelectSort extends Control {

    get hasValue(): boolean {

        if ( this.getValues().length === 0 ) {
            return false;
        }

        const firstValue = this.getValues()[0].value;
        if ( firstValue && firstValue !== "" ) {
            return true;
        }

        return false;

    }

    private readonly selectControl: JQuery;
    private readonly cssSelectorFieldContainer: string = ".form-group";

    constructor(fieldContainer: JQuery, form?: FormInterface) {

        super(fieldContainer, form, "select");

        this.selectControl = this.fieldContainer.find("select.form-control");

    }

    public initialize(): void {
    }

    public enable(): void {

        if ( this.isStaticControl ) {
            return;
        }

        // Remove attribute disabled on each DOM element
        this.selectControl.prop("disabled", false);                // enable select too!
        this.selectControl.find("option").prop("disabled", false); // enable select.option(s)

        // Procedure from controlHelper.ts
        if ( this.fieldContainer.closest(this.cssSelectorFieldContainer).find("select").hasClass("sortable") ) {
            this.fieldContainer.closest(this.cssSelectorFieldContainer).find("ul").sortable("enable");
        }

    }

    public disable(): void {

        if (this.isStaticControl) {
            return;
        }

        // Set attribute disabled on each DOM element
        this.selectControl.prop("disabled", true);                // disable select too!
        this.selectControl.find("option").prop("disabled", true); // disable select.option(s)

        // Procedure from controlHelper.ts
        if ( this.fieldContainer.closest(this.cssSelectorFieldContainer).find("select").hasClass("sortable") ) {
            this.fieldContainer.closest(this.cssSelectorFieldContainer).find("ul").sortable("option", "disabled");
        }

    }

    public clear(triggerCallback: boolean = true): void {

        if (!this.hasValue) { return; }

        if (this.isStaticControl) {

            super.clearStaticInputBasedElement();

        } else {

            // Clear control with null and trigger select2 change event
            this.selectControl.val(null).trigger("change");

        }

        if (triggerCallback) {
            this.runValueChangedCallbacks();
        }
    }

    // Returns a ControlData array of :
    //      chosen items of the (visible) list
    //      not the underlaying (hidden) select.options
    //
    // Caution: Don't mix the available select.options
    // with the finally user picked unordered.list-items
    //
    // Better name => getSelectedValues() || getChosenValues()
    public getValues(): ControlDataInterface[] {

        if (this.isStaticControl) {

            return this.addEmptyControlDataWhenEmpty(super.getStaticMultipleField());

        } else {

            return this.addEmptyControlDataWhenEmpty(super.getValuesFromSortableSelect());

        }

    }

    // Add a single (visible) selected list.items
    // Respect underlaying (hidden) & available select.options
    public addValue(controlData: ControlDataInterface, triggerCallback: boolean = true): void {

        if (!controlData || super.containsValue(controlData)) { return; }

        if (this.isStaticControl) {
            super.addStaticValueMultipleField(controlData);
        } else {
            this.addAvailableOptionToSelectedList(controlData.displayValue, controlData.value);
        }

        // Trigger Event to execute parentcontrol clear functionality
        // [ this.getValues() ] <= array in array to prevent flattening by jquery
        this.fieldContainer.trigger("notifyChildControls", [this.getValues()]);

        if (triggerCallback) {
            this.runValueChangedCallbacks();
        }
    }

    // Set a couple of (visible) selected list.items.
    // Replace all selected & visible list.items with the new supplied values.
    // Respect underlaying (hidden) & available select.options
    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        this.clear(false);
        if ( Array.isArray( controlData) ) {
            for ( const cd of controlData ) {
                this.addValue(cd, false);
            }
        } else {
            this.addValue(controlData, false);
        }

        this.runValueChangedCallbacks();
    }

    public resetOptions(): void {

        if (this.isStaticControl) { return; }

        this.selectControl.html("");

        if (!this.getDataAttribute("data-field-attribute-no-blank")) {
            this.addValue(new ControlData("", Locale.getTranslation("select")), false);
        }
    }

    private addAvailableOptionToSelectedList(text: string, value: string): void {

        // Does this option already exist in our selection?
        const existingOption = this.selectControl.find( `option[value='${value}']` );

        // Option is available go ahead
        // Create an array of (hidden) option.values which reflect
        // the (visible) user selected list.items
        if ( existingOption.length > 0 ) {

            // Get values (guids) of previously(visual) selected items
            const valuesOfSelectedItemsPrevious = this.getValues().map( (x) => x.value ).filter( (x) => x );

            // Create an array of all to be (visual) selected items (previous + new )
            const valuesOfSelectedItemsNew = [ ...valuesOfSelectedItemsPrevious, value ];

            // A verified available option of our hidden select control gets selected
            // https://select2.org/programmatic-control/add-select-clear-items
            this.selectControl.val( valuesOfSelectedItemsNew ).trigger("change");

            // Trigger event to execute parent.control clear functionality only for removal
            // [ this.getValues() ] <= array in array to prevent flattening by jquery
            this.fieldContainer.trigger( "notifyChildControls", [ this.getValues() ] );

        }

    }

}

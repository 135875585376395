export class HtmlHelper {

    public static createListItemWithAnchor(anchorUrl: string, anchorText: string, listItemClasses: string[]): HTMLLIElement {

        const li = document.createElement("li");
        const a = document.createElement("a");

        for (const listItemClass of listItemClasses) {
            li.className = li.className + " " + listItemClass;
        }

        a.setAttribute("href", anchorUrl);
        a.innerText = anchorText;
        li.appendChild(a);

        return li;
    }

    public static downloadFile(href: string, filename: string): void {

        const a = document.createElement("a");
        a.href = href;
        a.download = filename;
        a.click();
    }
}

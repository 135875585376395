import { doParentSourceChildRelationship } from "component/parentSourceChild/parentSourceChildUtils";
import { ControlData } from "controls/util/controlData";
import { getSelect2MultipleConfiguration } from "controls/util/select2Configuration";
import { Locale } from "../../component/localeManager/localeManager";
import { Control } from "./control";

// No Static Field possible. Only rendered on Search Page
export class SelectMultiple extends Control {

    private readonly selectControl: JQuery;

    constructor(fieldContainer: JQuery, form?: FormInterface) {

        super(fieldContainer, form, "select");
        this.selectControl = this.fieldContainer.find("select");

    }

    public initialize(): void {

        this.initParentSourceChild();
    }

    public clear(): void {

        if (!this.hasValue) { return; }

        this.selectControl.val(null).trigger("change");

        this.runValueChangedCallbacks();

    }

    public equals(controlData: ControlDataInterface | ControlDataInterface[]): boolean {
        return super.equals(controlData, true);
    }

    public addValue(controlData: ControlDataInterface): void {

        const values = this.getValues();

        if (!super.containsValue(controlData)) {
            values.push(controlData);
        }

        this.setValues(values); // triggers value changed callback
    }

    public getValues(): ControlDataInterface[] {

        const options = this.fieldContainer.find("option:selected");
        const values: ControlData[] = [];

        if (options.length === 0) {
            return this.addEmptyControlDataWhenEmpty(values);
        }

        options.each((i: number, elem: Element) => {
            values.push(new ControlData($(elem).val().toString(), $(elem).text()));
        });

        return this.addEmptyControlDataWhenEmpty(values);

    }

    public setValues(values: ControlDataInterface | ControlDataInterface[]): void {

        if (!values || this.equals(values)) { return; }

        const valueColumn: string[] = [];

        for (const valueRow of this.makeArray(values)) {

            valueColumn.push(valueRow.value);

        }

        this.selectControl.val(valueColumn).trigger("change");

        this.runValueChangedCallbacks();

    }

    public resetOptions(): void {

        if (this.isStaticControl) { return; }

        this.selectControl.html("");

        if (!this.getDataAttribute("data-field-attribute-no-blank")) {
            this.addOptions([new ControlData("", Locale.getTranslation("select"))]);
        }
    }

    public addOptions(childData: ControlDataInterface[]): void {

        for (const item of childData) {
             //check if option value is already there
            if (!this.selectControl.find(`option[value='${item.value}']`).length) {
                this.selectControl.append(`<option value='${item.value}'>${item.displayValue}</option>`);
            }
        }
    }

    public disableAllOptions(): void {

        this.selectControl.find("option").prop("disabled", true);

        // need to trigger select2 again to update the disabled options
        this.selectControl.select2(getSelect2MultipleConfiguration(this.selectControl));
    }

    public enableOptions(childData?: ControlDataInterface[]): void {

        if (childData) {
            for (const item of childData) {
                this.selectControl.find(`option[value='${item.value}']`).prop("disabled", false);
            }
        } else {
            this.selectControl.find("option").prop("disabled", false);
        }

        // need to trigger select2 again to update the enabled options
        this.selectControl.select2(getSelect2MultipleConfiguration(this.selectControl));
    }

    private initParentSourceChild(): void {
        doParentSourceChildRelationship(this);

        this.selectControl.on("change", () => {
            doParentSourceChildRelationship(this);
        });
    }

}

import { Modules } from '../../uiFramework/enum/allEnums';
import { ChecklistDto } from './interfaces';
import { HttpClient } from '../../uiFramework/component/httpClient/httpClient';
import { SuggestionDto } from '../complianceJobTypeSuggestion/interfaces';
import { FormHelper } from '../../uiFramework/helper/formHelper';
import { Guid } from 'guid-typescript';
import { DetailForm } from '../../uiFramework/core/forms/detailForm';

export async function getChecklistById(checkListId: Guid = null): Promise<ChecklistDto> {

    const url = "Compliance/getChecklistById";
    const params = new URLSearchParams();
    if (checkListId !== null)
        params.append("checklistId", checkListId.toString());

    return HttpClient.httpGet<ChecklistDto>(url, params);
}

export async function getSuggestion(module: Modules): Promise<SuggestionDto> {

    const url = "Compliance/getSuggestion";
    const params = new URLSearchParams();
    params.append("module", module.toString());

    return HttpClient.httpGet<SuggestionDto>(url, params);
}

interface FormAndModule {
    form: FormInterface,
    module: Modules,
}
export function getFormAndModule(): FormAndModule {
    const formCr = FormHelper.getFormsByFormClass("compliance-cr")[0];
    const formProject = FormHelper.getFormsByFormClass("compliance-project")[0];

    const form = formCr ?? formProject;
    const module = (formCr != null) ? Modules.ChangeRequest : Modules.Project;

    return { form, module };
}

export function GetSelectChecklistControl(): Select {

    const formSelection = FormHelper.getFormsByFormClass("compliance-selection")[0] as DetailForm;
    return formSelection.getControl("name") as Select;
}

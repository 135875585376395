import { Locale } from "component/localeManager/localeManager";

export function getSelect2MultipleConfiguration(selectElement: JQuery<HTMLElement>): Select2.Options {

    let placeholder = "";

    if (selectElement.hasClass("select2-dropdown")) {
        placeholder = Locale.getTranslation("select");
    }

    const select2Config: Select2.Options = {};

    select2Config.dropdownCss = {};
    select2Config.placeholder = placeholder;
    select2Config.closeOnSelect = false;

    // fix width bug
    if (selectElement.hasClass("select2-dropdown sortable")) { // selectsort
        select2Config.width = "element";
    }
    else if (selectElement.hasClass("sortable")) { // sortable multiple lookup
        select2Config.width = "100%";
 }
    else {
        select2Config.width = null;
 }

    select2Config.language = Locale.getCurrentLanguage().substr(0, 2);
    select2Config.theme = "bootstrap";

    select2Config.sorter = function(data : any) {
        /* Sort data using lowercase comparison */
        return data.sort(function(a : any, b: any) {
            a = a.text.toLowerCase();
            b = b.text.toLowerCase();
            if (a > b) {
                return 1;
            } else if (a < b) {
                return -1;
            }
            return 0;
        });
    };

    return select2Config;
}

export function getSelect2Configuration(): Select2.Options {

    const select2Config: Select2.Options = {};

    select2Config.minimumResultsForSearch = 10;
    select2Config.width = null;
    select2Config.language = Locale.getCurrentLanguage().substr(0, 2);
    select2Config.theme = "bootstrap";
    select2Config.dropdownAutoWidth = true;

    return select2Config;
}

import { Locale } from "component/localeManager/localeManager";
import { ToastrPositionInline } from "component/toastrWrapper/toastrPositionInline";
import * as toastr from "toastr";

export class ToastrWrapper {

    public static removeInlineMessage(container: JQuery): void {

        container.children(".toast-inline-container").remove();
    }

    public static showPageMessage(message: string[] | string, toastrPageOptions?: ToastrPageOptions): void {

        toastrPageOptions = $.extend(this.getDefaultOptionsPage(), toastrPageOptions);

        const messageRaw = this.getMessageRaw(message);

        switch (toastrPageOptions.MessageType) {

            case "error":
                toastr.error(messageRaw, Locale.getTranslation("an error occurred"), toastrPageOptions.ToastrOptions);
                break;
            case "info":
                toastr.info(messageRaw, "", toastrPageOptions.ToastrOptions);
                break;
            case "success":
                toastr.success(messageRaw, "", toastrPageOptions.ToastrOptions);
                break;
            case "warning":
                toastr.warning(messageRaw, "", toastrPageOptions.ToastrOptions);
                break;
        }
    }

    public static showInlineMessage(message: string | string[], toastrInlineOptions: ToastrInlineOptions): void {

        toastrInlineOptions = $.extend(this.getDefaultOptionsInline(), toastrInlineOptions);

        let toastrControl: JQuery<HTMLElement>;

        const messageRaw = this.getMessageRaw(message);

        switch (toastrInlineOptions.MessageType) {

            case "error":
                toastrControl = toastr.error(messageRaw, "", toastrInlineOptions.ToastrOptions);
                break;
            case "info":
                toastrControl = toastr.info(messageRaw, "", toastrInlineOptions.ToastrOptions);
                break;
            case "success":
                toastrControl = toastr.success(messageRaw, "", toastrInlineOptions.ToastrOptions);
                break;
            case "warning":
                toastrControl = toastr.warning(messageRaw, "", toastrInlineOptions.ToastrOptions);
                break;

        }

        if (!toastrControl) {
            return;
        }

        toastrControl = this.wrapToastrInContainer(toastrControl, toastrInlineOptions.Position);

        this.placeInlineToastr(toastrControl, toastrInlineOptions.ParentElement, toastrInlineOptions.Position);

        const toastrContainerInDom = toastrInlineOptions.ParentElement.parent().find(".toast-inline-container");

        if (toastrInlineOptions.ShowPageToastrIfNotVisible &&
            !this.inlineToastrIsVisible(toastrContainerInDom)) {

            this.showPageMessage(messageRaw, { MessageType: toastrInlineOptions.MessageType });
        }
    }

    private static getMessageRaw(message: string | string[]): string {

        let messageRaw;

        if (Array.isArray(message)) {
            messageRaw = message.join("</br>");
        } else {
            messageRaw = message.toString();
        }

        return messageRaw;
    }

    private static wrapToastrInContainer(toastrControl: JQuery<HTMLElement>, position: ToastrPositionInline): JQuery<HTMLElement> {
        const toastrContainer = $("<div></div>").addClass("toast-inline-container");

        if (position === ToastrPositionInline.Right) {
            toastrContainer.addClass("toast-inline-right");
        }

        if (position === ToastrPositionInline.Left) {
            toastrContainer.addClass("toast-inline-left");
        }

        toastrContainer.append(toastrControl);
        return toastrContainer;
    }

    private static inlineToastrIsVisible(elm: JQuery): boolean {

        const viewportHeight = $(window).height(),
            scrollTop = $(window).scrollTop(),
            elementOffset = $(elm).offset().top,
            elementHeight = $(elm).height();

        return ((elementOffset < (viewportHeight + scrollTop))
                && (elementOffset > (scrollTop - elementHeight)));
    }

    private static placeInlineToastr(toastrControl: JQuery<HTMLElement>, parentElement: JQuery, position: ToastrPositionInline) {

        switch (position) {
            case ToastrPositionInline.Right:
            case ToastrPositionInline.Bottom:
            case ToastrPositionInline.Left:

                parentElement.after(toastrControl);

                break;

            case ToastrPositionInline.Top:

                parentElement.before(toastrControl);

                break;
            case ToastrPositionInline.Inside:

                parentElement.append(toastrControl);

                break;
        }
    }

    private static getDefaultOptionsInline(): ToastrInlineOptions {

        let toastrinlineOptions: ToastrInlineOptions;

        let toasterOptions: ToastrOptions;
        toasterOptions = {
            containerId: "toast-container-inline",
            closeOnHover: false,
            preventDuplicates: false,
            progressBar: false,
            timeOut: 0,
            extendedTimeOut: 0,
            tapToDismiss: false,
            positionClass: "",
        };

        toastrinlineOptions = {
            ParentElement: $("body"),
            MessageType: "error",
            ShowPageToastrIfNotVisible: true,
            Position: ToastrPositionInline.Bottom,
            ToastrOptions: toasterOptions,
        };

        return toastrinlineOptions;
    }

    private static getDefaultOptionsPage(): ToastrPageOptions {

        let toasterOptions: ToastrOptions;
        toasterOptions = {
            closeOnHover: false,
            preventDuplicates: true,
            progressBar: true,
            timeOut: 10000,
            extendedTimeOut: 0,
            positionClass: "toast-top-center",
        };

        return {
            MessageType: "error",
            ToastrOptions: toasterOptions,
        };
    }
}

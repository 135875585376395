import { ControlData } from "controls/util/controlData";
import { Control } from "./control";

export class Radio extends Control {

    public initialize(): void {}

    public clear(triggerCallback: boolean = true): void {

        if (!this.hasValue) { return; }

        if (this.isStaticControl) {
            super.clearStaticInputBasedElement();
        } else {
            (this.fieldContainer.find("input:checked") as JQuery<HTMLInputElement>).removeAttr("checked");
        }

        if (triggerCallback) {
            this.runValueChangedCallbacks();
        }
    }

    public focus(): void {

        // checkboxes are not possible to focus,
        // because visible is only a span, and span's couldnt be focused
        return;

    }

    public getValues(): ControlDataInterface[] {

        if (this.isStaticControl) {
            return super.getStaticSingleField();
        } else {
            return [this.getCheckbox()];

        }
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }

        this.runValueChangedCallbacks();
    }

    private setValue(controlData: ControlDataInterface): void {

        if (this.isStaticControl) {
            super.setStaticField(controlData.value, controlData.displayValue);
        } else {

            this.clear(false);
            this.fieldContainer.find(`input[value="${controlData.value}"]`).attr("checked", "");
        }
    }

    private getCheckbox() {

        const inputElement = this.fieldContainer.find("input:checked");
        const value = super.nullToEmptyString(inputElement.val() as string);
        const displayValue = inputElement.parent().text();

        return new ControlData(value, displayValue);
    }

}

import { FormAction, ModalType } from "enum/allEnums";

export class PageTransfer implements PageTransferInterface {
    public forms: FormTransferInterface[] = [];
    public action: FormAction;
    public actionType: ActionTypes;
    public modalType: ModalType;
    public pageName: string;
    public sessionValuesByProperties: Dictionary<string | number>;

    public addForm(form: FormTransferInterface): void {
        this.forms.push(form);
    }

}

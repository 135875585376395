import { BackgroundSubmit } from "component/backgroundSubmit/backgroundSubmit";
import { HttpClient } from "component/httpClient/httpClient";
import { Locale } from "component/localeManager/localeManager";
import { ModalType } from "enum/allEnums";
import { HtmlHelper } from "helper/htmlHelper";
import { Modal } from "modals/modal";

export class GridActionButton {

    private readonly form: FormInterface;

    constructor(form: FormInterface) {
        this.form = form;
    }

    public wireGridActionButtons(gridActionButtons: NodeListOf<Element> | Element[]): void {

        for (let i = 0; i < gridActionButtons.length; i++) {

            gridActionButtons[i].addEventListener("click", (event: Event) => {

                const buttonElement = ($(event.target) as JQuery<HTMLButtonElement>).closest("a");

                this.applyGridAction(buttonElement);
            });
        }
    }

    private applyGridAction(buttonElement: JQuery<HTMLButtonElement>): void {

        const formName = buttonElement.attr("data-gridactionbutton-form-name");

        if (!formName) {
            return;
        }

        const sessionPropertiesAndValuesToSetJson = buttonElement.attr("data-gridactionbutton-session-properties-to-set");
        const sessionPropertiesAndValuesToSet: Dictionary<string> = JSON.parse(sessionPropertiesAndValuesToSetJson);

        const url = "Framework/GetGridActionList";
        const params = new URLSearchParams();
        params.append("formName", formName);

        $.blockUI();
        HttpClient
            .httpPost<string>(url, sessionPropertiesAndValuesToSet, params)
            .then((gridActionJson) => {

                $.unblockUI();

                if (gridActionJson != null) {

                    const gridActions: GridAction[] = JSON.parse(gridActionJson);
                    this.applyGridActionList(buttonElement, gridActions);
                }
            })
            .catch(() => {
                $.unblockUI();
            });

    }

    private applyGridActionList(buttonElement: JQuery<HTMLButtonElement>, gridActions: GridAction[]): void {

        const dropDownListRoot = buttonElement.parent().find("ul.dropdown-menu");
        if (dropDownListRoot == null) {
            return;
        }

        // remove existing entries to render the latest entries
        while (dropDownListRoot.find(":first-child").length !== 0) {
            dropDownListRoot.find(":first-child").remove();
        }

        // render empty list element, so the user knows there is nothing to select here.
        if (gridActions == null || gridActions.length === 0) {

            const listItemElement = HtmlHelper.createListItemWithAnchor("javascript:void(0)", Locale.getTranslation("empty dropdown"), ["gridActionListItem"]);
            dropDownListRoot.append(listItemElement);

            return;
        }

        const formId = buttonElement.closest("form").attr("data-form-id");
        if (gridActions.length > 0) {
            for (const gridAction of gridActions) {

                this.renderAction(gridAction, dropDownListRoot, formId);
            }
        }
    }

    private renderAction(gridAction: GridAction, dropDownListRoot: JQuery<HTMLButtonElement>, formId: string) {

        const classes: string[] = ["gridActionListItem"];
        const listItemElement = HtmlHelper.createListItemWithAnchor(gridAction.url, gridAction.title, classes);

        dropDownListRoot.append(listItemElement);

        if (gridAction.actionForm && !gridAction.openModal) {

            this.doBackgroundSubmit(listItemElement, gridAction);

        } else if (gridAction.actionForm && gridAction.openModal) {

            this.doActionInModal(listItemElement, gridAction, formId);
        }
    }

    private doActionInModal(listItemElement: HTMLLIElement, gridAction: GridAction, formId: string) {

        listItemElement.querySelector("a").dataset.url = gridAction.url;
        listItemElement.querySelector("a").addEventListener("click", (event: Event) => {

            event.preventDefault();

            const modalId = `${formId}button${gridAction.title}`;
            const modalType = ModalType.Single;

            const modal = new Modal(modalType, modalId, this.form);

            modal.openModalByURL(gridAction.url);
        });

    }

    private doBackgroundSubmit(listItemElement: HTMLLIElement, gridAction: GridAction) {

        listItemElement.querySelector("a").addEventListener("click", (event: Event) => {

            event.preventDefault();

            const backgroundSubmit = new BackgroundSubmit(this.form);
            backgroundSubmit.backgroundSubmit(gridAction.url, gridAction.actionForm)
                .then(() => this.form.reloadForm());
        });
    }
}

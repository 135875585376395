
export enum LogLevels {
    Trace = 1000,
    Debug = 2000,
    Info = 3000,
    Warn = 4000,
    Error = 5000,
    Fatal = 6000,

}

import { HttpClient } from "../../uiFramework/component/httpClient/httpClient";
import { Locale } from "../../uiFramework/component/localeManager/localeManager";
import { ControlData } from "../../uiFramework/core/controls/util/controlData";
import { PayTypeUsed } from "../../uiFramework/enum/allEnums";
import { Util } from "../../uiFramework/helper/util";
import { isPayGroupBased } from "./calculationUtil";
import { IndustrySurchargeModel } from './interface/industrySurchargeModel';
import { SurchargeInputData } from './interface/surchargeInputData';

export class IndustrySurchargeHelper {

    // Dropdown to select industry surcharge level in case of previous employment (not possible for project)
    public fillPreviousEmploymentDropdown(surchargeControl: Select, inputData: SurchargeInputData): Promise<void> {

        if (!surchargeControl
            || !inputData.StartDate
            || !Util.isValidDate(inputData.StartDate)
            || !inputData.ObjectId
            || !inputData.Module) {
            return Promise.resolve();
        }

        if (!isPayGroupBased(inputData.PayTypeUsed) && inputData.PayTypeUsed !== PayTypeUsed.IndividualRegulation) {
            return Promise.resolve();
        }

        if (!inputData.IndustryId && inputData.PayTypeUsed !== PayTypeUsed.IndividualRegulation) { // pay grade and industry is not needed for individual regulation, because the individual surcharges are fetched by object id
            return Promise.resolve();
        }

        inputData.PaymentGroupRuleId = !inputData.PaymentGroupRuleId ? null : inputData.PaymentGroupRuleId; // use null instead of empty string otherwise web api cannot convert empty string to nullable guid

        $.blockUI();
        return this.getIndustrySurcharges(inputData)
            .then((industrySurcharge) => {

                this.addIndustrySurcharges(industrySurcharge, surchargeControl);

            })
            .finally(() => {

                $.unblockUI();

            });
    }

    private getIndustrySurcharges(inputData: SurchargeInputData): Promise<IndustrySurchargeModel> {
        
        const url = `surchargerulewebapi/getIndustrySurcharge`;
        
        return HttpClient
            .httpPost<IndustrySurchargeModel>(url, inputData);

    }

    private async addIndustrySurcharges(industrySurcharge: IndustrySurchargeModel, surchargeControl: Select): Promise<void> {

        if (!industrySurcharge) {
            surchargeControl.resetOptions();
            return Promise.resolve();
        }

        const entries = this.formatIndustrySurchargeEntries(industrySurcharge);
        surchargeControl.resetOptions(); // reset to get the default entry
        surchargeControl.addOptions(entries);

        const oldValue = surchargeControl.getDataAttribute("data-field-value");
        if (oldValue) {
            surchargeControl.clear();
            surchargeControl.setValues(new ControlData(oldValue));
        }

        return Promise.resolve();
    }


    private formatIndustrySurchargeEntries(industrySurcharge: IndustrySurchargeModel): ControlDataInterface[] {

        const entries = [];

        let i = 1;
        for (let item of industrySurcharge.industrySurchargePos.sort(x => x.industrySurchargeLevel)) {

            const increase = industrySurcharge.increaseType
                ? Locale.formatCurrency(item.increase)
                : `${Locale.formatNumber(item.increase)}%`;

            let durationUnitType;
            switch (item.durationType) {
                case 1:
                    durationUnitType = Locale.getTranslation("days");
                    break;
                case 2:
                    durationUnitType = Locale.getTranslation("weeks");
                    break;
                case 3:
                    durationUnitType = Locale.getTranslation("months");
                    break;
                case 4:
                    durationUnitType = Locale.getTranslation("years");
                    break;
                default:
                    durationUnitType = "";
                    break;
            }

            const name = `${Locale.getTranslation("step")} ${i} : ${item.workingDuration} ${durationUnitType} - ${increase}`;
            entries.push(new ControlData(item.industrySurchargeLevel.toString(), name));
            i++;
        }

        return entries;

    }

}

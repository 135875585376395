import { Log } from "component/logging/logging";

export class FormTransfer implements FormTransferInterface {

    public id: string;
    public dataRows: DataRowTransferInterface[] = [];
    public primaryKeys: string; // used on server

    constructor(id: string) {
        if (id === undefined) {
            Log.logError("No Form Id commited!");
        }

        this.id = id;
    }

    public containsData(): boolean {

        for (const row of this.dataRows) {

            if (row != null) { return true; }

        }

        return false;
    }

    public addOrUpdateRow(row: DataRowTransferInterface, index: number): void {

        this.dataRows[index] = row;
    }

    public getRows(): DataRowTransferInterface[] {
        return this.dataRows;
    }

    public getRow(index: number): DataRowTransferInterface | undefined {
        return this.dataRows[index];
    }

    public addField(rowIndex: number, fieldName: string, values: string[]): void {

        let row = this.dataRows[rowIndex];
        if (!row) {
            this.dataRows[rowIndex] = row = {};
        }

        row[fieldName] = values;

    }

    public updateFieldValue(fieldName: string, values: string[]): boolean {
        if (!this.doesFieldExist(fieldName)) {
            return false;
        }
        const firstRow = this.dataRows[0];
        firstRow[fieldName] = values;
        return true;
    }

    public getFieldValue(fieldName: string): any[] {

        const firstRow = this.dataRows[0];

        return firstRow[fieldName];
    }

    public doesFieldExist(fieldName: string): boolean {
        const firstRow = this.dataRows[0];
        if (firstRow[fieldName] === undefined) {
            return false;
        }
        return true;
    }

    public setPrimaryKeys(primaryKeys: string) {
        this.primaryKeys = primaryKeys;
    }
}

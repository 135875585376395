import { Locale } from "component/localeManager/localeManager";
import { ControlData } from "controls/util/controlData";
import { FormAction, FormStyles, ModalType, Status } from "enum/allEnums";
import { DetailForm } from "forms/detailForm";
import { FormCallbackType } from "forms/enum/formCallbackType";
import { GridForm } from "forms/gridForm";
import { Util } from "helper/util";
import { isEqual } from "lodash";
import { DialogType } from "modals/enum/dialogType";
import { Modal } from "modals/modal";
import { FormDataWrapper } from "./formDataWrapper";

type Direction = "previous" | "next" | "add";
export type Mode = "add" | "edit";

export class DetailFormWithDataGrid extends DetailForm {

    public get formStyle(): FormStyles {
        return FormStyles.DetailFormWithDataGrid;
    }

    private parentGridForm: GridForm;
    private mode: Mode;
    private addRowOperation = false;
    private formDataWrapper: FormDataWrapper;

    constructor(dispatcher: DispatcherInterface, portletBody: JQuery, formCallbacks?: FormCallback | FormCallback[]) {

        super(dispatcher, portletBody, formCallbacks);

    }

    public async initializeForm(): Promise<void> {

        this.parentGridForm = this.getParentGridForm();
        if (this.parentGridForm == null) {
            throw new Error("Parent Grid Form not found!");
        }

        this.setMode();

        super.initializeDetailForm();

        this.formDataWrapper = new FormDataWrapper(this.parentGridForm, this.mode);

        this.setButtonVisibility();

        this.overrideCloseButtons();

        this.resetControls();

        if (this.mode === "edit") {
            this.fillForm();
        } else {
            this.addRowOperation = true;
        }

        super.setFormInitialized();

    }

     /**
     * Override: Submit the Form
     * @param button
     */
    public dispatch(button: ButtonInterface): Promise<void> {

        const actionType = button.formAction;
        if (actionType === FormAction.Nextrow || actionType === FormAction.Prevrow || actionType === FormAction.Addrow) {

            this.doWork(actionType);
            return Promise.resolve();

        } else {

            this.saveChangedData(); // save current viewed detail form to transfer data
            return super.dispatch(button);

        }
    }

    /**
    * Override: Render Error Message to Form
    * @param response
    */
    public handleDispatchError(response: ResponseMessage): void {

        if (response.RowStatus != null && response.RowStatus.length > 0) {

            if (response.RowStatus.filter((e) => e.Status === Status.Success).length > 0) {
                this.parentGridForm.reloadForm();
            } // when something was done successfully, show reloaded grid to the user

            if (this.mode === "add") {
                this.updatePrimaryKeyFields(response.RowStatus);
            }

        }
        this.showErrorEntry(response.RowStatus);

        super.handleDispatchError(response);
    }

    public getTransferData(): FormTransferInterface {

        const transferData = this.createTransferData();

        this.includeFormData(transferData);

        return transferData;
    }

    public fillControlsFromFormData(formData: Dictionary<ControlDataInterface[]>): void {

        for (const control of this.controls) {

            const values = formData[control.fieldName];
            if (values == null) {
                continue;
            }

            control.setValues(values);

        }
    }

    private setMode(): void {

        const value = this.parentGridForm.getDataAttribute("mode");
        if (value === "add") {
            this.mode = "add";
        } else {
            this.mode = "edit";
        }
    }

    private includeFormData(transferData: FormTransferInterface): void {

        let position = 0;
        for (let i = 0; i < this.formDataWrapper.rowCount(); i++) {

            const rowData = this.formDataWrapper.getFormControlData(i, true);

            if (!rowData) { continue; }

            const convertedRow = this.convertRow(rowData);
            transferData.addOrUpdateRow(convertedRow, position);
            position++;
        }

    }

    private convertRow(formRow: Dictionary<ControlDataInterface[]>): DataRowTransferInterface {

        const convertedRow: DataRowTransferInterface = {};
        for (const fieldName in formRow) {

            const fieldValues = formRow[fieldName];
            convertedRow[fieldName] = fieldValues.map((v) => v.value);

        }

        return convertedRow;
    }

    private doWork(actionType: FormAction): void {

        if (!this.preChecks(actionType)) {
            return;
        }

        this.removeInlineMessage();

        this.blockModalOrPage();

        this.saveChangedData();

        const direction = this.getDirectionByActionType(actionType);
        this.switchPage(direction);

        this.unblockModalOrPage();
    }

    private getDirectionByActionType(actionType: FormAction): Direction {

        switch (actionType) {

            case FormAction.Nextrow:
                return "next";

            case FormAction.Prevrow:
                return "previous";

            case FormAction.Addrow:
                return "add";

            default:
                throw new Error("Unsupported FormAction");
        }

    }

    private switchPage(which: Direction | number): void {

        const direction: Direction = (this.formDataWrapper.getActiveRow() > which) ? "previous" : "next";
        if (typeof which === "number") {
            this.runBeforeAnimation(direction);
        } else {
            this.runBeforeAnimation(which);
        }

        this.resetControls();

        super.resetValidation();

        this.setActiveRow(which);

        this.fillForm(which);

        this.setButtonVisibility();

        this.setRowMode(which);

        super.runCallbacks(FormCallbackType.FormLoaded);

        if (typeof which === "number") {
            this.runAfterAnimation(direction);
        } else {
            this.runAfterAnimation(which);
        }
    }

    private fillForm(action?: Direction | number): void {

        if (action === "add") {
            return;
        }

        this.fillControlsFromFormDataCurrentRow();

    }

    private preChecks(actionType: FormAction): boolean {

        if (!this.checkValidActionType(actionType)) {
            return false;
        }

        if (this.skipAnotherAddWhenNewRowIsEmpty(actionType)) {
            return false;
        }

        if (!this.otherRowExist(actionType)) {
            return false;
        }

        if (!this.validateForm()) {
            return false;
        }

        return true;

    }

    private skipAnotherAddWhenNewRowIsEmpty(actionType: FormAction) {
        return (actionType === FormAction.Addrow) && this.addRowOperation && !this.formHasAnyValues();
    }

    private validateForm(): boolean {

        if (this.addRowOperation && !this.formHasAnyValues()) {
            // skip validation when adding a new row and no values were set.
            // otherwise user can't left accidentally added new row
            return true;
        }

        return this.isValid();
    }

    private checkValidActionType(actionType: FormAction): boolean {

        return actionType === FormAction.Prevrow || actionType === FormAction.Nextrow || actionType === FormAction.Addrow;
    }

    private otherRowExist(actionType: FormAction): boolean {

        // stop action when min or max row reached
        if (actionType === FormAction.Nextrow) {
            return this.formDataWrapper.rowExist("next");
        } else if (actionType === FormAction.Prevrow) {
            return this.formDataWrapper.rowExist("previous");
 }

        return true;
    }

    private setRowMode(actionType?: Direction | number) {
        this.addRowOperation = actionType === "add";
    }

    private setButtonVisibility(): void {

        const prevButton = this.getButtonsByActionType(FormAction.Prevrow)[0];
        this.formDataWrapper.rowExist("previous") ? prevButton.enable() : prevButton.disable();

        const nextButton = this.getButtonsByActionType(FormAction.Nextrow)[0];
        this.formDataWrapper.rowExist("next") ? nextButton.enable() : nextButton.disable();

    }

    private overrideCloseButtons(): void {

        const overrideFunction = () => this.handleCloseEvent();

        const closeButton = this.getButtonsByActionType(FormAction.Close)[0];
        closeButton.overrideClickEvent(overrideFunction);

        const closeButtonSymbol = this.getModal().getModalDialogContainer().find("button.close");
        closeButtonSymbol.click(overrideFunction);
    }

    private handleCloseEvent(): void {

        this.saveChangedData();

        this.showCloseMessage();
    }

    private showCloseMessage(): void {

        if (this.formDataWrapper.containsDirtyData()) {

            const pkId = `${this.mode}-close`;
            const unsavedDialog: Modal = new Modal(ModalType.Single, pkId, this);

            const headerText: string = Locale.getTranslation("are you sure");
            const bodyText: string = Locale.getTranslation("unsaved data");

            unsavedDialog.openModalDialog(headerText, bodyText, DialogType.YesNo, {
                overrideSuccessCallback: () => {

                    unsavedDialog.closeModal();
                    this.getModal().closeModal();
                }
            });

        } else {
            this.getModal().closeModal();
        }

    }

    private saveChangedData(): void {

        if (this.hasChangedData()) {
            this.saveRow();
        }

    }

    private hasChangedData() {

        if (this.rowAlreadyDirty()) {
            return true;
        }

        if (this.addRowOperation && this.formHasAnyValues()) {
            return true;
        }

        if (!this.addRowOperation && this.rowIsDirty()) {
            return true;
        }

        return false;
    }

    private formHasAnyValues(): boolean {

        for (const control of this.controls) {
            if (control.hasValue) {
                return true;
            }
        }
        return false;
    }

    private saveRow(): void {

        const newRowData = super.getControlDataDictionary();
        this.formDataWrapper.insertOrUpateFormControlData(newRowData);
    }

    private rowAlreadyDirty(): boolean {
        return this.formDataWrapper.rowContainsChangedData();
    }

    private rowIsDirty(): boolean {

        // compare values with grid
        const oldVisibleFields = this.parentGridForm.getVisibleFields("current");
        const oldHiddenFields = this.parentGridForm.getHiddenFields("current");
        const rowDataDict = super.getControlDataDictionary();

        for (const fieldName in rowDataDict) {

            const controlData = rowDataDict[fieldName];

            if (this.hasValueChangedToGridValue(fieldName, controlData, oldVisibleFields)
                || this.hasValueChangedToHiddenGridValue(fieldName, controlData, oldHiddenFields)) {
                return true;
            }

        }

        return false;

    }

    private hasValueChangedToGridValue(fieldName: string, controlData: ControlDataInterface[], gridFields: Dictionary<GridContentData>) {

        if (gridFields[fieldName] == null) {
            return false;
        }

        const values = controlData.map((cd) => cd.value);
        return !isEqual(gridFields[fieldName].value, values);
    }

    private hasValueChangedToHiddenGridValue(fieldName: string, controlData: ControlDataInterface[], hiddenGridFields: Dictionary<string[]>) {

        const values = controlData.map((cd) => cd.value);
        return hiddenGridFields[fieldName] != null && !isEqual(hiddenGridFields[fieldName], values);
    }

    // set pk fields for successfully inserted rows
    private updatePrimaryKeyFields(rowStatus: RowStatus[]): void {

        for (let rowIndex = 0; rowIndex < rowStatus.length; rowIndex++) {
            const entry = rowStatus[rowIndex];

            if (entry.Status === Status.Error) { continue; }

            const pkFields = entry.PrimaryKeys;
            const rowData = this.formDataWrapper.getFormControlData(rowIndex);

            let updateRow = false;
            for (const pkName in pkFields) {

                if (rowData[pkName][0].value !== pkFields[pkName]) { // compare existing pk field value with response pk field value
                    updateRow = true;
                    rowData[pkName] = [new ControlData(pkFields[pkName])];
                }
            }

            if (updateRow) {
                const oldActiveRow = this.formDataWrapper.getActiveRow();
                this.formDataWrapper.setActiveRow(rowIndex);
                this.formDataWrapper.insertOrUpateFormControlData(rowData);
                this.formDataWrapper.setActiveRow(oldActiveRow);
            }
        }

    }

    private showErrorEntry(rowStatus: RowStatus[]): void {

        const entry = this.getFirstErrorEntry(rowStatus);

        this.fillControlsFromPrimaryKey(entry);

    }

    private getFirstErrorEntry(rowStatus: RowStatus[]): Dictionary<string> {

        if (rowStatus && rowStatus.length > 0) {

            const entries = rowStatus.filter((e) => e.Status === Status.Error);

            if (entries.length > 0) {
                return entries[0].PrimaryKeys;
            }
        }

        return null;
    }

    private fillControlsFromPrimaryKey(primaryKeyData: Dictionary<string>): void {

        this.blockModalOrPage();

        let rowIndex = this.findRowIndexByPrimaryKey(primaryKeyData);

        if (rowIndex == null) {
            rowIndex = this.getIndexOfFirstUnsavedData();
        }

        if (rowIndex != null && this.formDataWrapper.getActiveRow() !== rowIndex) {
            this.switchPage(rowIndex);
        }

        this.unblockModalOrPage();
    }

    private findRowIndexByPrimaryKey(primaryKeyData: Dictionary<string>): number | null {

        if (Util.isDictionaryNullOrEmpty(primaryKeyData)) { return null; }

        for (let rowIndex = 0; rowIndex < this.formDataWrapper.rowCount(); rowIndex++) {

            const row = this.formDataWrapper.getFormControlData(rowIndex);

            let allPksEqualInRow = true;
            for (const pkName in primaryKeyData) {

                const errorPkValue = primaryKeyData[pkName];
                const rowPkValue = row[pkName][0].value;

                if (rowPkValue !== errorPkValue) {
                    allPksEqualInRow = false;
                    break;
                }

            }

            if (allPksEqualInRow) {
                return rowIndex;
            }

        }

        return null;
    }

    private getIndexOfFirstUnsavedData(): number | null {

        for (let rowIndex = 0; rowIndex < this.formDataWrapper.rowCount(); rowIndex++) {

            const row = this.formDataWrapper.getFormControlData(rowIndex, true);

            if (row != null) {
                return rowIndex;
            }
        }
        return null;
    }

    private fillControlsFromFormDataCurrentRow(): void {

        const formData = this.formDataWrapper.getFormControlData("current");

        this.fillControlsFromFormData(formData);
    }

    private getParentGridForm(): GridForm | null {
        if (!this.getModal()) { return null; }

        const gridForm = this.getModal().rootForm;
        if (!(gridForm instanceof GridForm)) { return null; }

        return gridForm;
    }

    private setActiveRow(which: Direction | number): void {

        if (which == null) { return; }

        if (which === "add") {
            this.formDataWrapper.setActiveRow("add");
        } else if (which === "next") {
            this.formDataWrapper.setActiveRow("next");
 } else if (which === "previous") {
            this.formDataWrapper.setActiveRow("previous");
 } else { // number = index
            this.formDataWrapper.setActiveRow(which);
        }
    }

    private runBeforeAnimation(direction: Direction): void {

        switch (direction) {
            case "previous": {
                const fxElement = this.getFormContainer().find(".modal-body");
                fxElement.removeClass("fadeInRight");
                fxElement.removeClass("fadeInLeft");
                fxElement.addClass("animated fadeOutRight");
                break;
            }
            case "add":
            case "next": {
                const fxElement = this.getFormContainer().find(".modal-body");
                fxElement.removeClass("fadeInRight");
                fxElement.removeClass("fadeInLeft");
                fxElement.addClass("animated fadeOutLeft");
                break;
            }
        }
    }

    private runAfterAnimation(direction: Direction): void {

        switch (direction) {
            case "previous": {
                const fxElement = this.getFormContainer().find(".modal-body");
                fxElement.removeClass("fadeOutRight");
                fxElement.addClass("fadeInLeft");
                break;
            }
            case "add":
            case "next": {
                const fxElement = this.getFormContainer().find(".modal-body");
                fxElement.removeClass("fadeOutLeft");
                fxElement.addClass("fadeInRight");
                break;
            }
        }
    }

}

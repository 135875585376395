import { ControlData } from "controls/util/controlData";
import { debounce } from "lodash";
import { Control, ControlCallbackFunction } from "./control";

export class Bitmask extends Control {

    get hasValue(): boolean {

        const values = this.getValues();
        if (values.length === 0) { return false; }

        const value = values[0].value;
        if (value && value !== "" && value !== "0") {
            return true;
        }

        return false;

    }

    public initialize(): void {}

    public clear(): void {

        if (!this.hasValue) { return; }

        if (!this.isStaticControl) {
            const inputChoices = this.fieldContainer.find("input");

            inputChoices.removeAttr("checked");
        } else {
            super.clearStaticInputBasedElement();
        }

        this.runValueChangedCallbacks();
    }

    public focus(): void {

        // checkboxes are not possible to focus,
        // because visible is only a span, and span's couldnt be focused
        return;

    }

    // Special case: When nothing selected, return value: 0, displayValue ""
    public getValues(): ControlDataInterface[] {

        if (this.isStaticControl) {

            return [this.getStaticBitmask()];

        } else {

            return [this.getEditBitmask()];

        }
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }

        this.runValueChangedCallbacks();
    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        super.bindOnValueChanged(callback);
        this.fieldContainer.find("span").on("click", debounce(() => callback(), 200));

    }

    private setValue(controlData: ControlDataInterface): void {

        if (!this.isStaticControl) {
            this.setBitmask(controlData);
        } else {
            super.setStaticField(controlData.value, controlData.displayValue);
        }

    }

    private getStaticBitmask(): ControlDataInterface {

        const controlData = super.getStaticSingleField()[0];

        const value = parseInt(controlData.value);

        if (isNaN(value)) { // empty case: nothing selected
            return new ControlData("0", "");
        }

        return new ControlData(value.toString(), controlData.displayValue);
    }

    private getEditBitmask(): ControlDataInterface {

        const fields = this.fieldContainer.find("input:checked");
        let value = 0;
        const displayValues: string[] = [];

        fields.each((i: number, elem: Element) => {
            const temp = parseInt($(elem).val() as string);
            if (!isNaN(temp)) {
                value += temp;
            }

            const tempDisplayValue = $(elem).parent().text().trim();
            if (tempDisplayValue) {
                displayValues.push(tempDisplayValue);
            }
        });

        return new ControlData(value.toString(), displayValues.join(", "));

    }

    private setBitmask(controlData: ControlDataInterface): void {

        const selectionValue = parseInt(controlData.value);

        const inputChoices = this.fieldContainer.find("input");

        inputChoices.each((i: number, element: HTMLElement) => {

            const inputChoice = $(element);
            this.setChoiceValue(inputChoice, selectionValue);

        });

    }

    private setChoiceValue(inputChoice: JQuery, selectionValue: number) {
        const choiceValue = parseInt(inputChoice.val().toString());
        if ((choiceValue & selectionValue) === choiceValue) {
            inputChoice.attr("checked", "checked");
        } else {
            inputChoice.removeAttr("checked");
        }
    }
}

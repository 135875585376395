import { ControlData } from "controls/util/controlData";
import { Control } from "./control";

export class MultipleCheckbox extends Control {

    public initialize(): void {}

    public clear(triggerCallback: boolean = true): void {

        if (!this.hasValue) { return; }

        if (this.isStaticControl) {
            super.clearStaticInputBasedElement();
        } else {
            this.fieldContainer.find("input").prop("checked", false);
        }

        if (triggerCallback) {
            this.runValueChangedCallbacks();
        }
    }

    public getValues(): ControlDataInterface[] {

        if (this.isStaticControl) {
            return this.addEmptyControlDataWhenEmpty(this.getStaticValues());
        } else {
            return this.addEmptyControlDataWhenEmpty(this.getCheckboxes());
        }

    }

    public equals(controlData: ControlDataInterface | ControlDataInterface[]): boolean {
        return super.equals(controlData, true);
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (this.isStaticControl) {
            this.setStaticValues(controlData);
        } else {
            this.setCheckboxes(controlData);
        }

        this.runValueChangedCallbacks();
    }

    public addValue(controlData: ControlDataInterface): void {

        if (!controlData) { return; }

        let alreadyExists = true;
        if (this.isStaticControl) {

            const values = this.getStaticValues();
            if (values.map((v) => v.value).indexOf(controlData.value) === -1) {

                values.push(controlData);
                this.setStaticValues(values);
                alreadyExists = false;
            }
        } else {
            if (!this.fieldContainer.find(`[value=${controlData.value}]`).prop("checked")) {
                this.fieldContainer.find(`[value=${controlData.value}]`).prop("checked", true);
                alreadyExists = false;
            }
        }

        if (!alreadyExists) {
            this.runValueChangedCallbacks();
        }
    }

    private getStaticValues(): ControlDataInterface[] {

        const values: ControlDataInterface[] = [];

        const inputControl = this.fieldContainer.find("input");
        const spanControl = this.fieldContainer.find("span");
        if (inputControl.length > 0 && spanControl.length > 0) {

            const valuesArray = inputControl.val().toString().split(";");
            const displayValuesArray = spanControl.text().toString().split(",");

            for (let i = 0; i < valuesArray.length; i++) {

                const value = valuesArray[i].trim();
                const displayValue = (displayValuesArray[i] || "").trim();

                if (value.length > 0 && displayValue.length > 0) {
                    values.push(new ControlData(value, displayValue));
                }
            }

        }

        return values;
    }

    private getCheckboxes(): ControlDataInterface[] {

        const checkboxes = this.fieldContainer.find("input:checked");

        if (checkboxes == null) {
            return [];
        }

        const values: ControlData[] = [];
        // only add values of checked options
        checkboxes.each((i: number, elem: HTMLInputElement) => {
            values.push(new ControlData($(elem).val().toString(), $(elem).parent().text()));
        });

        return values;
    }

    private setStaticValues(values: ControlDataInterface | ControlDataInterface[]): void {

        if (!Array.isArray(values)) {
            super.setStaticField(values.value, values.displayValue);
        } else {
            const controlValues = values.map((v) => v.value).join(";");
            const displayValues = values.map((v) => v.displayValue).join(",<br>");
            super.setStaticField(controlValues, displayValues, true);
        }
    }

    private setCheckboxes(values: ControlDataInterface | ControlDataInterface[]): void {

        // first clear all values, then set given values
        this.clear(false);

        if (!Array.isArray(values)) {
            this.selectCheckbox(values);
        } else {
            for (const element of values) {
                this.selectCheckbox(element);
            }
        }
    }

    private selectCheckbox(element: ControlDataInterface): void {

        const toSelect = element.value;
        if (toSelect) {
            this.fieldContainer.find(`[value=${toSelect}]`).prop("checked", true);
        }

    }

}

import { Util } from "helper/util";

// Wire Global Bootstrap Tooltips
export function wireTooltips(): void {

    const selector = getTooltipSelector();

    $("body").tooltip({ container: "body", selector, trigger: "hover", delay: { show: 200 }, html: true });

}

function getTooltipSelector(): string {

    let selector = ".force-tooltip";

    if (!Util.isTouchDevice()) {
        selector += ", .has-tooltip";
    }

    return selector;
}

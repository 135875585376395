import { Lookup } from "uiFramework/core/controls/lookup";
import { DetailForm } from "uiFramework/core/forms/detailForm";
import { GridForm } from "uiFramework/core/forms/gridForm";
import { Modal } from "uiFramework/core/modals/modal";
import { ModalType } from "uiFramework/enum/allEnums";
import { ControlHelper } from "uiFramework/helper/controlHelper";
import { Util } from './util';
import { KeyCodes } from '../enum/keyCodes';

export class LookupHelper {

    public static initializeLookupModal(buttonSelector: string, fieldContainer: JQuery, control: ControlInterface): void {

        if (control.isStaticControl) { return; }

        $(buttonSelector, fieldContainer).click((event: JQuery.Event) => {

            let modalType;
            if (control instanceof Lookup) {
                modalType = ModalType.Single;
            }
            else {
                modalType = ModalType.Multi;
            }

            let pageUrl = this.getLookupPage(buttonSelector, fieldContainer);
            const parentNames = fieldContainer.find(buttonSelector).attr("data-parent-control");

            if (parentNames && parentNames.length > 0) {
                pageUrl = ControlHelper.getUrlWithParametersFromParentControl(pageUrl, parentNames.split(";"), control.form as DetailForm);
            }

            if (pageUrl !== "") {

                const modal = new Modal(modalType, control.fieldName, control.form);

                const values = control.getValues();
                const callback = GridForm.initGridModalCallback(values, control, modal);

                modal.openModalByURL(pageUrl, callback);
            }
        });

    }

    public static initializeLookupClear(buttonSelector: string, fieldContainer: JQuery, control: ControlInterface): void {

        if (control.isStaticControl) { return; }

        $(buttonSelector, fieldContainer).click(() => {
            control.clear();
            control.validate();
        });

    }

    public static disableInput(fieldContainer: JQuery): void {

        fieldContainer.find("input").on("keydown", ((event) => {
            if (Util.getKeyCode(event) === KeyCodes.Tab) {
                return true;
            }
            return false;
        }));
    }

    private static getLookupPage(buttonSelector: string, fieldContainer: JQuery): string {
        return fieldContainer.find(buttonSelector).attr("data-url");
    }

}

import { Locale } from "component/localeManager/localeManager";

export class FormValidation {

    private readonly form: FormInterface;
    private readonly formNode: JQuery;
    private readonly validator: JQueryValidation.Validator;

    private readonly selectorFormGroup = ".form-group";
    private readonly selectorInputIcon = ".input-icon";

    constructor(form: FormInterface) {

        this.form = form;
        this.formNode = form.getFormContainer().find("form");
        this.validator = this.createValidator();
    }

    /**
     * Validate Form Controls
     * */
    public validate(): boolean {

        return this.validator.form();

    }

    /**
     * Reset Form Validation - Removes CSS Styles on Elements
     * */
    public reset(): void {

        this.validator.resetForm();

    }

    private createValidator(): JQueryValidation.Validator {

        // plugin documentation http://docs.jquery.com/Plugins/Validation
        // create Validator instance. This will not trigger validation! The documentation is wrong.
        const validator = this.formNode.validate({

            onfocusout(control) {
                // "eager" validation
                validator.element($(control));
            },

            onkeyup(control, event) {
                // "eager" validation
                validator.element($(control));
            },

            errorElement: "span", // default input error message container
            errorClass: "help-block help-block-error", // default input error message class
            focusInvalid: false, // do not focus the last invalid input
            ignore: ":hidden:not(.text-editor),.note-editable", // validate all fields excluding form hidden input and summerbreeze editor

            invalidHandler: () => { // display error alert on form submit

                this.form.showInlineMessage(Locale.getTranslation("required fields"), "error");

            },

            errorPlacement: (error, element) => {

                const text = error.text();
                if (text) {
                    const icon = $(element).parent(this.selectorInputIcon).children("i");
                    icon.removeClass("fa-check").addClass("fa-warning force-tooltip");
                    icon.attr("title", text);
                }

            },

            highlight: (element) => {

                // set error class to the control group
                $(element).closest(this.selectorFormGroup).removeClass("has-success").addClass("has-error");

            },

            success: (label, element) => {

                // set success class to the control group
                const icon = $(element).parent(this.selectorInputIcon).children("i");
                $(element).closest(this.selectorFormGroup).removeClass("has-error").addClass("has-success");
                icon.removeClass("fa-warning").addClass("fa-check");

            },

        });

        return validator;
    }
}

import { HttpClient } from "component/httpClient/httpClient";
import { Log } from "component/logging/logging";
import { Select, SelectMultiple } from "controls/allControls";
import { ControlData } from "controls/util/controlData";
import { DetailForm } from "forms/detailForm";

export function doParentSourceChildRelationship(parentControl: ControlInterface): void {

    const parentSourceChildObject: ParentSourceChildConfig = getConfig(parentControl);

    if (missingDataAttribute(parentSourceChildObject)) {
        return;
    }

    getChildDataAndFillChildControl(parentSourceChildObject);
}

export function setTargetEmptyIfParentNotSelected(control: ControlInterface): boolean {

    const targetEmptyIfParentIsNotSelectedRaw = control.getDataAttribute("data-parentsourcechild-target-empty-if-parent-not-selected");

    return targetEmptyIfParentIsNotSelectedRaw && targetEmptyIfParentIsNotSelectedRaw !== "0";
}

export function missingDataAttribute(parentSourceChildObject: ParentSourceChildConfig) {

    return !parentSourceChildObject.SourceFormName
        || !parentSourceChildObject.SourceFilterFieldName
        || !parentSourceChildObject.SourceResultFieldName
        || !parentSourceChildObject.TargetFieldName;
}

function getConfig(parentControl: ControlInterface): ParentSourceChildConfig {

    return {
        ParentControl: parentControl,
        SourceFormName: parentControl.getDataAttribute("data-parentsourcechild-source-form-name"),
        SourceFilterFieldName: parentControl.getDataAttribute("data-parentsourcechild-source-filter-field-name"),
        SourceResultFieldName: parentControl.getDataAttribute("data-parentsourcechild-source-result-field-name"),
        TargetFieldName: parentControl.getDataAttribute("data-parentsourcechild-target-field-name"),
        TargetEmptyIfParentIsNotSelected: setTargetEmptyIfParentNotSelected(parentControl),
    };
}

export function getChildDataAndFillChildControl(parentSourceChildObject: ParentSourceChildConfig): Promise<void> {

    const parentControl = parentSourceChildObject.ParentControl;

    const detailForm = parentControl.form as DetailForm;
    const childControl = detailForm.getControl(parentSourceChildObject.TargetFieldName);

    // when child control is not visible (in dom) we cant apply Parent Source Child Function
    if (!childControl) {
        return Promise.resolve();
    }

    const selectedValues = getSelectedValues(childControl);

    if (childControl instanceof SelectMultiple || childControl instanceof Select) {

        childControl.resetOptions();
    }

    // reset child values
    childControl.clear();

    if (parentSourceChildObject.TargetEmptyIfParentIsNotSelected && !parentControl.hasValue) {
        return Promise.resolve();
    }

    const url = "Framework/GetParentSourceChildData";
    const params = new URLSearchParams();
    params.append("sourceFormName", parentSourceChildObject.SourceFormName);
    params.append("sourceFilterFieldName", parentSourceChildObject.SourceFilterFieldName);
    params.append("sourceResultFieldName", parentSourceChildObject.SourceResultFieldName);
    params.append("parentFieldValues", getSelectedValues(parentControl).map((x) => x.value).join(";"));

    return HttpClient.httpGet<string>(url, params)
        .then((childDataJson) => {

            $.unblockUI();

            if (childDataJson != null) {

                const childData: ControlData[] = JSON.parse(childDataJson);

                fillChildControl(childControl, childData, selectedValues);
            }
        }).catch((error) => {
            Log.logError(error);
            $.unblockUI();
        });
}

function getSelectedValues(childControl: ControlInterface) {

    return childControl.getValues();

}

function fillChildControl(childControl: ControlInterface, childData: ControlDataInterface[], selectedValues: ControlDataInterface[]): void {

    if (childControl instanceof SelectMultiple
        || childControl instanceof Select) {

        if (childData.length > 0) {
            childControl.addOptions(childData);
        }

        if (selectedValues.length > 0 && selectedValues[0].value !== "") {
            childControl.setValues(selectedValues);
        }
    } else {

        const values: ControlData[] = [];

        for (const item of childData) {

            values.push(new ControlData(item.value, item.displayValue));
        }

        childControl.setValues(values);
    }
}

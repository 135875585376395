import { Button } from "buttons/button";
import { Locale } from "component/localeManager/localeManager";
import { Log } from "component/logging/logging";
import { ToastrPositionInline } from "component/toastrWrapper/toastrPositionInline";
import { ToastrWrapper } from "component/toastrWrapper/toastrWrapper";
import { wireTooltips } from "component/tooltipWrapper/tooltipWrapper";
import { FormAction, FormStyles, ModalType } from "enum/allEnums";
import { FormCallbackType } from "forms/enum/formCallbackType";
import { Framework } from "framework";
import { FormHelper } from "helper/formHelper";
import { Util } from "helper/util";
import { DialogType } from "modals/enum/dialogType";
import { Modal } from "modals/modal";
import { FormTransfer } from "transfer/FormTransfer";
import { TssApp } from "../../tssApp";

export abstract class Form implements FormInterface {

    abstract get formStyle(): FormStyles;

    public formId: string;
    public pageName: string; // because a form in modal can have a different pageName than normal forms
    public formClass: string;

    // Properties
    protected portletBody: JQuery;
    protected formNode: JQuery<HTMLFormElement>;
    protected formButtons: ButtonInterface[];
    protected dispatcher: DispatcherInterface;
    protected isInitialized: boolean;
    protected formCallbacks: FormCallback[];

    private modal: ModalInterface; // if this form is in a modal, this is the modal object
    private childModal: ModalInterface; // when form opens a new modal, this is the modal

    constructor(dispatcher: DispatcherInterface, portletBody: JQuery, formCallbacks?: FormCallback | FormCallback[]) {

        Framework.verifyPortlet(portletBody);

        this.dispatcher = dispatcher;
        this.portletBody = portletBody;

        this.formButtons = new Array<ButtonInterface>();

        this.setFormCallbacks(formCallbacks);

        const formNode = portletBody.find("form").first() as JQuery<HTMLFormElement>; // first form because there could be a modal inside portlet body with another form
        this.initFormData(formNode);
    }

    // Methods
    public abstract initializeForm(ClearOldControls:boolean): Promise<void>;

    public changeVisibility(formConfiguration?: FormConfiguration): void {
        const section = this.portletBody.closest(".section-form");

        //If the Form needs to be hidden for any reason
        if (formConfiguration?.hideForm) {
            section.hide("blind");
        }
        else {
            section.show("blind");
        }
    }

    public reloadForm(formConfiguration?: FormConfiguration): Promise<void> {

        this.isInitialized = false;
        const section = this.portletBody.closest(".section-form");


        //If the Form needs to be hidden for any reason
        if (formConfiguration?.hideForm) {
            section.hide("blind");
        }
        else {
            section.show("blind");
        }


        section.block();

        const defaultFormConfiguration: FormConfiguration = {
            formId: this.formId,
            pageName: this.pageName,
            modalType: this.getModalType(),
        };

        let customFormConfig = { ...defaultFormConfiguration, ...formConfiguration };

        section.find("div.toast-inline-container").hide();


      


        return FormHelper.loadFormDOMFromServer(customFormConfig)
            .then(async (data) => {

              

                // No Data from Server after reload means the form is not visible any more, e.g. Form Attribute "No Records Hides Form"
                if (data == null || data.length === 0) {
                    section.hide("blind");
                    return;
                }

                const newForm = data.find("form").first() as JQuery<HTMLFormElement>;

                // Update object properties
                this.initFormData(newForm);

                // Replace the old form with the new in the UI
                this.portletBody.find("form").first().replaceWith(newForm);

                await this.initializeForm(customFormConfig.removeOldControls);

                wireTooltips();

                TssApp.getInstance().initializeLinks();

                section.unblock();

            })
            .catch((error) => {
                section.unblock();
                if (error.message != null) {
                    Log.logError(error.message);
                }
                else {
                    Log.logError(error);
                }
                this.showInlineMessage(Locale.getTranslation("form load error"), "error");
            });

    }

    public getFormContainer(): JQuery {

        // When Form in Modal, return Modal Container instead of Form Container
        if (this.modal) {
            return this.modal.getModalDialogContainer();
        }
        return this.portletBody;
    }

    public getFormCallbacks(): FormCallback[] {
        return this.formCallbacks;
    }

    public appendFormCallback(formCallback: FormCallback): void {

        if (this.isInitialized) {
            formCallback.call(this, FormCallbackType.FormLoaded, null);
        }

        this.formCallbacks.push(formCallback);
    }

    public getDataAttribute(name: string): string {
        name = Util.formatDataAttribute(name);
        return this.formNode.attr(name);
    }

    public setDataAttribute(name: string, value: string): void {
        name = Util.formatDataAttribute(name);
        this.formNode.attr(name, value);
    }

    public setModal(modal: ModalInterface) {
        this.modal = modal;
    }

    public getModal(): ModalInterface {
        return this.modal;
    }

    public setChildModal(modal: ModalInterface) {
        this.childModal = modal;
    }

    public getChildModal() {
        return this.childModal;
    }

    public getModalType(): ModalType {
        if (this.modal) {
            return this.modal.modalType;
        }

        return ModalType.None;
    }

    public getButtonById(buttonId: string): ButtonInterface {

        // there is only one button with buttonId (buttonId is unique)
        const buttons = this.formButtons.filter((b) => b.id === buttonId);

        if (buttons == null || buttons.length === 0) { return null; }

        return buttons[0];
    }

    public getButtonsByActionType(formAction: FormAction): ButtonInterface[] {
        return this.formButtons.filter((b) => b.formAction === formAction);
    }

    public getButtonsByDataAttribute(attributeName: string): ButtonInterface[] {

        return this.formButtons.filter((b) => b.getAttribute("data-" + attributeName) != null);
    }

    public getTransferData(): FormTransferInterface {
        return this.createTransferData();
    }

    public abstract isValid(): boolean;

    /**
     * Render UI Message in the form
     * @param message
     * @param replace
     */
    public showInlineMessage(message: string[] | string, messageType: ToastrType, replace = true, showOnPage = true): void {

        let toastrInlineOptions: ToastrInlineOptions;

        toastrInlineOptions = {
            MessageType: messageType,
            ParentElement: this.portletBody,
            Position: ToastrPositionInline.Top,
            ShowPageToastrIfNotVisible: showOnPage,
        };

        if (replace) {
            this.removeInlineMessage();
        }

        ToastrWrapper.showInlineMessage(message, toastrInlineOptions);
    }

    /**
     * Remove UI message in the form
     */
    public removeInlineMessage(): void {
        ToastrWrapper.removeInlineMessage(this.portletBody.parent());
    }

    /**
     * Submit the Form
     * @param button
     */
    public dispatch(button: ButtonInterface): Promise<void> {

        const actionType = button.formAction;

        switch (actionType) {

            case FormAction.Href: // passthrough, because we may need to handle a link in a modal
            case FormAction.Search:
            case FormAction.Saveform:
            case FormAction.Edit:

                return this.dispatcher.dispatch(this, button, actionType);
                
            case FormAction.Delete:

                this.triggerDeleteButton(button, actionType);
                return Promise.resolve();

            default:
                throw new Error(`Form Action not implemented: ${actionType}`);

        }
    }

    /**
     * Render Error Message to Form
     * @param response
     */
    public handleDispatchError(response: ResponseMessage): void {

        if (response.HasMessages) {

            const messages = (response.Messages as string[]).join("<br>").toString();

            Log.logInfo(messages);

            this.showInlineMessage(messages, "error");

        }

    }

    /**
     * If the current Form is in a Modal, only the Modal will be blocked, otherwise the whole page
     */
    public blockModalOrPage(): void {
        if (this.modal) {
            this.modal.getModalDialogContainer().block();
        }
        else {
            $.blockUI();
        }
    }

    /**
     * If the current Form is in a Modal, only the Modal will be unblocked, otherwise the whole page
     */
    public unblockModalOrPage(): void {
        if (this.modal) {
            this.modal.getModalDialogContainer().unblock();
        }
        else {
            $.unblockUI();
        }
    }

    protected setFormInitialized(data?: any): void {
        this.isInitialized = true;

        this.runCallbacks(FormCallbackType.FormLoaded, data);
    }

    protected runCallbacks(type: FormCallbackType, data?: any): void {

        for (const callback of this.formCallbacks) {
            callback.call(this, type, data);
        }
    }

    protected initializeButtons(): void {
        // Get all buttons from top and bottom of form and store them in a dictionary

        let buttonContainer;
        if (this.getModalType() === ModalType.None) {
            buttonContainer = this.getFormContainer().find("div.form-actions");
        }
        else {
            buttonContainer = this.getFormContainer().find("div.modal-footer");
        }

        const linkElements = buttonContainer.find("a.text-button:not([data-backgroundsubmit-form-name]), a.icon-button");
        const groupedLinkElements: { [name: string]: HTMLElement[]; } = {};

        linkElements.each((i, link) => {
            const buttonId = link.getAttribute("data-button-id");

            if (groupedLinkElements[buttonId] == null) {
                groupedLinkElements[buttonId] = [];
            }

            groupedLinkElements[buttonId].push(link);
        });

        // create one button object for button from top and bottom
        this.formButtons = new Array<ButtonInterface>(); // kill existing buttons in array (e.g. reloading a form)
        for (const buttonId of Object.keys(groupedLinkElements)) {

            const anchorTags = groupedLinkElements[buttonId].map((element, index) => $(element) as JQuery<HTMLAnchorElement>);

            const button = new Button(buttonId, this, ...anchorTags);
            this.formButtons.push(button);
        }
    }

    protected createTransferData(): FormTransferInterface {
        return new FormTransfer(this.formId);
    }

    private setFormCallbacks(formCallbacks?: FormCallback | FormCallback[]): void {
        if (formCallbacks == null) {
            this.formCallbacks = new Array<FormCallback>();
            return;
        }

        if (formCallbacks instanceof Array) {
            this.formCallbacks = formCallbacks;
        }
        else {
            this.formCallbacks = [formCallbacks];
        }
    }

    private triggerDeleteButton(button: ButtonInterface, actionType: FormAction) {

        const pkId = button.getAttribute("data-button-id");
        const headerText = Locale.getTranslation("are you sure");
        const bodyText = Locale.getTranslation("delete entry");

        const confirmationModal: ModalInterface = new Modal(ModalType.Single, pkId, this);
        confirmationModal.openModalDialog(headerText, bodyText, DialogType.YesNo, {
            overrideSuccessCallback: () => {
                this.dispatcher.dispatch(this, button, actionType);
            }
        });

    }

    private initFormData(formNode: JQuery<HTMLFormElement>) {
        this.formNode = formNode;
        this.formId = formNode.attr("data-form-id");
        this.formClass = formNode.attr("data-form-class");
        this.pageName = formNode.attr("action");
    }
}


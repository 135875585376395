// We use class instead of interface because we need to make an instanceof-check
export class HttpClientError extends Error {
    public url?: string;
    public exceptionMessage?: string;
    public responseData?: HttpClientResponseData;
    public request?: XMLHttpRequest;
    public statusCode?: number;

    constructor(errorMessage: string, errorName: string) {
        super(errorMessage);
        this.name = errorName;
    }
}

export class HttpClientResponseData {
    public Code: string;
    public ExceptionMessage: string;
    public ErrorKey: string;
    public AlreadyLogged: string;
}

import { HubConnector } from '../hubConnector';

export class SessionHubConnector extends HubConnector {


    public async connect() {

        return this.connectHub("/sessionhub");
        
    }

    // Receiving Methods

    public RegisterLoggedOutCallback(callback: (redirectUrl: string, allTabs: boolean) => void): void {

        this._connection.on("LoggedOut", (redirectUrl: string, allTabs: boolean) => {

            callback(redirectUrl, allTabs);

        });

    }

    public RegisterSessionUpdatedCallback(callback: (expiryTime: string) => void): void {

        this._connection.on("SessionUpdated", (expiryTime: string) => {

            callback(expiryTime);

        });

    }

    // Sending Methods

    /** returns redirect url, can be empty when exception on server */
    public async SessionExpired(): Promise<string> {

        return this._connection.invoke<string>("SessionExpired");

    }

    public async ExtendSession() : Promise<void> {

        return this._connection.send("ExtendSession");

    }

}

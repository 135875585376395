import { Locale } from "component/localeManager/localeManager";
import { ControlData } from "controls/util/controlData";
import { Util } from "helper/util";
import { debounce } from "lodash";
import { Control, ControlCallbackFunction } from "./control";

export class Hourpicker extends Control {

    public initialize(): void {}

    public clear(): void {

        if (!this.hasValue) { return; }

        super.clearDatePickerBasedControl();
        this.runValueChangedCallbacks();
    }

    public getValues(): ControlDataInterface[] {

        let value = this.fieldContainer.find("input").val().toString();

        if (!this.isStaticControl) {
            value = Util.convertIndustryHoursToTime(value);
        }

        return [new ControlData(value)];
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }

        this.runValueChangedCallbacks();
    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        super.bindOnValueChanged(callback);

        this.fieldContainer.on("dp.change", debounce(() => callback(), 200));
    }

    private setValue(controlData: ControlDataInterface): void {

        if (!this.isStaticControl) {

            this.setHourpicker(controlData.value);

        } else {

            this.setValueWhenStatic(controlData);
        }

    }

    private setHourpicker(value: string): void {

        this.fieldContainer
            .data("DateTimePicker")
            .date(value);
    }

    private setValueWhenStatic(controlData: ControlDataInterface): void {

        const displayValue = this.buildDisplayValueFromValue(controlData);
        super.setStaticField(controlData.value, displayValue);

    }

    private buildDisplayValueFromValue(controlData: ControlDataInterface): string {

        let displayValue = controlData.displayValue;
        if (displayValue != null && !displayValue) {

            displayValue = Util.convertTimeToIndustryHours(controlData.value);
            displayValue = Locale.formatNumber(displayValue);
        }

        displayValue = super.nullToEmptyString(displayValue);

        return displayValue;
    }

}

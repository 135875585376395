import { ControlData } from "controls/util/controlData";
import { ControlHelper } from "helper/controlHelper";
import { debounce } from "lodash";
import { Control, ControlCallbackFunction } from "./control";

// this control supports Datepicker, Datetimepicker and Timepicker.
// Bootstrap Datepicker knows which one to render with the data attribute "data-date-format"
export class Datepicker extends Control {

    public initialize(): void { }

    public clear(): void {

        if (!this.hasValue) { return; }

        super.clearDatePickerBasedControl();

        this.runValueChangedCallbacks();
    }

    public getDate(): Date {

        const control = this.fieldContainer.find("input");

        return new Date(control[0].dataset.isoDate);
    }

    public getValues(): ControlDataInterface[] {

        return [this.getValue()];
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }

        this.runValueChangedCallbacks();
    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        super.bindOnValueChanged(callback);
        this.fieldContainer.on("dp.change", debounce(() => callback(), 200));

    }

    private getValue(): ControlDataInterface {

        const control = this.fieldContainer.find("input");
        if (control.length === 0) {
            return new ControlData("");
        }

        let isoDate = control[0].dataset.isoDate;
        const date = new Date(isoDate);

        if (!date) { // check date is valid
            return new ControlData("");
        }

        isoDate = this.nullToEmptyString(isoDate);

        if (this.isStaticControl) {

            const displayValue = this.fieldContainer.find("span").text();
            return new ControlData(isoDate, displayValue);

        } else {

            const displayValue = control.val().toString();
            return new ControlData(isoDate, displayValue);
        }

    }

    private setValue(controlData: ControlDataInterface): void {

        if (!this.isStaticControl) {

            this.setDatepicker(controlData.value);

        } else {
            this.setValueWhenStatic(controlData);
        }

    }

    private setValueWhenStatic(controlData: ControlDataInterface): void {

        const displayValue = ControlHelper.buildDateDisplayValueFromValue(controlData, this.getFormat());
        super.setStaticField(controlData.value, displayValue);

        this.fieldContainer.find("input")[0].dataset.isoDate = controlData.value;
    }

    private setDatepicker(value: string): void {

        this.fieldContainer
            .data("DateTimePicker")
            .date(new Date(value));
    }

    private getFormat(): string {
        return this.fieldContainer.attr("data-date-format");

    }
}

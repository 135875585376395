/* ===========================================================
 *
 * by 3SS Team clean <p> tags outside <ul> or <ol> elements
 * https://github.com/Alex-D/Trumbowyg/issues/484
 *
 * ===========================================================
 */

import { debounce } from "lodash";


function cleanIt(html: string) {

    html = html.replace(/<p><ul>/g, "<ul>");
    html = html.replace(/<\/p><\/ul>/g, "<\/ul>");
    html = html.replace(/<p><ol>/g, "<ol>");
    html = html.replace(/<\/p><\/ol>/g, "aef<\/ol>");

    return html;
}

$.extend(true, $.trumbowyg, {
    plugins: {
        cleanChanges: {
            init: function (trumbowyg: Trumbowyg) {

                setTimeout(function () {
                    try {
                        let editor = trumbowyg.$ed;
                        editor.on("tbwblur ", debounce(() => {

                            let cleaned = cleanIt(editor.html());
                            editor.html(cleaned);
                            trumbowyg.syncTextarea();

                        }, 200));
                    } catch (c) {
                    }
                }, 0);

            }
        }
    }
});

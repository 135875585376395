import { HttpClient } from "component/httpClient/httpClient";
import { PageHelper } from "helper/pageHelper";
import { Locale } from "../component/localeManager/localeManager";

export class FormHelper {

    /**
     * Returns an existing form instance
     * @param formId
     */
    public static getFormById(formId: string): FormInterface {
        return PageHelper.getPage().getForm(formId);
    }

    /**
     * Returns all form instances with the FormClass specified in DevStar
     * @param formClass FormClass specified in Form in DevStar
     */
    public static getFormsByFormClass(formClass: string): FormInterface[] {
        return PageHelper.getPage().getFormsByFormClass(formClass);
    }

    public static loadFormDOMFromServer(formConfiguration: FormConfiguration): Promise<JQuery> {

        const url = `form/renderFormAsHtml`;

        return HttpClient
            .httpPost<string>(url, formConfiguration )
            .then((data) => {
                return $(data);
            });
    }

    public static showNoRecordsFoundMessage(formNode: JQuery) {

        const table = document.createElement("table");
        const tr = document.createElement("tr");
        const td = document.createElement("td");
        const text = Locale.getTranslation("no_records_found");

        table.appendChild(tr.appendChild(td));
        td.innerText = text;

        formNode.append(table);
    }

}

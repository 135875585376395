import { ControlData } from "controls/util/controlData";
import { debounce } from "lodash";
import { Control, ControlCallbackFunction } from "./control";

export class FreeText extends Control {

    public initialize(): void { }

    public clear(): void {

        if (!this.hasValue) { return; }

        if (this.isStaticControl) {
            super.clearStaticInputBasedElement();
        } else {
            this.fieldContainer.find("input").val("");
        }

        this.runValueChangedCallbacks();

    }

    public getValues(): ControlDataInterface[] {

        if (this.isStaticControl) {
            return super.getStaticSingleField();
        } else {

            const control = this.fieldContainer.find("input");
            if (control.length === 0) {
                throw new Error(`FreeText(${this.fieldName}): Input Element not found`);
            }

            const value = control.val().toString();

            return [new ControlData(value, value)];
        }
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }
    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        super.bindOnValueChanged(callback);

        this.fieldContainer.find("input").on("keyup", debounce(() => callback(), 200));

        this.fieldContainer.find(".bootstrap-touchspin-up, .bootstrap-touchspin-down").on("click", debounce(() => callback(), 200));
    }

    private setValue(controlData: ControlDataInterface): void {
        if (this.isStaticControl) {
            super.setStaticField(controlData.value, controlData.displayValue);
        } else {
            this.fieldContainer.find("input").val(controlData.value);
        }

        this.runValueChangedCallbacks();
    }
}

import { ControlData } from "controls/util/controlData";
import { debounce } from "lodash";
import { Control, ControlCallbackFunction } from "./control";

export class Textarea extends Control {

    private readonly textArea: JQuery<HTMLTextAreaElement>;
    private readonly editorElement: JQuery<HTMLDivElement>;
    private readonly isEditor: boolean;

    constructor(fieldContainer: JQuery, form?: FormInterface) {

        super(fieldContainer, form, "textarea");

        const editor = this.fieldContainer.find("div.text-editor") as JQuery<HTMLDivElement>;

        if (editor.length > 0) {
            this.isEditor = true;
            this.editorElement = editor;
        }

        this.textArea = this.fieldContainer.find("textarea:first") as JQuery<HTMLTextAreaElement>;

    }

    public initialize(): void { }

    public getDataAttribute(name: string): string {

        if (this.isEditor) {
            return this.editorElement.attr(name);
        } else {
            return super.getDataAttribute(name);
        }

    }

    public clear(triggerCallback: boolean = true): void {

        if (!this.hasValue) { return; }

        if (this.isStaticControl) {
            super.clearStaticInputBasedElement();
        } else {

            this.clearEditControl();
        }

        if (triggerCallback) {
            this.runValueChangedCallbacks();
        }
    }

    public enable(): void {

        if (this.isStaticControl) { return; }

        if (this.isEditor) {

            this.editorElement.trumbowyg("enable");
        } else {

            super.enableElement("textarea");
        }

    }

    public disable(): void {

        if (this.isStaticControl) { return; }

        if (this.isEditor) {

            this.editorElement.trumbowyg("disable");

        } else {

            super.disableElement("textarea");

        }

    }

    public focus(): void {

        if (!this.isEditor) {
            super.focus();
        } else {
            this.editorElement.trigger("focus");
        }

    }

    public validate(): void {

        if (!this.isEditor) {
            super.validateElement("textarea");
        }

    }

    public getValues(): ControlDataInterface[] {

        return [this.getValue()];
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }

        this.runValueChangedCallbacks();
    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        super.bindOnValueChanged(callback);

        if (!this.isEditor) {

            this.fieldContainer.find("textarea").on("keyup", debounce(() => { callback(); }, 200));
        } else {
            this.editorElement.trumbowyg().on("tbwchange", debounce(() => { callback(); }, 200));
        }
    }

    private getValue(): ControlDataInterface {

        let value = "";

        const inputControl = this.fieldContainer.find("input");
        if (this.isStaticControl && inputControl) {
            value = this.fieldContainer.find("input").val().toString();
        } else {
            value = this.textArea.val().toString();
        }

        return new ControlData(value, value);
    }

    private setValue(controlData: ControlDataInterface): void {

        if (!this.isStaticControl) {

            if (this.isEditor) {
                this.setWYSIWYGValue(controlData);
            } else {
                this.setTextareaValue(controlData);
            }
        } else {
            super.setStaticField(controlData.value, controlData.displayValue);
        }

    }

    private clearEditControl() {

        if (this.isEditor) {
            this.editorElement.trumbowyg("empty");
        }
        this.textArea.val("");

    }

    private setTextareaValue(controlData: ControlDataInterface): void {
        this.textArea.val(controlData.value);
    }

    private setWYSIWYGValue(controlData: ControlDataInterface): void {
        this.clear(false);
        this.editorElement.trumbowyg("html", controlData.value);
    }
}

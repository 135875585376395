
import { HttpClient } from "component/httpClient/httpClient";
import { Locale } from "component/localeManager/localeManager";
import { Log } from "component/logging/logging";
import { LogLevels } from "component/logging/logLevels";
import { ToastrWrapper } from "component/toastrWrapper/toastrWrapper";
import { SubmitDoneCallback, SubmitErrorCallback } from "dispatcher/submitCallbackTypes";
import { SubmitHelper } from "dispatcher/submitHelper";
import { FormAction, FormStyles, ModalType, Status } from "enum/allEnums";
import { PageHelper } from "helper/pageHelper";
import { DialogType } from "modals/enum/dialogType";
import { PageTransfer } from "transfer/pageTransfer";
import { ClientOperationalException } from "../helper/ClientOperationalException";
import { ClientPageExtensionHelper } from "../helper/clientPageExtensionHelper";
import { HttpClientError } from "../helper/httpClientError";

export class Dispatcher implements DispatcherInterface {

    public static createFormDataIncludingURLParameters(pageTransfer: PageTransfer): FormData {

        // Form Data is the native HTML Class for transfering data
        const formData = new FormData();

        formData.append("PageTransfer", JSON.stringify(pageTransfer));

        HttpClient
            .getUrlParameters()
            .forEach((value, name) => {
                formData.append(name, value);
            });

        return formData;
    }

    private pageTransfer: PageTransfer;

    private form: FormInterface;
    private errorHandler: SubmitErrorCallback;

    public async dispatch(form: FormInterface, clickedButton: ButtonInterface, action: FormAction): Promise<void> {

        this.form = form;
        this.pageTransfer = new PageTransfer();
        this.pageTransfer.action = action;
        this.pageTransfer.modalType = form.getModalType();
        this.pageTransfer.pageName = this.form.pageName;
        this.pageTransfer.actionType = clickedButton.actionType;

        // Init Handler
        this.errorHandler = this.initializeErrorHandler(form, clickedButton, action);

        let submitUrl = "";
        switch (action) {

            case FormAction.Edit:
                return;

            case FormAction.Href:
                this.followHref(form, action, clickedButton);
                return;

            case FormAction.Saveform:
            case FormAction.Delete:
            case FormAction.Search:
            case FormAction.Adhocupdate:
            case FormAction.Adhocsavesearch:

                if (action === FormAction.Search) {
                    submitUrl = "validate.app";
                } else if (action === FormAction.Adhocupdate) {
                    submitUrl = "adhoc_update.app";
                } else if (action === FormAction.Adhocsavesearch) {
                    submitUrl = "adhoc_save_filter.app";
                } else {
                    submitUrl = "update.app";
                }

                if (action !== FormAction.Delete) {
                    if (!form.isValid()) {
                        return; // stop submit process
                    }
                }

                const formTransfer = this.buildFormTransferData(form, clickedButton, action);
                this.pageTransfer.addForm(formTransfer);

                break;

            // this one comes later, should be something like pagsave
            // case FormAction.savall
            //    break;

            default:
                throw new Error("Form Action not implemented: " + action.toString());
        }

        if (Log.getLogLevel() <= LogLevels.Debug) {
            console.info(JSON.stringify(this.pageTransfer));
        }

        submitUrl = this.includeDbChange(submitUrl, form);

        form.blockModalOrPage();

        // Before Submitting Data to Server, do fileupload
        try {
            await SubmitHelper.doFileUpoad(this.pageTransfer, form);
        } catch (error) {
            this.errorHandler(error);
            return;
        }

        // Submit Page
        return SubmitHelper.submitData({
            submitUrl,
            formData: Dispatcher.createFormDataIncludingURLParameters(this.pageTransfer),
            currentPage: this.form.pageName,
        })
            .then((data) => {
                return this.submitDoneHandler(data, form, clickedButton, action);
            })
            .catch((error) => {
                this.errorHandler(error);
            });
    }

    private includeDbChange(url: string, submittedForm: FormInterface): string {

        // Check the submitted form for data-db-change attribute
        // find an org_id field on that form and append dbchange=<orgid> on url

        let orgId: string;
        const formTransfer = this.pageTransfer.forms.filter((f) => f.id === submittedForm.formId)[0];
        if (formTransfer == null) { return url; }

        const dbChange = submittedForm.getDataAttribute("data-db-change");
        if (dbChange === "true") {
            const orgIdField = formTransfer.getFieldValue("org_id");

            if (orgIdField != null && orgIdField.length > 0) {
                orgId = orgIdField[0];
            }
        }

        if (orgId != null && orgId.length > 0) {
            url += `?dbchange=${orgId}`;
        }

        return url;
    }

    private validateSchema(): void {

        // var env = new djv();
        // if (Log.getLogLevel() <= LogLevels.Debug) {
        //    let url = "./Scripts/3SS/transfer/schema/pageTransferSchema.json"
        //    $.get(url, (data: any) => {

        //        env.addSchema("main", data);
        //        var result = env.validate("main", this.pageTransfer);

        //    })

        // }

    }

    private getResultSearchPageDoneHandler(document: string): void {

        this.form.unblockModalOrPage();
        
        const rootNode = $(document);
        // when we have an opened modal, we want to render search results within the modal.
        if (this.form.getModalType() !== ModalType.None) {

            if (!this.form.getModal()) {
                Log.logError("No Modal set in Form");
            }

            const callbacks = this.form.getFormCallbacks();
            this.form.getModal().openModalByDOMData(rootNode, callbacks); // copy the old callback to the new modal

        } else {

            // render search result page

            $(".page-content-container").replaceWith(rootNode.find(".page-content-container"));

            ClientPageExtensionHelper.resetExtensions();
            PageHelper.getPage().initialize();

            // after replacing page content with search results, we add a entry in browser history, to keep the back button functionality
            // url for search results and search mask is the same
            const currentUrl = window.location.href;
            history.replaceState({ url: currentUrl }, "SearchForm");
            history.pushState({ url: currentUrl }, "ResultForm");
            window.onpopstate = function (e: PopStateEvent) {
                if (e.state != null && e.state.url != null) {
                    HttpClient.browserRedirect(e.state.url); // load search form
                }
            };
        }

        Log.logDebug("Got Search Results!");
    }

    private buildFormTransferData(form: FormInterface, clickedButton: ButtonInterface, action: FormAction): FormTransferInterface {

        const formTransferData = form.getTransferData();

        // Set Primary Key for deletion
        if (action === FormAction.Delete) {
            formTransferData.setPrimaryKeys(clickedButton.getAttribute("data-id"));
        }

        Log.logDebug("Loading Control Data completed");

        return formTransferData;
    }

    private initializeErrorHandler(form: FormInterface, clickedButton: ButtonInterface, action: FormAction): SubmitErrorCallback {
        return (error) => {

            if (error instanceof ClientOperationalException) {

                const userMessage = error.message;
                const errorMessage = `\r\nFormId: ${form.formId}\r\nClickedButtonId: ${clickedButton.id}\r\nFormAction: ${action}\r\nMessage: ${error.message} `;

                this.form.unblockModalOrPage();
                this.form.showInlineMessage(userMessage, "error");

                error.message = "Dispatcher: Submit failed!" + errorMessage;
                Log.logError(error);

            } else {

                let userMessage = error.exceptionMessage || error.message;

                let errorMessage = `\r\nFormId: ${form.formId}\r\nClickedButtonId: ${clickedButton.id}\r\nFormAction: ${action}\r\nMessage: ${error.message} `;

                // For Fileupload we have the message as a Response Object
                if (error.responseData && error.responseData instanceof Array) {
                    const fileErrors = error.responseData as FileUploadResponseObject[];
                    fileErrors.forEach((value) => {
                        if (value.reason) {
                            errorMessage += `\r\nFileUploadReason: ${value.reason} `;
                        }
                    });

                    // show file upload error reason to UI
                    userMessage = fileErrors.map((x) => x.reason).join(", ");
                } else if (error.responseData) {
                    errorMessage += `\r\nResponse: ${escape(JSON.stringify(error.responseData))} `;
                }

                this.form.unblockModalOrPage();

                this.form.showInlineMessage(userMessage, "error");

                errorMessage = "Dispatcher: Submit failed!" + errorMessage;

                if (error instanceof HttpClientError) {
                    error.message = errorMessage;
                    Log.logError(error);
                } else {
                    Log.logError(errorMessage);
                }
            }
        };
    }

    private async submitDoneHandler(repsonse: ResponseMessage, form: FormInterface, clickedButton: ButtonInterface, action: FormAction) : Promise<void> {

        if (this.renderServerErrorMessages(form, repsonse)) {
            return Promise.resolve();
        }

        if (await this.reloadForm(form, repsonse, action)) {
            return Promise.resolve();
        }

        if (this.followHref(form, action, clickedButton)) {
            return Promise.resolve();
        }

        if (await this.redirect(form, repsonse, action)) {
            return Promise.resolve();
        }

        this.handleNoSucessUrl(form);
        return Promise.resolve();
    }

    private handleNoSucessUrl(form: FormInterface) {

        // everyhting is handled before, except the case when there is no success url
        // reloading form would not work with searchpages
        form.unblockModalOrPage();
        form.removeInlineMessage();
        ToastrWrapper.showPageMessage(Locale.getTranslation("save done"), { MessageType: "success", ToastrOptions: { timeOut: 5000 } });
    }

    private async reloadForm(form: FormInterface, response: ResponseMessage, action: FormAction) : Promise<boolean> {

        if (this.isGridDelete(action, form)) {
            // delete dialog is calling dispatcher with grid form, not with modal form
            form.unblockModalOrPage();
            form.getChildModal().closeModal();
            ToastrWrapper.showPageMessage(Locale.getTranslation("delete done"), { MessageType: "success", ToastrOptions: { timeOut: 5000 } });
            await form.reloadForm();

            return true;
        }

        if (this.isGridAddOrEdit(form, action)) {

            form.unblockModalOrPage();
            form.getModal().closeModal();
            ToastrWrapper.showPageMessage(Locale.getTranslation("save done"), { MessageType: "success", ToastrOptions: { timeOut: 5000 } });

            if (response.RowStatus.filter((e) => e.Status === Status.Success).length > 0) {
                await form.getModal().parentForm.reloadForm();
            }

            return true;
        }

        // save event inside modal triggered, redirect will be handled in redirect()
        if (form.getModal() && action === FormAction.Saveform) {
            return false;
        }

        return false;
    }

    // Follow Href
    private followHref(form: FormInterface, action: FormAction, clickedButton: ButtonInterface): boolean {

        if (action === FormAction.Href) {
            const url = clickedButton.getAttribute("href");

            if (!url) { // when no href exists, return. e.g. modal close button has no href
                return true;
            }

            if (form.getModalType() !== ModalType.None) {
                // render href in modal
                const callbacks = form.getFormCallbacks(); // copy the old callback to the new modal
                form.getModal().openModalByURL(url, callbacks);
                return true;
            }

            HttpClient.browserRedirect(url);

            return true; // follow href
        }

        return false;
    }

    private async redirect(form: FormInterface, data: ResponseMessage, action: FormAction): Promise<boolean> {
        // Check for Search Page or follow the redirect url
        if (data && data.RedirectURL && data.RedirectURL.length > 0) {

            // First Submit was for validation, now get search results
            if (action === FormAction.Search) {

                try {
                    const searchResultDocument = await SubmitHelper.getResultSearchPage({
                        submitUrl: data.RedirectURL,
                        formData: Dispatcher.createFormDataIncludingURLParameters(this.pageTransfer),
                        currentPage: this.form.pageName,
                    });
                    this.getResultSearchPageDoneHandler(searchResultDocument);
                } catch (e) {
                    this.errorHandler(e);
                }

                return true;
            } else {

                // Follow redirect url
                const url = data.RedirectURL;

                // save event inside modal triggered, redirect inside modal
                if (form.getModal() && action === FormAction.Saveform) {

                    // render redirect url in modal
                    const callbacks = form.getFormCallbacks(); // copy the old callback to the new modal
                    form.getModal().openModalByURL(url, callbacks);

                } else {

                    HttpClient.browserRedirect(url);
                    return true;

                }
            }
        }

        return false;
    }

    // When there was a server error, we render the error message on the form
    private renderServerErrorMessages(form: FormInterface, data: ResponseMessage): boolean {

        if (data.HasMessages) {

            form.unblockModalOrPage();

            form.handleDispatchError(data);

            const childModal = form.getChildModal();
            if (childModal != null && childModal.dialogType === DialogType.YesNo) {
                childModal.closeModal();
            }

            return true;
        }
        return false;
    }

    private isGridDelete(action: FormAction, form: FormInterface) {
        return action === FormAction.Delete && form.formStyle === FormStyles.Grid;
    }

    private isGridAddOrEdit(form: FormInterface, action: FormAction): boolean {

        return form.getModal()
            && form.getModal().parentForm
            && form.getModal().parentForm.formStyle === FormStyles.Grid
            && action === FormAction.Saveform;

    }
}

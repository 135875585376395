import { ControlData } from "controls/util/controlData";
import { debounce } from "lodash";
import { Control, ControlCallbackFunction } from "./control";

export class Checkbox extends Control {

    get hasValue(): boolean {

        const value = this.getValue().value;
        if (value && value !== "" && value !== "0") {
            return true;
        }

        return false;

    }

    public initialize(): void {}

    public clear(): void {

        if (!this.hasValue) { return; }

        if (!this.isStaticControl) {
            this.fieldContainer.find("input").prop("checked", false);
        } else {
            this.fieldContainer.find("input:hidden").val("0");
            this.fieldContainer.find("span").text("");
        }

        this.runValueChangedCallbacks();
    }

    public focus(): void {

        // checkboxes are not possible to focus,
        // because visible is only a span, and span's couldnt be focused
        return;

    }

    public getValues(): ControlDataInterface[] {

        return [this.getValue()];
    }

    public setValues(controlData: ControlDataInterface | ControlDataInterface[]): void {

        if (!controlData || this.equals(controlData)) { return; }

        if (!Array.isArray(controlData)) {
            this.setValue(controlData);
        } else {
            if (controlData.length === 0) { return; }
            this.setValue(controlData[0]);
        }

        this.runValueChangedCallbacks();
    }

    public bindOnValueChanged(callback: ControlCallbackFunction): void {

        super.bindOnValueChanged(callback);
        this.fieldContainer.find("input").on("click", debounce(() => callback(), 200));

    }

    // returns "1" when checked, "0" when unchecked
    private getValue(): ControlDataInterface {

        const control = this.fieldContainer.find("input");

        if (!control) {
            throw new Error(`Checkbox(${this.fieldName}): Input Element not found`);
        }

        let displayValue = "";
        let value = "0";
        if (this.isStaticControl) {

            if (control.val() === "1") {
                displayValue = "1";
                value = "1";
            }

        } else {

            if (control.is(":checked")) {
                value = "1";
                displayValue = "1";
            }

        }

        return new ControlData(value, displayValue);

    }

    private setValue(controlData: ControlDataInterface): void {

        if (this.isStaticControl) {
            this.setValueWhenStatic(controlData);
        } else {
            this.setCheckbox(controlData);
        }
    }

    private setCheckbox(controlData: ControlData): void {
        const isChecked = controlData.value === "1";
        this.fieldContainer.find("input").prop("checked", isChecked);
    }

    private setValueWhenStatic(controlData: ControlData): void {
        super.setStaticField(controlData.value, controlData.displayValue);
    }
}

import { debounce } from "lodash";

function handleMaxLength(editor: JQuery<HTMLDivElement>): void {

    let maxLength = editor.attr("maxlength"); 
    if (!maxLength) return;
        

    if (editor.html().length > parseInt(maxLength)) {

        if (hasHtmlTags(editor)) { // maybe there are no html tags in the dom

            cutOff(editor, parseInt(maxLength));            

        }
        else {

            editor.html(cutNoHtml(editor.html(), parseInt(maxLength)));

        }
    }

}

function hasHtmlTags(editor: JQuery): boolean {
    return editor.html().match(/<[a-z][a-z0-9]*>/g) != null;
}

function cleanWhitespace(node: JQuery) : JQuery {

    node.contents().filter(
        function () { return (this.nodeType === 3 && !/\S/.test(this.nodeValue)); })
        .remove();
    return this;

}

// strategy is: Check the length including html tags. 
// Check if it is enough to cut some letters inside a node
// If not, remove the whole node and run again
// If yes, remove charactes needed, to fit the maximum. But we remove whole words until we find a whitespace,
// because otherwise html special chars like &nbsp; could be destroyed
function cutOff(dom: JQuery, maxLength: number): JQuery {
    
    let completeLength = dom.html().length;
    let lastElement = getDeepestLastChild(dom);
    let lastElementLength = lastElement.text().length;

    // check if cutting the current elements text is enough. no less than or equal, because when the text of the current element is empty, we can remove it
    if (completeLength - lastElementLength < maxLength) {

        let cutCharacterCount = getCharacterCountToCut(lastElement.text(), maxLength);

        lastElement.text(lastElement.text().slice(0, -cutCharacterCount));

        if (lastElement.text.length === 0) {
            lastElement.remove();
            cleanWhitespace(dom); // after removing a node, there could remain some useless whitespaces
        }

        return dom;
    }
    else {

        // We need to remove the whole tag and continue recursively;
        lastElement.remove();        

        cleanWhitespace(dom); // after removing a node, there could remain some useless whitespaces

        return cutOff(dom, maxLength);
    }
    
}

function cutNoHtml(text: string, maxLength: number): string {

    let cutCharacterCount = getCharacterCountToCut(text, maxLength);
    return text.slice(0, -cutCharacterCount);
}

function getCharacterCountToCut(text: string, maxLength: number): number {

    let cutCharacterCount = text.length - maxLength;

    // check if position is inside a word and go left until word begins
    // otherwise we may break words like &nbsp; and so on

    while (cutCharacterCount < text.length && text[text.length - cutCharacterCount] !== " ") {
        cutCharacterCount++;
    }

    return cutCharacterCount;

}

function getDeepestLastChild(dom: JQuery) : JQuery {

    if (dom.children().length === 0)
        return dom.last();    

    return getDeepestLastChild(dom.children().last());

}


$.extend(true, $.trumbowyg, {
    plugins: {
        maxLength: {
            init: function (trumbowyg: Trumbowyg) {
                trumbowyg.pasteHandlers.push(function () {
                    setTimeout(function () {                        
                        try {
                            handleMaxLength(trumbowyg.$ed);
                        } catch (c) {
                        }
                    }, 0);
                });
            }
        }
    }
});